/**
* Copyright JS Foundation and other contributors, http://js.foundation
*
* Licensed under the Apache License, Version 2.0 (the "License");
* you may not use this file except in compliance with the License.
* You may obtain a copy of the License at
*
* http://www.apache.org/licenses/LICENSE-2.0
*
* Unless required by applicable law or agreed to in writing, software
* distributed under the License is distributed on an "AS IS" BASIS,
* WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
* See the License for the specific language governing permissions and
* limitations under the License.
**/
/**
 * Copyright JS Foundation and other contributors, http://js.foundation
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 **/
:root {
	--red-ui-primary-font: Lato, Arial, Helvetica, sans-serif;
	--red-ui-primary-font-size: 14px;
	--red-ui-monospace-font: Menlo, Consolas, DejaVu Sans Mono, Courier,
		monospace;
	--red-ui-primary-background: #f3f3f3;
	--red-ui-secondary-background: #fff;
	--red-ui-secondary-background-selected: #efefef;
	--red-ui-secondary-background-inactive: #f0f0f0;
	--red-ui-secondary-background-hover: #e6e6e6;
	--red-ui-secondary-background-disabled: #f9f9f9;
	--red-ui-tertiary-background: #f7f7f7;
	--red-ui-shadow: rgba(0, 0, 0, 0.2);
	--red-ui-primary-text-color: #555;
	--red-ui-secondary-text-color: #888;
	--red-ui-secondary-text-color-focus: #666;
	--red-ui-secondary-text-color-hover: #666;
	--red-ui-secondary-text-color-active: #666;
	--red-ui-secondary-text-color-selected: #666;
	--red-ui-secondary-text-color-inactive: #666;
	--red-ui-secondary-text-color-disabled: #bbb;
	--red-ui-secondary-text-color-disabled-active: #999;
	--red-ui-secondary-text-color-disabled-inactive: #aaa;
	--red-ui-tertiary-text-color: #aaa;
	--red-ui-header-text-color: #444;
	--red-ui-text-color-error: #ad1625;
	--red-ui-text-color-warning: #cab200;
	--red-ui-text-color-success: #3a3;
	--red-ui-text-color-code: #ad1625;
	--red-ui-text-color-link: #0088cc;
	--red-ui-primary-border-color: #bbbbbb;
	--red-ui-secondary-border-color: #dddddd;
	--red-ui-tertiary-border-color: #ccc;
	--red-ui-border-color-error: #df2935;
	--red-ui-border-color-warning: #ffcf70;
	--red-ui-border-color-success: #4b8400;
	--red-ui-form-background: #fff;
	--red-ui-form-placeholder-color: #aaa;
	--red-ui-form-text-color: #555;
	--red-ui-form-text-color-disabled: #bbb;
	--red-ui-form-input-border-color: #ccc;
	--red-ui-form-input-border-color-focus: rgba(85, 150, 230, 0.8);
	--red-ui-form-input-border-color-selected: #aaa;
	--red-ui-form-input-border-color-error: #d6615f;
	--red-ui-form-input-background: #fff;
	--red-ui-form-input-background-disabled: #f9f9f9;
	--red-ui-form-button-background: #efefef;
	--red-ui-form-tips-background: #ffe;
	--red-ui-list-item-color: #555;
	--red-ui-list-item-secondary-color: #888;
	--red-ui-list-item-background: #fff;
	--red-ui-list-item-background-disabled: #f0f0f0;
	--red-ui-list-item-background-hover: #e6e6e6;
	--red-ui-list-item-background-selected: #ffebc7;
	--red-ui-list-item-border-selected: #666;
	--red-ui-shade-color: rgba(160, 160, 160, 0.5);
	--red-ui-node-link-port-background: #eee;
	--red-ui-node-status-error-border: #911002;
	--red-ui-node-status-error-background: #ff6600;
	--red-ui-node-status-changed-border: #1c668c;
	--red-ui-node-status-changed-background: #00bcff;
	--red-ui-node-border: #999;
	--red-ui-node-port-background: #d9d9d9;
	--red-ui-node-label-color: #333;
	--red-ui-node-selected-color: #ff7f0e;
	--red-ui-port-selected-color: #ff7f0e;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.button-group-vertical {
	display: inline-block;
	vertical-align: middle;
}

.button-group:not(:last-child) {
	margin-right: 10px;
}

.red-ui-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(160, 160, 160, 0.5);
	z-index: 5;
}

/**
  * Copyright JS Foundation and other contributors, http://js.foundation
  *
  * Licensed under the Apache License, Version 2.0 (the "License");
  * you may not use this file except in compliance with the License.
  * You may obtain a copy of the License at
  *
  * http://www.apache.org/licenses/LICENSE-2.0
  *
  * Unless required by applicable law or agreed to in writing, software
  * distributed under the License is distributed on an "AS IS" BASIS,
  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  * See the License for the specific language governing permissions and
  * limitations under the License.
  **/
body {
	overflow: hidden;
}

.red-ui-editor {
	font-size: 14px;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	padding: 0;
	margin: 0;
	background: #f3f3f3;
	color: #555;
	line-height: 20px;
}

#red-ui-editor {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

#red-ui-editor-node-configs {
	display: none;
}

#red-ui-main-container {
	position: absolute;
	top: 40px;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
}

#red-ui-palette-shade,
#red-ui-editor-shade,
#red-ui-header-shade,
#red-ui-sidebar-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(160, 160, 160, 0.5);
	z-index: 5;
	z-index: 2;
}

#red-ui-sidebar-shade {
	left: -8px;
	top: -1px;
	bottom: -1px;
}

#red-ui-full-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(160, 160, 160, 0.5);
	z-index: 5;
	z-index: 15;
}

.red-ui-editor a,
.red-ui-editor-dialog a,
.red-ui-menu a,
.red-ui-popover a,
.red-ui-typedInput-options a,
.red-ui-icon-picker a {
	text-decoration: none;
	color: #555;
}
.red-ui-editor a:hover,
.red-ui-editor a:focus,
.red-ui-editor-dialog a:hover,
.red-ui-editor-dialog a:focus,
.red-ui-menu a:hover,
.red-ui-menu a:focus,
.red-ui-popover a:hover,
.red-ui-popover a:focus,
.red-ui-typedInput-options a:hover,
.red-ui-typedInput-options a:focus,
.red-ui-icon-picker a:hover,
.red-ui-icon-picker a:focus {
	text-decoration: none;
	color: #555;
}
.red-ui-editor a:focus,
.red-ui-editor-dialog a:focus,
.red-ui-menu a:focus,
.red-ui-popover a:focus,
.red-ui-typedInput-options a:focus,
.red-ui-icon-picker a:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
}
.red-ui-editor p,
.red-ui-editor-dialog p,
.red-ui-menu p,
.red-ui-popover p,
.red-ui-typedInput-options p,
.red-ui-icon-picker p {
	margin: 0 0 10px;
}
.red-ui-editor small,
.red-ui-editor-dialog small,
.red-ui-menu small,
.red-ui-popover small,
.red-ui-typedInput-options small,
.red-ui-icon-picker small {
	font-size: 85%;
}
.red-ui-editor strong,
.red-ui-editor-dialog strong,
.red-ui-menu strong,
.red-ui-popover strong,
.red-ui-typedInput-options strong,
.red-ui-icon-picker strong {
	font-weight: bold;
}
.red-ui-editor em,
.red-ui-editor-dialog em,
.red-ui-menu em,
.red-ui-popover em,
.red-ui-typedInput-options em,
.red-ui-icon-picker em {
	font-style: italic;
}
.red-ui-editor cite,
.red-ui-editor-dialog cite,
.red-ui-menu cite,
.red-ui-popover cite,
.red-ui-typedInput-options cite,
.red-ui-icon-picker cite {
	font-style: normal;
}
.red-ui-editor ul,
.red-ui-editor ol,
.red-ui-editor-dialog ul,
.red-ui-editor-dialog ol,
.red-ui-menu ul,
.red-ui-menu ol,
.red-ui-popover ul,
.red-ui-popover ol,
.red-ui-typedInput-options ul,
.red-ui-typedInput-options ol,
.red-ui-icon-picker ul,
.red-ui-icon-picker ol {
	padding: 0;
	margin: 0 0 10px 25px;
}
.red-ui-editor ul ul,
.red-ui-editor ul ol,
.red-ui-editor ol ol,
.red-ui-editor ol ul,
.red-ui-editor-dialog ul ul,
.red-ui-editor-dialog ul ol,
.red-ui-editor-dialog ol ol,
.red-ui-editor-dialog ol ul,
.red-ui-menu ul ul,
.red-ui-menu ul ol,
.red-ui-menu ol ol,
.red-ui-menu ol ul,
.red-ui-popover ul ul,
.red-ui-popover ul ol,
.red-ui-popover ol ol,
.red-ui-popover ol ul,
.red-ui-typedInput-options ul ul,
.red-ui-typedInput-options ul ol,
.red-ui-typedInput-options ol ol,
.red-ui-typedInput-options ol ul,
.red-ui-icon-picker ul ul,
.red-ui-icon-picker ul ol,
.red-ui-icon-picker ol ol,
.red-ui-icon-picker ol ul {
	margin-bottom: 0;
}
.red-ui-editor li,
.red-ui-editor-dialog li,
.red-ui-menu li,
.red-ui-popover li,
.red-ui-typedInput-options li,
.red-ui-icon-picker li {
	line-height: 20px;
}
.red-ui-editor dl,
.red-ui-editor-dialog dl,
.red-ui-menu dl,
.red-ui-popover dl,
.red-ui-typedInput-options dl,
.red-ui-icon-picker dl {
	margin-bottom: 20px;
}
.red-ui-editor dt,
.red-ui-editor dd,
.red-ui-editor-dialog dt,
.red-ui-editor-dialog dd,
.red-ui-menu dt,
.red-ui-menu dd,
.red-ui-popover dt,
.red-ui-popover dd,
.red-ui-typedInput-options dt,
.red-ui-typedInput-options dd,
.red-ui-icon-picker dt,
.red-ui-icon-picker dd {
	line-height: 20px;
}
.red-ui-editor dt,
.red-ui-editor-dialog dt,
.red-ui-menu dt,
.red-ui-popover dt,
.red-ui-typedInput-options dt,
.red-ui-icon-picker dt {
	font-weight: bold;
}
.red-ui-editor dd,
.red-ui-editor-dialog dd,
.red-ui-menu dd,
.red-ui-popover dd,
.red-ui-typedInput-options dd,
.red-ui-icon-picker dd {
	margin-left: 10px;
}
.red-ui-editor hr,
.red-ui-editor-dialog hr,
.red-ui-menu hr,
.red-ui-popover hr,
.red-ui-typedInput-options hr,
.red-ui-icon-picker hr {
	margin: 20px 0;
	border: 0;
	border-top: 1px solid #ccc;
}
.red-ui-editor i.spinner,
.red-ui-editor-dialog i.spinner,
.red-ui-menu i.spinner,
.red-ui-popover i.spinner,
.red-ui-typedInput-options i.spinner,
.red-ui-icon-picker i.spinner {
	display: inline-block;
	width: 14px;
	height: 14px;
	line-height: 14px;
	vertical-align: text-top;
	margin-top: 0px;
	background: url(../../assets/red/spin.svg) no-repeat 50% 50%;
	background-size: contain;
}
.red-ui-editor .red-ui-font-code,
.red-ui-editor-dialog .red-ui-font-code,
.red-ui-menu .red-ui-font-code,
.red-ui-popover .red-ui-font-code,
.red-ui-typedInput-options .red-ui-font-code,
.red-ui-icon-picker .red-ui-font-code {
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	font-size: 14px;
	color: #ad1625;
	white-space: nowrap;
}
.red-ui-editor code,
.red-ui-editor-dialog code,
.red-ui-menu code,
.red-ui-popover code,
.red-ui-typedInput-options code,
.red-ui-icon-picker code {
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	font-size: 14px;
	padding: 0px;
	margin: 1px;
	color: #ad1625;
	white-space: nowrap;
}
.red-ui-editor pre,
.red-ui-editor-dialog pre,
.red-ui-menu pre,
.red-ui-popover pre,
.red-ui-typedInput-options pre,
.red-ui-icon-picker pre {
	display: block;
	padding: 9.5px;
	margin: 0 0 10px;
	line-height: 20px;
	word-break: break-all;
	word-wrap: break-word;
	white-space: pre-wrap;
	background-color: #f7f7f7;
	border: 1px solid #ccc;
	border-radius: 2px;
}
.red-ui-editor pre code,
.red-ui-editor-dialog pre code,
.red-ui-menu pre code,
.red-ui-popover pre code,
.red-ui-typedInput-options pre code,
.red-ui-icon-picker pre code {
	padding: 0;
	color: inherit;
	white-space: pre;
	white-space: pre-wrap;
	background-color: transparent;
	border: 0;
}
.red-ui-editor .pull-right,
.red-ui-editor-dialog .pull-right,
.red-ui-menu .pull-right,
.red-ui-popover .pull-right,
.red-ui-typedInput-options .pull-right,
.red-ui-icon-picker .pull-right {
	float: right;
}
.red-ui-editor .pull-left,
.red-ui-editor-dialog .pull-left,
.red-ui-menu .pull-left,
.red-ui-popover .pull-left,
.red-ui-typedInput-options .pull-left,
.red-ui-icon-picker .pull-left {
	float: left;
}
.red-ui-editor .hide,
.red-ui-editor-dialog .hide,
.red-ui-menu .hide,
.red-ui-popover .hide,
.red-ui-typedInput-options .hide,
.red-ui-icon-picker .hide {
	display: none;
}
.red-ui-editor .show,
.red-ui-editor-dialog .show,
.red-ui-menu .show,
.red-ui-popover .show,
.red-ui-typedInput-options .show,
.red-ui-icon-picker .show {
	display: block;
}
.red-ui-editor img,
.red-ui-editor-dialog img,
.red-ui-menu img,
.red-ui-popover img,
.red-ui-typedInput-options img,
.red-ui-icon-picker img {
	width: auto\9;
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	border: 0;
	-ms-interpolation-mode: bicubic;
}
.red-ui-editor blockquote,
.red-ui-editor-dialog blockquote,
.red-ui-menu blockquote,
.red-ui-popover blockquote,
.red-ui-typedInput-options blockquote,
.red-ui-icon-picker blockquote {
	padding: 0 0 0 15px;
	margin: 0 0 20px;
	border-left: 4px solid #dddddd;
	color: #888;
}
.red-ui-editor blockquote p,
.red-ui-editor-dialog blockquote p,
.red-ui-menu blockquote p,
.red-ui-popover blockquote p,
.red-ui-typedInput-options blockquote p,
.red-ui-icon-picker blockquote p {
	font-size: 14px;
	font-weight: inherit;
	line-height: 1.25;
	margin-bottom: 0;
}
.red-ui-editor table,
.red-ui-editor-dialog table,
.red-ui-menu table,
.red-ui-popover table,
.red-ui-typedInput-options table,
.red-ui-icon-picker table {
	max-width: 100%;
	background-color: transparent;
	border-collapse: collapse;
	border-spacing: 0;
}

.red-ui-component-spinner {
	position: absolute;
	top: 1px;
	bottom: 1px;
	left: 1px;
	right: 1px;
	text-align: center;
	padding: 40px;
	background: #fff;
}
.red-ui-component-spinner:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -0.25em;
}
.red-ui-component-spinner img {
	display: inline-block;
	vertical-align: middle;
	width: 80px;
}
.red-ui-component-spinner.red-ui-component-spinner-sidebar {
	background: #fff;
	padding: 0;
}
.red-ui-component-spinner.red-ui-component-spinner-sidebar img {
	width: 40px;
}
.red-ui-component-spinner.projects-version-control-spinner-sidebar {
	background: #fff;
	padding: 0;
}
.red-ui-component-spinner.projects-version-control-spinner-sidebar img {
	width: 20px;
}
.red-ui-component-spinner.red-ui-component-spinner-contain {
	padding: 0;
}
.red-ui-component-spinner.red-ui-component-spinner-contain img {
	width: auto;
	height: 100%;
	max-height: 50px;
}

/**
  * Copyright JS Foundation and other contributors, http://js.foundation
  *
  * Licensed under the Apache License, Version 2.0 (the "License");
  * you may not use this file except in compliance with the License.
  * You may obtain a copy of the License at
  *
  * http://www.apache.org/licenses/LICENSE-2.0
  *
  * Unless required by applicable law or agreed to in writing, software
  * distributed under the License is distributed on an "AS IS" BASIS,
  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  * See the License for the specific language governing permissions and
  * limitations under the License.
  **/
/*!
  * Extracted from Bootstrap v2.3.2
  *
  * Copyright 2013 Twitter, Inc
  * Licensed under the Apache License v2.0
  * http://www.apache.org/licenses/LICENSE-2.0
  *
  * Designed and built with all the love in the world by @mdo and @fat.
  */
.red-ui-editor,
.red-ui-editor-dialog {
	/* Allow for input prepend/append in search forms */
}
.red-ui-editor button,
.red-ui-editor input,
.red-ui-editor select,
.red-ui-editor div[contenteditable='true'],
.red-ui-editor textarea,
.red-ui-editor-dialog button,
.red-ui-editor-dialog input,
.red-ui-editor-dialog select,
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog textarea {
	margin: 0;
	font-size: 100%;
	vertical-align: middle;
}
.red-ui-editor button,
.red-ui-editor div[contenteditable='true'],
.red-ui-editor input,
.red-ui-editor-dialog button,
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog input {
	*overflow: visible;
	line-height: normal;
}
.red-ui-editor button::-moz-focus-inner,
.red-ui-editor div[contenteditable='true']::-moz-focus-inner,
.red-ui-editor input::-moz-focus-inner,
.red-ui-editor-dialog button::-moz-focus-inner,
.red-ui-editor-dialog div[contenteditable='true']::-moz-focus-inner,
.red-ui-editor-dialog input::-moz-focus-inner {
	padding: 0;
	border: 0;
}
.red-ui-editor button,
.red-ui-editor html input[type='button'],
.red-ui-editor input[type='reset'],
.red-ui-editor input[type='submit'],
.red-ui-editor-dialog button,
.red-ui-editor-dialog html input[type='button'],
.red-ui-editor-dialog input[type='reset'],
.red-ui-editor-dialog input[type='submit'] {
	cursor: pointer;
	-webkit-appearance: button;
}
.red-ui-editor label,
.red-ui-editor select,
.red-ui-editor button,
.red-ui-editor input[type='button'],
.red-ui-editor input[type='reset'],
.red-ui-editor input[type='submit'],
.red-ui-editor input[type='radio'],
.red-ui-editor input[type='checkbox'],
.red-ui-editor-dialog label,
.red-ui-editor-dialog select,
.red-ui-editor-dialog button,
.red-ui-editor-dialog input[type='button'],
.red-ui-editor-dialog input[type='reset'],
.red-ui-editor-dialog input[type='submit'],
.red-ui-editor-dialog input[type='radio'],
.red-ui-editor-dialog input[type='checkbox'] {
	cursor: pointer;
}
.red-ui-editor input[type='search'],
.red-ui-editor-dialog input[type='search'] {
	box-sizing: content-box;
}
.red-ui-editor input[type='search']::-webkit-search-decoration,
.red-ui-editor input[type='search']::-webkit-search-cancel-button,
.red-ui-editor-dialog input[type='search']::-webkit-search-decoration,
.red-ui-editor-dialog input[type='search']::-webkit-search-cancel-button {
	-webkit-appearance: none;
}
.red-ui-editor textarea,
.red-ui-editor-dialog textarea {
	overflow: auto;
	vertical-align: top;
}
.red-ui-editor form,
.red-ui-editor-dialog form {
	margin: 0 0 20px;
}
.red-ui-editor fieldset,
.red-ui-editor-dialog fieldset {
	padding: 0;
	margin: 0;
	border: 0;
}
.red-ui-editor legend,
.red-ui-editor-dialog legend {
	display: block;
	width: 100%;
	padding: 0;
	margin-bottom: 20px;
	font-size: 21px;
	line-height: 40px;
	color: #aaa;
	border: 0;
	border-bottom: 1px solid #dddddd;
}
.red-ui-editor legend small,
.red-ui-editor-dialog legend small {
	color: #aaa;
}
.red-ui-editor label,
.red-ui-editor input,
.red-ui-editor div[contenteditable='true'],
.red-ui-editor button,
.red-ui-editor select,
.red-ui-editor textarea,
.red-ui-editor-dialog label,
.red-ui-editor-dialog input,
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog button,
.red-ui-editor-dialog select,
.red-ui-editor-dialog textarea {
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
}
.red-ui-editor input,
.red-ui-editor div[contenteditable='true'],
.red-ui-editor button,
.red-ui-editor select,
.red-ui-editor textarea,
.red-ui-editor-dialog input,
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog button,
.red-ui-editor-dialog select,
.red-ui-editor-dialog textarea {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}
.red-ui-editor label,
.red-ui-editor-dialog label {
	display: block;
	margin-bottom: 5px;
}
.red-ui-editor select,
.red-ui-editor textarea,
.red-ui-editor input[type='text'],
.red-ui-editor input[type='password'],
.red-ui-editor input[type='datetime'],
.red-ui-editor input[type='datetime-local'],
.red-ui-editor input[type='date'],
.red-ui-editor input[type='month'],
.red-ui-editor input[type='time'],
.red-ui-editor input[type='week'],
.red-ui-editor input[type='number'],
.red-ui-editor input[type='email'],
.red-ui-editor input[type='url'],
.red-ui-editor input[type='search'],
.red-ui-editor input[type='tel'],
.red-ui-editor input[type='color'],
.red-ui-editor div[contenteditable='true'],
.red-ui-editor .uneditable-input,
.red-ui-editor .placeholder-input,
.red-ui-editor-dialog select,
.red-ui-editor-dialog textarea,
.red-ui-editor-dialog input[type='text'],
.red-ui-editor-dialog input[type='password'],
.red-ui-editor-dialog input[type='datetime'],
.red-ui-editor-dialog input[type='datetime-local'],
.red-ui-editor-dialog input[type='date'],
.red-ui-editor-dialog input[type='month'],
.red-ui-editor-dialog input[type='time'],
.red-ui-editor-dialog input[type='week'],
.red-ui-editor-dialog input[type='number'],
.red-ui-editor-dialog input[type='email'],
.red-ui-editor-dialog input[type='url'],
.red-ui-editor-dialog input[type='search'],
.red-ui-editor-dialog input[type='tel'],
.red-ui-editor-dialog input[type='color'],
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog .uneditable-input,
.red-ui-editor-dialog .placeholder-input {
	box-sizing: border-box;
	display: inline-block;
	height: 34px;
	padding: 6px 6px;
	margin-bottom: 10px;
	font-size: 14px;
	line-height: 20px;
	color: #555;
	vertical-align: middle;
	border-radius: 4px;
}
.red-ui-editor input,
.red-ui-editor textarea,
.red-ui-editor div[contenteditable='true'],
.red-ui-editor .uneditable-input,
.red-ui-editor-dialog input,
.red-ui-editor-dialog textarea,
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog .uneditable-input {
	width: 206px;
}
.red-ui-editor textarea,
.red-ui-editor-dialog textarea {
	height: auto;
}
.red-ui-editor textarea,
.red-ui-editor input[type='text'],
.red-ui-editor input[type='password'],
.red-ui-editor input[type='datetime'],
.red-ui-editor input[type='datetime-local'],
.red-ui-editor input[type='date'],
.red-ui-editor input[type='month'],
.red-ui-editor input[type='time'],
.red-ui-editor input[type='week'],
.red-ui-editor input[type='number'],
.red-ui-editor input[type='email'],
.red-ui-editor input[type='url'],
.red-ui-editor input[type='search'],
.red-ui-editor input[type='tel'],
.red-ui-editor input[type='color'],
.red-ui-editor div[contenteditable='true'],
.red-ui-editor .uneditable-input,
.red-ui-editor .placeholder-input,
.red-ui-editor-dialog textarea,
.red-ui-editor-dialog input[type='text'],
.red-ui-editor-dialog input[type='password'],
.red-ui-editor-dialog input[type='datetime'],
.red-ui-editor-dialog input[type='datetime-local'],
.red-ui-editor-dialog input[type='date'],
.red-ui-editor-dialog input[type='month'],
.red-ui-editor-dialog input[type='time'],
.red-ui-editor-dialog input[type='week'],
.red-ui-editor-dialog input[type='number'],
.red-ui-editor-dialog input[type='email'],
.red-ui-editor-dialog input[type='url'],
.red-ui-editor-dialog input[type='search'],
.red-ui-editor-dialog input[type='tel'],
.red-ui-editor-dialog input[type='color'],
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog .uneditable-input,
.red-ui-editor-dialog .placeholder-input {
	background-color: #fff;
	border: 1px solid #ccc;
}
.red-ui-editor textarea:focus,
.red-ui-editor input[type='text']:focus,
.red-ui-editor input[type='password']:focus,
.red-ui-editor input[type='datetime']:focus,
.red-ui-editor input[type='datetime-local']:focus,
.red-ui-editor input[type='date']:focus,
.red-ui-editor input[type='month']:focus,
.red-ui-editor input[type='time']:focus,
.red-ui-editor input[type='week']:focus,
.red-ui-editor input[type='number']:focus,
.red-ui-editor input[type='email']:focus,
.red-ui-editor input[type='url']:focus,
.red-ui-editor input[type='search']:focus,
.red-ui-editor input[type='tel']:focus,
.red-ui-editor input[type='color']:focus,
.red-ui-editor div[contenteditable='true']:focus,
.red-ui-editor .uneditable-input:focus,
.red-ui-editor-dialog textarea:focus,
.red-ui-editor-dialog input[type='text']:focus,
.red-ui-editor-dialog input[type='password']:focus,
.red-ui-editor-dialog input[type='datetime']:focus,
.red-ui-editor-dialog input[type='datetime-local']:focus,
.red-ui-editor-dialog input[type='date']:focus,
.red-ui-editor-dialog input[type='month']:focus,
.red-ui-editor-dialog input[type='time']:focus,
.red-ui-editor-dialog input[type='week']:focus,
.red-ui-editor-dialog input[type='number']:focus,
.red-ui-editor-dialog input[type='email']:focus,
.red-ui-editor-dialog input[type='url']:focus,
.red-ui-editor-dialog input[type='search']:focus,
.red-ui-editor-dialog input[type='tel']:focus,
.red-ui-editor-dialog input[type='color']:focus,
.red-ui-editor-dialog div[contenteditable='true']:focus,
.red-ui-editor-dialog .uneditable-input:focus {
	border-color: rgba(85, 150, 230, 0.8);
	outline: 0;
	outline: thin dotted \9;
}
.red-ui-editor input[type='radio'],
.red-ui-editor input[type='checkbox'],
.red-ui-editor-dialog input[type='radio'],
.red-ui-editor-dialog input[type='checkbox'] {
	margin: 4px 0 0;
	margin-top: 1px \9;
	*margin-top: 0;
	line-height: normal;
}
.red-ui-editor input[type='file'],
.red-ui-editor input[type='image'],
.red-ui-editor input[type='submit'],
.red-ui-editor input[type='reset'],
.red-ui-editor input[type='button'],
.red-ui-editor input[type='radio'],
.red-ui-editor input[type='checkbox'],
.red-ui-editor-dialog input[type='file'],
.red-ui-editor-dialog input[type='image'],
.red-ui-editor-dialog input[type='submit'],
.red-ui-editor-dialog input[type='reset'],
.red-ui-editor-dialog input[type='button'],
.red-ui-editor-dialog input[type='radio'],
.red-ui-editor-dialog input[type='checkbox'] {
	width: auto;
}
.red-ui-editor select,
.red-ui-editor input[type='file'],
.red-ui-editor-dialog select,
.red-ui-editor-dialog input[type='file'] {
	height: 34px;
	line-height: 34px;
}
.red-ui-editor select,
.red-ui-editor-dialog select {
	width: 220px;
	background-color: #fff;
	border: 1px solid #ccc;
}
.red-ui-editor select[multiple],
.red-ui-editor select[size],
.red-ui-editor-dialog select[multiple],
.red-ui-editor-dialog select[size] {
	height: auto;
}
.red-ui-editor select:focus,
.red-ui-editor input[type='file']:focus,
.red-ui-editor input[type='radio']:focus,
.red-ui-editor input[type='checkbox']:focus,
.red-ui-editor-dialog select:focus,
.red-ui-editor-dialog input[type='file']:focus,
.red-ui-editor-dialog input[type='radio']:focus,
.red-ui-editor-dialog input[type='checkbox']:focus {
	outline: 2px auto rgba(85, 150, 230, 0.8);
	outline-offset: -3px;
}
.red-ui-editor .uneditable-input,
.red-ui-editor .uneditable-textarea,
.red-ui-editor-dialog .uneditable-input,
.red-ui-editor-dialog .uneditable-textarea {
	color: #bbb;
	cursor: not-allowed;
	background-color: #f9f9f9;
	border-color: #ccc;
}
.red-ui-editor .uneditable-input,
.red-ui-editor-dialog .uneditable-input {
	overflow: hidden;
	white-space: nowrap;
}
.red-ui-editor .uneditable-textarea,
.red-ui-editor-dialog .uneditable-textarea {
	width: auto;
	height: auto;
}
.red-ui-editor input:-moz-placeholder,
.red-ui-editor textarea:-moz-placeholder,
.red-ui-editor-dialog input:-moz-placeholder,
.red-ui-editor-dialog textarea:-moz-placeholder {
	color: #aaa;
}
.red-ui-editor input:-ms-input-placeholder,
.red-ui-editor div[contenteditable='true']:-ms-input-placeholder,
.red-ui-editor textarea:-ms-input-placeholder,
.red-ui-editor-dialog input:-ms-input-placeholder,
.red-ui-editor-dialog div[contenteditable='true']:-ms-input-placeholder,
.red-ui-editor-dialog textarea:-ms-input-placeholder {
	color: #aaa;
}
.red-ui-editor input::-webkit-input-placeholder,
.red-ui-editor div[contenteditable='true']::-webkit-input-placeholder,
.red-ui-editor textarea::-webkit-input-placeholder,
.red-ui-editor-dialog input::-webkit-input-placeholder,
.red-ui-editor-dialog div[contenteditable='true']::-webkit-input-placeholder,
.red-ui-editor-dialog textarea::-webkit-input-placeholder {
	color: #aaa;
}
.red-ui-editor .radio,
.red-ui-editor .checkbox,
.red-ui-editor-dialog .radio,
.red-ui-editor-dialog .checkbox {
	min-height: 20px;
	padding-left: 20px;
}
.red-ui-editor .radio input[type='radio'],
.red-ui-editor .checkbox input[type='checkbox'],
.red-ui-editor-dialog .radio input[type='radio'],
.red-ui-editor-dialog .checkbox input[type='checkbox'] {
	float: left;
	margin-left: -20px;
}
.red-ui-editor .controls > .radio:first-child,
.red-ui-editor .controls > .checkbox:first-child,
.red-ui-editor-dialog .controls > .radio:first-child,
.red-ui-editor-dialog .controls > .checkbox:first-child {
	padding-top: 5px;
}
.red-ui-editor .radio.inline,
.red-ui-editor .checkbox.inline,
.red-ui-editor-dialog .radio.inline,
.red-ui-editor-dialog .checkbox.inline {
	display: inline-block;
	padding-top: 5px;
	margin-bottom: 0;
	vertical-align: middle;
}
.red-ui-editor .radio.inline + .radio.inline,
.red-ui-editor .checkbox.inline + .checkbox.inline,
.red-ui-editor-dialog .radio.inline + .radio.inline,
.red-ui-editor-dialog .checkbox.inline + .checkbox.inline {
	margin-left: 10px;
}
.red-ui-editor .input-mini,
.red-ui-editor-dialog .input-mini {
	width: 60px;
}
.red-ui-editor .input-small,
.red-ui-editor-dialog .input-small {
	width: 90px;
}
.red-ui-editor .input-medium,
.red-ui-editor-dialog .input-medium {
	width: 150px;
}
.red-ui-editor .input-large,
.red-ui-editor-dialog .input-large {
	width: 210px;
}
.red-ui-editor .input-xlarge,
.red-ui-editor-dialog .input-xlarge {
	width: 270px;
}
.red-ui-editor .input-xxlarge,
.red-ui-editor-dialog .input-xxlarge {
	width: 530px;
}
.red-ui-editor input[class*='span'],
.red-ui-editor select[class*='span'],
.red-ui-editor textarea[class*='span'],
.red-ui-editor .uneditable-input[class*='span'],
.red-ui-editor .row-fluid input[class*='span'],
.red-ui-editor .row-fluid select[class*='span'],
.red-ui-editor .row-fluid textarea[class*='span'],
.red-ui-editor .row-fluid .uneditable-input[class*='span'],
.red-ui-editor-dialog input[class*='span'],
.red-ui-editor-dialog select[class*='span'],
.red-ui-editor-dialog textarea[class*='span'],
.red-ui-editor-dialog .uneditable-input[class*='span'],
.red-ui-editor-dialog .row-fluid input[class*='span'],
.red-ui-editor-dialog .row-fluid select[class*='span'],
.red-ui-editor-dialog .row-fluid textarea[class*='span'],
.red-ui-editor-dialog .row-fluid .uneditable-input[class*='span'] {
	float: none;
	margin-left: 0;
}
.red-ui-editor .input-append input[class*='span'],
.red-ui-editor .input-append .uneditable-input[class*='span'],
.red-ui-editor .input-prepend input[class*='span'],
.red-ui-editor .input-prepend .uneditable-input[class*='span'],
.red-ui-editor .row-fluid input[class*='span'],
.red-ui-editor .row-fluid select[class*='span'],
.red-ui-editor .row-fluid textarea[class*='span'],
.red-ui-editor .row-fluid .uneditable-input[class*='span'],
.red-ui-editor .row-fluid .input-prepend [class*='span'],
.red-ui-editor .row-fluid .input-append [class*='span'],
.red-ui-editor-dialog .input-append input[class*='span'],
.red-ui-editor-dialog .input-append .uneditable-input[class*='span'],
.red-ui-editor-dialog .input-prepend input[class*='span'],
.red-ui-editor-dialog .input-prepend .uneditable-input[class*='span'],
.red-ui-editor-dialog .row-fluid input[class*='span'],
.red-ui-editor-dialog .row-fluid select[class*='span'],
.red-ui-editor-dialog .row-fluid textarea[class*='span'],
.red-ui-editor-dialog .row-fluid .uneditable-input[class*='span'],
.red-ui-editor-dialog .row-fluid .input-prepend [class*='span'],
.red-ui-editor-dialog .row-fluid .input-append [class*='span'] {
	display: inline-block;
}
.red-ui-editor input,
.red-ui-editor textarea,
.red-ui-editor div[contenteditable='true'],
.red-ui-editor .uneditable-input,
.red-ui-editor-dialog input,
.red-ui-editor-dialog textarea,
.red-ui-editor-dialog div[contenteditable='true'],
.red-ui-editor-dialog .uneditable-input {
	margin-left: 0;
}
.red-ui-editor label.disabled,
.red-ui-editor-dialog label.disabled {
	color: #bbb;
	cursor: default;
}
.red-ui-editor input[disabled],
.red-ui-editor select[disabled],
.red-ui-editor textarea[disabled],
.red-ui-editor input[readonly],
.red-ui-editor select[readonly],
.red-ui-editor textarea[readonly],
.red-ui-editor-dialog input[disabled],
.red-ui-editor-dialog select[disabled],
.red-ui-editor-dialog textarea[disabled],
.red-ui-editor-dialog input[readonly],
.red-ui-editor-dialog select[readonly],
.red-ui-editor-dialog textarea[readonly] {
	cursor: not-allowed;
	color: #bbb;
	background-color: #f9f9f9;
}
.red-ui-editor input[type='radio'][disabled],
.red-ui-editor input[type='checkbox'][disabled],
.red-ui-editor input[type='radio'][readonly],
.red-ui-editor input[type='checkbox'][readonly],
.red-ui-editor-dialog input[type='radio'][disabled],
.red-ui-editor-dialog input[type='checkbox'][disabled],
.red-ui-editor-dialog input[type='radio'][readonly],
.red-ui-editor-dialog input[type='checkbox'][readonly] {
	background-color: transparent;
}
.red-ui-editor input:invalid,
.red-ui-editor div[contenteditable='true']:invalid,
.red-ui-editor textarea:invalid,
.red-ui-editor select:invalid,
.red-ui-editor-dialog input:invalid,
.red-ui-editor-dialog div[contenteditable='true']:invalid,
.red-ui-editor-dialog textarea:invalid,
.red-ui-editor-dialog select:invalid {
	border-color: #d6615f;
}
.red-ui-editor input:focus:invalid,
.red-ui-editor div[contenteditable='true']:focus:invalid,
.red-ui-editor textarea:focus:invalid,
.red-ui-editor select:focus:invalid,
.red-ui-editor-dialog input:focus:invalid,
.red-ui-editor-dialog div[contenteditable='true']:focus:invalid,
.red-ui-editor-dialog textarea:focus:invalid,
.red-ui-editor-dialog select:focus:invalid {
	border-color: #d6615f;
}
.red-ui-editor input:focus:invalid:focus,
.red-ui-editor div[contenteditable='true']:focus:invalid:focus,
.red-ui-editor textarea:focus:invalid:focus,
.red-ui-editor select:focus:invalid:focus,
.red-ui-editor-dialog input:focus:invalid:focus,
.red-ui-editor-dialog div[contenteditable='true']:focus:invalid:focus,
.red-ui-editor-dialog textarea:focus:invalid:focus,
.red-ui-editor-dialog select:focus:invalid:focus {
	border-color: #d6615f;
}
.red-ui-editor .input-append,
.red-ui-editor .input-prepend,
.red-ui-editor-dialog .input-append,
.red-ui-editor-dialog .input-prepend {
	display: inline-block;
	margin-bottom: 10px;
	font-size: 0;
	white-space: nowrap;
	vertical-align: middle;
}
.red-ui-editor .input-append input,
.red-ui-editor .input-prepend input,
.red-ui-editor .input-append div[contenteditable='true'],
.red-ui-editor .input-prepend div[contenteditable='true'],
.red-ui-editor .input-append select,
.red-ui-editor .input-prepend select,
.red-ui-editor .input-append .uneditable-input,
.red-ui-editor .input-prepend .uneditable-input,
.red-ui-editor .input-append .red-ui-menu-dropdown,
.red-ui-editor .input-prepend .red-ui-menu-dropdown,
.red-ui-editor .input-append .popover,
.red-ui-editor .input-prepend .popover,
.red-ui-editor-dialog .input-append input,
.red-ui-editor-dialog .input-prepend input,
.red-ui-editor-dialog .input-append div[contenteditable='true'],
.red-ui-editor-dialog .input-prepend div[contenteditable='true'],
.red-ui-editor-dialog .input-append select,
.red-ui-editor-dialog .input-prepend select,
.red-ui-editor-dialog .input-append .uneditable-input,
.red-ui-editor-dialog .input-prepend .uneditable-input,
.red-ui-editor-dialog .input-append .red-ui-menu-dropdown,
.red-ui-editor-dialog .input-prepend .red-ui-menu-dropdown,
.red-ui-editor-dialog .input-append .popover,
.red-ui-editor-dialog .input-prepend .popover {
	font-size: 14px;
}
.red-ui-editor .input-append input,
.red-ui-editor .input-prepend input,
.red-ui-editor .input-append div[contenteditable='true'],
.red-ui-editor .input-prepend div[contenteditable='true'],
.red-ui-editor .input-append select,
.red-ui-editor .input-prepend select,
.red-ui-editor .input-append .uneditable-input,
.red-ui-editor .input-prepend .uneditable-input,
.red-ui-editor-dialog .input-append input,
.red-ui-editor-dialog .input-prepend input,
.red-ui-editor-dialog .input-append div[contenteditable='true'],
.red-ui-editor-dialog .input-prepend div[contenteditable='true'],
.red-ui-editor-dialog .input-append select,
.red-ui-editor-dialog .input-prepend select,
.red-ui-editor-dialog .input-append .uneditable-input,
.red-ui-editor-dialog .input-prepend .uneditable-input {
	position: relative;
	margin-bottom: 0;
	*margin-left: 0;
	vertical-align: top;
	border-radius: 0 4px 4px 0;
}
.red-ui-editor .input-append input:focus,
.red-ui-editor .input-prepend input:focus,
.red-ui-editor .input-append div[contenteditable='true']:focus,
.red-ui-editor .input-prepend div[contenteditable='true']:focus,
.red-ui-editor .input-append select:focus,
.red-ui-editor .input-prepend select:focus,
.red-ui-editor .input-append .uneditable-input:focus,
.red-ui-editor .input-prepend .uneditable-input:focus,
.red-ui-editor-dialog .input-append input:focus,
.red-ui-editor-dialog .input-prepend input:focus,
.red-ui-editor-dialog .input-append div[contenteditable='true']:focus,
.red-ui-editor-dialog .input-prepend div[contenteditable='true']:focus,
.red-ui-editor-dialog .input-append select:focus,
.red-ui-editor-dialog .input-prepend select:focus,
.red-ui-editor-dialog .input-append .uneditable-input:focus,
.red-ui-editor-dialog .input-prepend .uneditable-input:focus {
	z-index: 2;
}
.red-ui-editor .input-append .add-on,
.red-ui-editor .input-prepend .add-on,
.red-ui-editor-dialog .input-append .add-on,
.red-ui-editor-dialog .input-prepend .add-on {
	display: inline-block;
	width: auto;
	height: 20px;
	min-width: 16px;
	padding: 4px 5px;
	font-size: 14px;
	font-weight: normal;
	line-height: 20px;
	text-align: center;
	background-color: #efefef;
	border: 1px solid #ccc;
}
.red-ui-editor .input-append .add-on,
.red-ui-editor .input-prepend .add-on,
.red-ui-editor .input-append .btn,
.red-ui-editor .input-prepend .btn,
.red-ui-editor .input-append .btn-group > .dropdown-toggle,
.red-ui-editor .input-prepend .btn-group > .dropdown-toggle,
.red-ui-editor-dialog .input-append .add-on,
.red-ui-editor-dialog .input-prepend .add-on,
.red-ui-editor-dialog .input-append .btn,
.red-ui-editor-dialog .input-prepend .btn,
.red-ui-editor-dialog .input-append .btn-group > .dropdown-toggle,
.red-ui-editor-dialog .input-prepend .btn-group > .dropdown-toggle {
	vertical-align: top;
	border-radius: 0;
}
.red-ui-editor .input-prepend .add-on,
.red-ui-editor .input-prepend .btn,
.red-ui-editor-dialog .input-prepend .add-on,
.red-ui-editor-dialog .input-prepend .btn {
	margin-right: -1px;
}
.red-ui-editor .input-prepend .add-on:first-child,
.red-ui-editor .input-prepend .btn:first-child,
.red-ui-editor-dialog .input-prepend .add-on:first-child,
.red-ui-editor-dialog .input-prepend .btn:first-child {
	border-radius: 4px 0 0 4px;
}
.red-ui-editor .input-append input,
.red-ui-editor .input-append div[contenteditable='true'],
.red-ui-editor .input-append select,
.red-ui-editor .input-append .uneditable-input,
.red-ui-editor-dialog .input-append input,
.red-ui-editor-dialog .input-append div[contenteditable='true'],
.red-ui-editor-dialog .input-append select,
.red-ui-editor-dialog .input-append .uneditable-input {
	border-radius: 4px 0 0 4px;
}
.red-ui-editor .input-append input + .btn-group .btn:last-child,
.red-ui-editor .input-append select + .btn-group .btn:last-child,
.red-ui-editor .input-append .uneditable-input + .btn-group .btn:last-child,
.red-ui-editor-dialog .input-append input + .btn-group .btn:last-child,
.red-ui-editor-dialog .input-append select + .btn-group .btn:last-child,
.red-ui-editor-dialog
	.input-append
	.uneditable-input
	+ .btn-group
	.btn:last-child {
	border-radius: 0 4px 4px 0;
}
.red-ui-editor .input-append .add-on,
.red-ui-editor .input-append .btn,
.red-ui-editor .input-append .btn-group,
.red-ui-editor-dialog .input-append .add-on,
.red-ui-editor-dialog .input-append .btn,
.red-ui-editor-dialog .input-append .btn-group {
	margin-left: -1px;
}
.red-ui-editor .input-append .add-on:last-child,
.red-ui-editor .input-append .btn:last-child,
.red-ui-editor .input-append .btn-group:last-child > .dropdown-toggle,
.red-ui-editor-dialog .input-append .add-on:last-child,
.red-ui-editor-dialog .input-append .btn:last-child,
.red-ui-editor-dialog .input-append .btn-group:last-child > .dropdown-toggle {
	border-radius: 0 4px 4px 0;
}
.red-ui-editor .input-prepend.input-append input,
.red-ui-editor .input-prepend.input-append div[contenteditable='true'],
.red-ui-editor .input-prepend.input-append select,
.red-ui-editor .input-prepend.input-append .uneditable-input,
.red-ui-editor-dialog .input-prepend.input-append input,
.red-ui-editor-dialog .input-prepend.input-append div[contenteditable='true'],
.red-ui-editor-dialog .input-prepend.input-append select,
.red-ui-editor-dialog .input-prepend.input-append .uneditable-input {
	border-radius: 0;
}
.red-ui-editor .input-prepend.input-append input + .btn-group .btn,
.red-ui-editor .input-prepend.input-append select + .btn-group .btn,
.red-ui-editor .input-prepend.input-append .uneditable-input + .btn-group .btn,
.red-ui-editor-dialog .input-prepend.input-append input + .btn-group .btn,
.red-ui-editor-dialog .input-prepend.input-append select + .btn-group .btn,
.red-ui-editor-dialog
	.input-prepend.input-append
	.uneditable-input
	+ .btn-group
	.btn {
	border-radius: 0 4px 4px 0;
}
.red-ui-editor .input-prepend.input-append .add-on:first-child,
.red-ui-editor .input-prepend.input-append .btn:first-child,
.red-ui-editor-dialog .input-prepend.input-append .add-on:first-child,
.red-ui-editor-dialog .input-prepend.input-append .btn:first-child {
	margin-right: -1px;
	border-radius: 4px 0 0 4px;
}
.red-ui-editor .input-prepend.input-append .add-on:last-child,
.red-ui-editor .input-prepend.input-append .btn:last-child,
.red-ui-editor-dialog .input-prepend.input-append .add-on:last-child,
.red-ui-editor-dialog .input-prepend.input-append .btn:last-child {
	margin-left: -1px;
	border-radius: 0 4px 4px 0;
}
.red-ui-editor .input-prepend.input-append .btn-group:first-child,
.red-ui-editor-dialog .input-prepend.input-append .btn-group:first-child {
	margin-left: 0;
}
.red-ui-editor input.search-query,
.red-ui-editor-dialog input.search-query {
	padding-right: 14px;
	padding-right: 4px \9;
	padding-left: 14px;
	padding-left: 4px \9;
	/* IE7-8 doesn't have border-radius, so don't indent the padding */
	margin-bottom: 0;
	border-radius: 15px;
}
.red-ui-editor .form-search .input-append .search-query,
.red-ui-editor .form-search .input-prepend .search-query,
.red-ui-editor-dialog .form-search .input-append .search-query,
.red-ui-editor-dialog .form-search .input-prepend .search-query {
	border-radius: 0;
}
.red-ui-editor .form-search .input-append .search-query,
.red-ui-editor-dialog .form-search .input-append .search-query {
	border-radius: 14px 0 0 14px;
}
.red-ui-editor .form-search .input-append .btn,
.red-ui-editor-dialog .form-search .input-append .btn {
	border-radius: 0 14px 14px 0;
}
.red-ui-editor .form-search .input-prepend .search-query,
.red-ui-editor-dialog .form-search .input-prepend .search-query {
	border-radius: 0 14px 14px 0;
}
.red-ui-editor .form-search .input-prepend .btn,
.red-ui-editor-dialog .form-search .input-prepend .btn {
	border-radius: 14px 0 0 14px;
}
.red-ui-editor .form-search input,
.red-ui-editor .form-inline input,
.red-ui-editor .form-horizontal input,
.red-ui-editor .form-search div[contenteditable='true'],
.red-ui-editor .form-inline div[contenteditable='true'],
.red-ui-editor .form-horizontal div[contenteditable='true'],
.red-ui-editor .form-search textarea,
.red-ui-editor .form-inline textarea,
.red-ui-editor .form-horizontal textarea,
.red-ui-editor .form-search select,
.red-ui-editor .form-inline select,
.red-ui-editor .form-horizontal select,
.red-ui-editor .form-search .help-inline,
.red-ui-editor .form-inline .help-inline,
.red-ui-editor .form-horizontal .help-inline,
.red-ui-editor .form-search .uneditable-input,
.red-ui-editor .form-inline .uneditable-input,
.red-ui-editor .form-horizontal .uneditable-input,
.red-ui-editor .form-search .input-prepend,
.red-ui-editor .form-inline .input-prepend,
.red-ui-editor .form-horizontal .input-prepend,
.red-ui-editor .form-search .input-append,
.red-ui-editor .form-inline .input-append,
.red-ui-editor .form-horizontal .input-append,
.red-ui-editor-dialog .form-search input,
.red-ui-editor-dialog .form-inline input,
.red-ui-editor-dialog .form-horizontal input,
.red-ui-editor-dialog .form-search div[contenteditable='true'],
.red-ui-editor-dialog .form-inline div[contenteditable='true'],
.red-ui-editor-dialog .form-horizontal div[contenteditable='true'],
.red-ui-editor-dialog .form-search textarea,
.red-ui-editor-dialog .form-inline textarea,
.red-ui-editor-dialog .form-horizontal textarea,
.red-ui-editor-dialog .form-search select,
.red-ui-editor-dialog .form-inline select,
.red-ui-editor-dialog .form-horizontal select,
.red-ui-editor-dialog .form-search .help-inline,
.red-ui-editor-dialog .form-inline .help-inline,
.red-ui-editor-dialog .form-horizontal .help-inline,
.red-ui-editor-dialog .form-search .uneditable-input,
.red-ui-editor-dialog .form-inline .uneditable-input,
.red-ui-editor-dialog .form-horizontal .uneditable-input,
.red-ui-editor-dialog .form-search .input-prepend,
.red-ui-editor-dialog .form-inline .input-prepend,
.red-ui-editor-dialog .form-horizontal .input-prepend,
.red-ui-editor-dialog .form-search .input-append,
.red-ui-editor-dialog .form-inline .input-append,
.red-ui-editor-dialog .form-horizontal .input-append {
	display: inline-block;
	*display: inline;
	margin-bottom: 0;
	vertical-align: middle;
	*zoom: 1;
}
.red-ui-editor .form-search .hide,
.red-ui-editor .form-inline .hide,
.red-ui-editor .form-horizontal .hide,
.red-ui-editor-dialog .form-search .hide,
.red-ui-editor-dialog .form-inline .hide,
.red-ui-editor-dialog .form-horizontal .hide {
	display: none;
}
.red-ui-editor .form-search label,
.red-ui-editor .form-inline label,
.red-ui-editor .form-search .btn-group,
.red-ui-editor .form-inline .btn-group,
.red-ui-editor-dialog .form-search label,
.red-ui-editor-dialog .form-inline label,
.red-ui-editor-dialog .form-search .btn-group,
.red-ui-editor-dialog .form-inline .btn-group {
	display: inline-block;
}
.red-ui-editor .form-search .input-append,
.red-ui-editor .form-inline .input-append,
.red-ui-editor .form-search .input-prepend,
.red-ui-editor .form-inline .input-prepend,
.red-ui-editor-dialog .form-search .input-append,
.red-ui-editor-dialog .form-inline .input-append,
.red-ui-editor-dialog .form-search .input-prepend,
.red-ui-editor-dialog .form-inline .input-prepend {
	margin-bottom: 0;
}
.red-ui-editor .form-search .radio,
.red-ui-editor .form-search .checkbox,
.red-ui-editor .form-inline .radio,
.red-ui-editor .form-inline .checkbox,
.red-ui-editor-dialog .form-search .radio,
.red-ui-editor-dialog .form-search .checkbox,
.red-ui-editor-dialog .form-inline .radio,
.red-ui-editor-dialog .form-inline .checkbox {
	padding-left: 0;
	margin-bottom: 0;
	vertical-align: middle;
}
.red-ui-editor .form-search .radio input[type='radio'],
.red-ui-editor .form-search .checkbox input[type='checkbox'],
.red-ui-editor .form-inline .radio input[type='radio'],
.red-ui-editor .form-inline .checkbox input[type='checkbox'],
.red-ui-editor-dialog .form-search .radio input[type='radio'],
.red-ui-editor-dialog .form-search .checkbox input[type='checkbox'],
.red-ui-editor-dialog .form-inline .radio input[type='radio'],
.red-ui-editor-dialog .form-inline .checkbox input[type='checkbox'] {
	float: left;
	margin-right: 3px;
	margin-left: 0;
}
.red-ui-editor .control-group,
.red-ui-editor-dialog .control-group {
	margin-bottom: 10px;
}
.red-ui-editor legend + .control-group,
.red-ui-editor-dialog legend + .control-group {
	margin-top: 20px;
	-webkit-margin-top-collapse: separate;
}
.red-ui-editor .form-horizontal .control-group,
.red-ui-editor-dialog .form-horizontal .control-group {
	margin-bottom: 20px;
	*zoom: 1;
}
.red-ui-editor .form-horizontal .control-group:before,
.red-ui-editor .form-horizontal .control-group:after,
.red-ui-editor-dialog .form-horizontal .control-group:before,
.red-ui-editor-dialog .form-horizontal .control-group:after {
	display: table;
	line-height: 0;
	content: '';
}
.red-ui-editor .form-horizontal .control-group:after,
.red-ui-editor-dialog .form-horizontal .control-group:after {
	clear: both;
}
.red-ui-editor .form-horizontal .control-label,
.red-ui-editor-dialog .form-horizontal .control-label {
	float: left;
	width: 160px;
	padding-top: 5px;
	text-align: right;
}
.red-ui-editor .form-horizontal .controls,
.red-ui-editor-dialog .form-horizontal .controls {
	*display: inline-block;
	*padding-left: 20px;
	margin-left: 180px;
	*margin-left: 0;
}
.red-ui-editor .form-horizontal .controls:first-child,
.red-ui-editor-dialog .form-horizontal .controls:first-child {
	*padding-left: 180px;
}
.red-ui-editor .form-horizontal .help-block,
.red-ui-editor-dialog .form-horizontal .help-block {
	margin-bottom: 0;
}
.red-ui-editor .form-horizontal input + .help-block,
.red-ui-editor .form-horizontal select + .help-block,
.red-ui-editor .form-horizontal textarea + .help-block,
.red-ui-editor .form-horizontal .uneditable-input + .help-block,
.red-ui-editor .form-horizontal .input-prepend + .help-block,
.red-ui-editor .form-horizontal .input-append + .help-block,
.red-ui-editor-dialog .form-horizontal input + .help-block,
.red-ui-editor-dialog .form-horizontal select + .help-block,
.red-ui-editor-dialog .form-horizontal textarea + .help-block,
.red-ui-editor-dialog .form-horizontal .uneditable-input + .help-block,
.red-ui-editor-dialog .form-horizontal .input-prepend + .help-block,
.red-ui-editor-dialog .form-horizontal .input-append + .help-block {
	margin-top: 10px;
}
.red-ui-editor .form-horizontal .form-actions,
.red-ui-editor-dialog .form-horizontal .form-actions {
	padding-left: 180px;
}
.red-ui-editor .form-row div[contenteditable='true'],
.red-ui-editor-dialog .form-row div[contenteditable='true'] {
	white-space: nowrap;
	overflow: hidden;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.ui-widget {
	font-size: 14px !important;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.ui-widget input,
.ui-widget div[contenteditable='true'],
.ui-widget select,
.ui-widget textarea,
.ui-widget button {
	font-size: 14px !important;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
}

.ui-widget input,
.ui-widget div[contenteditable='true'] {
	box-shadow: none;
}

.ui-widget-header {
	color: #444;
}

/* jQuery Theme overrides */
.ui-tabs .ui-tabs-panel {
	padding: 0px;
}

.ui-autocomplete {
	max-height: 250px;
	overflow-x: hidden;
	overflow-y: scroll;
}

.ui-dialog {
	border-radius: 1px;
	background: #fff;
	padding: 0;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}

.ui-dialog .ui-dialog-content {
	padding: 25px 25px 10px 25px;
}

.ui-dialog .ui-dialog-title {
	width: auto;
}

.ui-dialog .ui-dialog-titlebar {
	padding: 10px;
	background: #f3f3f3;
	border: none;
	border-bottom: 1px solid #bbbbbb;
	border-radius: 0;
}

.ui-dialog .ui-dialog-buttonpane.ui-widget-content {
	background: #f7f7f7;
}

.ui-corner-all {
	border-radius: 1px;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
	background: #f3f3f3;
}

.ui-dialog-no-close .ui-dialog-titlebar-close {
	display: none;
}

.ui-dialog-buttonset {
	text-align: right;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
	float: none;
}

.ui-dialog-buttonset button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 14px;
	padding: 6px 14px;
	margin-right: 8px;
	border-radius: 2px;
	color: #888;
	background: #fff;
}
.ui-dialog-buttonset button.disabled,
.ui-dialog-buttonset button:disabled {
	cursor: default;
	color: #bbb !important;
}
.ui-dialog-buttonset button:hover,
.ui-dialog-buttonset button:focus {
	text-decoration: none;
}
.ui-dialog-buttonset button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
.ui-dialog-buttonset button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
.ui-dialog-buttonset button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
.ui-dialog-buttonset button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group .ui-dialog-buttonset button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group .ui-dialog-buttonset button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical .ui-dialog-buttonset button {
	display: block;
	min-width: 22px;
}
.button-group-vertical .ui-dialog-buttonset button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical .ui-dialog-buttonset button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group .ui-dialog-buttonset button:focus {
	position: relative;
}
.button-row .ui-dialog-buttonset button:not(:first-child) {
	margin-left: 15px;
}
.ui-dialog-buttonset button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
.ui-dialog-buttonset button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.ui-dialog-buttonset button.primary.disabled,
.ui-dialog-buttonset button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
.ui-dialog-buttonset
	button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.ui-dialog-buttonset button.secondary {
	background: none;
}
.ui-dialog-buttonset button.secondary:not(:hover) {
	border-color: transparent;
}
.ui-dialog-buttonset button.leftButton {
	float: left;
	margin-top: 7px;
}
.ui-dialog-buttonset button:not(.leftButton):not(:last-child) {
	margin-right: 16px;
}
.ui-dialog-buttonset button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.ui-dialog-buttonset button.primary:not(.disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.ui-dialog-buttonset button.primary.disabled {
	border-color: #ccc;
	color: #bbb !important;
	background: #fff;
}
.ui-dialog-buttonset button.disabled {
	background: none;
}
.ui-dialog-buttonset button.disabled {
	background: none;
}
.ui-dialog-buttonset button.disabled:focus {
	outline: none;
}
.ui-dialog-buttonset button .ui-button-text {
	padding: 0;
}

.ui-dialog .ui-dialog-buttonpane {
	padding: 0.3em 1em 0.5em 1em;
}

.ui-spinner {
	border-radius: 4px;
	padding: 0;
	border: 1px solid #ccc;
}

.ui-spinner input {
	background: #fff;
	margin: 0 17px 0 0;
	padding: 6px;
	border: none;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}
.ui-spinner input:focus {
	outline: none;
}

.ui-widget-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(160, 160, 160, 0.5);
	z-index: 5;
	z-index: 100;
	opacity: 1;
}

.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default,
.ui-button,
html .ui-button.ui-state-disabled:hover,
html .ui-button.ui-state-disabled:active {
	border: 1px solid #dddddd;
	background: #efefef;
}

.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus,
.ui-button:hover,
.ui-button:focus {
	border: 1px solid #dddddd;
	background: #e6e6e6;
	color: #666;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
	border: 1px solid #dddddd;
	background: #efefef;
	font-weight: normal;
	color: #666;
}

.ui-state-active .ui-icon,
.ui-button:active .ui-icon {
	background-image: url(../../assets/red/node-red-256.png);
}

.red-ui-editor .ace_read-only .ace_scroller,
.red-ui-editor-dialog .ace_read-only .ace_scroller {
	background: #f9f9f9;
	color: #666;
}
.red-ui-editor .ace_read-only .ace_cursor,
.red-ui-editor-dialog .ace_read-only .ace_cursor {
	color: transparent !important;
}
.red-ui-editor .ace_gutter,
.red-ui-editor-dialog .ace_gutter {
	background: #f6f6f6;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.red-ui-editor .ace_scroller,
.red-ui-editor-dialog .ace_scroller {
	background: #fff;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	color: #4d4d4c;
}
.red-ui-editor .ace_marker-layer .ace_active-line,
.red-ui-editor-dialog .ace_marker-layer .ace_active-line {
	background: #efefef;
}
.red-ui-editor .ace_marker-layer .ace_selection,
.red-ui-editor-dialog .ace_marker-layer .ace_selection {
	background: #d6d6d6;
	border-radius: 1px;
}
.red-ui-editor .ace_gutter-cell,
.red-ui-editor-dialog .ace_gutter-cell {
	color: #4d4d4c;
}
.red-ui-editor .ace_gutter-active-line,
.red-ui-editor-dialog .ace_gutter-active-line {
	background: #dcdcdc;
}
.red-ui-editor .ace_tooltip,
.red-ui-editor-dialog .ace_tooltip {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	line-height: 1.4em;
	max-width: 400px;
	white-space: normal;
	background-image: none;
	background: #333;
	color: #eee;
	border-radius: 4px;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	border-color: #333;
}
.red-ui-editor .ace_content,
.red-ui-editor-dialog .ace_content {
	line-height: 1;
}
.red-ui-editor textarea.ace_text-input,
.red-ui-editor-dialog textarea.ace_text-input {
	overflow: hidden;
	padding: 0px 1px !important;
}
.red-ui-editor #red-ui-event-log-editor .ace_scroller,
.red-ui-editor-dialog #red-ui-event-log-editor .ace_scroller {
	background: #444;
	color: #dd9;
}
.red-ui-editor #red-ui-event-log-editor .ace_marker-layer .ace_active-line,
.red-ui-editor-dialog
	#red-ui-event-log-editor
	.ace_marker-layer
	.ace_active-line {
	background: #333;
}
.red-ui-editor #red-ui-event-log-editor .ace_marker-layer .ace_selection,
.red-ui-editor-dialog
	#red-ui-event-log-editor
	.ace_marker-layer
	.ace_selection {
	background: #999;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-menu-dropdown {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size: 14px;
	position: absolute;
	top: 100%;
	width: 200px;
	left: 0;
	z-index: 1000;
	display: none;
	float: left;
	margin: 2px 0 0;
	margin-left: 0px !important;
	padding: 5px 0;
	list-style: none;
	background: #f3f3f3;
	border: 1px solid #dddddd;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.red-ui-menu-dropdown.pull-right {
	right: 0;
	left: auto;
}
.red-ui-menu-dropdown .red-ui-menu-divider {
	height: 1px;
	margin: 9px 1px;
	overflow: hidden;
	background-color: #dddddd;
}
.red-ui-menu-dropdown > li > a,
.red-ui-menu-dropdown > li > a:focus {
	display: block;
	padding: 4px 0 4px 32px;
	clear: both;
	font-weight: normal;
	line-height: 20px;
	color: #555;
	white-space: normal !important;
	outline: none;
}
.red-ui-menu-dropdown > .active > a,
.red-ui-menu-dropdown > .active > a:hover,
.red-ui-menu-dropdown > .active > a:focus {
	color: #666;
	text-decoration: none;
	background-color: #e6e6e6;
	outline: 0;
}
.red-ui-menu-dropdown > .disabled > a,
.red-ui-menu-dropdown > .disabled > a:hover,
.red-ui-menu-dropdown > .disabled > a:focus {
	color: #bbb;
}
.red-ui-menu-dropdown > .disabled > a:hover,
.red-ui-menu-dropdown > .disabled > a:focus {
	text-decoration: none;
	cursor: default;
	background-color: transparent;
	background-image: none;
}
.red-ui-menu-dropdown a img {
	max-width: 14px;
}
.red-ui-menu-dropdown a .fa {
	width: 20px;
	margin-left: -25px;
	margin-top: 3px;
	text-align: center;
}
.red-ui-menu-dropdown a .fa-check-square {
	display: none;
}
.red-ui-menu-dropdown a .fa-square {
	display: inline-block;
}
.red-ui-menu-dropdown a.active .fa-check-square {
	display: inline-block;
}
.red-ui-menu-dropdown a.active .fa-square {
	display: none;
}

.pull-right > .red-ui-menu-dropdown {
	right: 0;
	left: auto;
}

.red-ui-menu-dropdown > li > a:hover,
.red-ui-menu-dropdown > li > a:focus,
.red-ui-menu-dropdown-submenu:hover > a,
.red-ui-menu-dropdown-submenu:focus > a {
	color: #666;
	text-decoration: none;
	background-color: #e6e6e6;
}

.red-ui-menu-dropdown-submenu {
	position: relative;
}
.red-ui-menu-dropdown-submenu > .red-ui-menu-dropdown {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
}
.red-ui-menu-dropdown-submenu:hover > .red-ui-menu-dropdown {
	display: block;
}
.red-ui-menu-dropdown-submenu > a:after {
	display: block;
	float: right;
	width: 0;
	height: 0;
	margin-top: 5px;
	margin-right: -10px;
	border-color: transparent;
	border-left-color: #aaa;
	border-style: solid;
	border-width: 5px 0 5px 5px;
	content: ' ';
}
.red-ui-menu-dropdown-submenu.pull-left {
	float: none;
}
.red-ui-menu-dropdown-submenu.pull-left > .red-ui-menu-dropdown {
	left: -100%;
	margin-left: 10px;
}

.red-ui-menu-dropdown-submenu > a:after {
	display: none;
}

.red-ui-menu-dropdown-submenu > a:before {
	display: block;
	float: left;
	width: 0;
	height: 0;
	margin-top: 5px;
	margin-left: -30px;
	/* Caret Arrow */
	border-color: transparent;
	border-right-color: #aaa;
	border-style: solid;
	border-width: 5px 5px 5px 0;
	content: ' ';
}

.red-ui-menu-dropdown-submenu.disabled > a:before {
	border-right-color: #aaa;
}

ul.red-ui-menu:not(.red-ui-menu-dropdown) {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size: 12px;
	list-style-type: none;
	padding: 0;
	margin: 0;
}
ul.red-ui-menu:not(.red-ui-menu-dropdown) li a {
	display: block;
	padding: 4px 8px 4px 16px;
	clear: both;
	font-weight: normal;
	line-height: 20px;
	color: #555;
	white-space: nowrap;
	text-decoration: none;
}
ul.red-ui-menu:not(.red-ui-menu-dropdown) li a:hover,
ul.red-ui-menu:not(.red-ui-menu-dropdown) li a:focus {
	color: #666;
	text-decoration: none;
	background-color: #e6e6e6;
	border: none;
	outline: none;
}
ul.red-ui-menu:not(.red-ui-menu-dropdown).red-ui-menu-compact {
	font-size: 12px;
}
ul.red-ui-menu:not(.red-ui-menu-dropdown).red-ui-menu-compact li a {
	line-height: 16px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#red-ui-header {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 40px;
	background: #000;
	box-sizing: border-box;
	padding: 0px 0px 0px 20px;
	color: #c7c7c7;
	font-size: 14px;
	/* Deploy menu customisations */
	/* User menu customisations */
}
#red-ui-header span.red-ui-header-logo {
	float: left;
	margin-top: 5px;
	font-size: 30px;
	line-height: 30px;
	text-decoration: none;
	white-space: nowrap;
}
#red-ui-header span.red-ui-header-logo span {
	vertical-align: middle;
	font-size: 16px !important;
}
#red-ui-header span.red-ui-header-logo span:not(:first-child) {
	margin-left: 5px;
}
#red-ui-header span.red-ui-header-logo img {
	height: 18px;
}
#red-ui-header span.red-ui-header-logo a {
	color: inherit;
}
#red-ui-header span.red-ui-header-logo a:hover {
	text-decoration: none;
}
#red-ui-header .red-ui-header-toolbar {
	padding: 0;
	margin: 0;
	list-style: none;
	float: right;
	display: flex;
}
#red-ui-header .red-ui-header-toolbar > li {
	display: inline-block;
	padding: 0;
	margin: 0;
	position: relative;
}
#red-ui-header .button {
	min-width: 20px;
	text-align: center;
	line-height: 40px;
	display: inline-block;
	font-size: 20px;
	padding: 0px 12px;
	text-decoration: none;
	color: #c7c7c7;
	margin: auto 5px;
	vertical-align: middle;
	border-left: 2px solid #000;
	border-right: 2px solid #000;
}
#red-ui-header .button:hover {
	border-color: #323232;
}
#red-ui-header .button:active,
#red-ui-header .button.active {
	background: #121212;
}
#red-ui-header .button:focus {
	outline: none;
}
#red-ui-header .button-group {
	display: inline-block;
	margin: auto 15px;
	vertical-align: middle;
	clear: both;
}
#red-ui-header .button-group > a {
	display: inline-block;
	position: relative;
	float: left;
	line-height: 22px;
	font-size: 14px;
	text-decoration: none;
	padding: 4px 8px;
	margin: 0;
}
#red-ui-header .red-ui-deploy-button {
	background: hsl(354, 79%, 31%);
	color: #eee;
}
#red-ui-header .red-ui-deploy-button:hover {
	background: #6e0a1e;
}
#red-ui-header .red-ui-deploy-button:focus {
	outline: none;
}
#red-ui-header .red-ui-deploy-button:active {
	background: #4c0a17;
	color: #ccc;
}
#red-ui-header .red-ui-deploy-button-spinner {
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	text-align: center;
}
#red-ui-header .red-ui-deploy-button-spinner img {
	opacity: 0.8;
	height: 100%;
}
#red-ui-header #red-ui-header-button-deploy {
	padding: 4px 12px;
}
#red-ui-header #red-ui-header-button-deploy.disabled {
	cursor: default;
	background: #444;
	color: #999;
}
#red-ui-header
	#red-ui-header-button-deploy.disabled
	.red-ui-deploy-button-content
	> img {
	opacity: 0.3;
}
#red-ui-header
	#red-ui-header-button-deploy.disabled
	+ #red-ui-header-button-deploy-options {
	background: #444;
	color: #ccc;
}
#red-ui-header
	#red-ui-header-button-deploy.disabled
	+ #red-ui-header-button-deploy-options:hover {
	background: #555;
}
#red-ui-header
	#red-ui-header-button-deploy.disabled
	+ #red-ui-header-button-deploy-options:active {
	background: #444;
}
#red-ui-header
	#red-ui-header-button-deploy
	.red-ui-deploy-button-content
	> img {
	margin-right: 8px;
}
#red-ui-header
	.red-ui-deploy-button-group.open
	#red-ui-header-button-deploy-options {
	background: #121212 !important;
}
#red-ui-header li.open .button {
	background: #121212;
	border-color: #121212;
}
#red-ui-header ul.red-ui-menu-dropdown {
	background: #121212;
	border: 1px solid #121212;
	width: 250px !important;
	margin-top: 0;
}
#red-ui-header ul.red-ui-menu-dropdown li a {
	color: #c7c7c7;
	padding: 3px 40px;
}
#red-ui-header ul.red-ui-menu-dropdown li a img {
	max-width: 100%;
	margin-right: 10px;
	padding: 4px;
	border: 3px solid transparent;
}
#red-ui-header ul.red-ui-menu-dropdown li a.active img {
	border: 3px solid #777677;
}
#red-ui-header ul.red-ui-menu-dropdown li a span.red-ui-menu-label-container {
	width: 180px;
	vertical-align: top;
	display: inline-block;
	text-indent: 0px;
}
#red-ui-header ul.red-ui-menu-dropdown li a span.red-ui-menu-label {
	font-size: 14px;
	display: inline-block;
	text-indent: 0px;
}
#red-ui-header ul.red-ui-menu-dropdown li a span.red-ui-menu-sublabel {
	color: #aeaeae;
	font-size: 13px;
	display: inline-block;
	text-indent: 0px;
}
#red-ui-header ul.red-ui-menu-dropdown > li > a:hover,
#red-ui-header ul.red-ui-menu-dropdown > li > a:focus,
#red-ui-header ul.red-ui-menu-dropdown > li:hover > a,
#red-ui-header ul.red-ui-menu-dropdown > li:focus > a {
	background: #323232 !important;
}
#red-ui-header ul.red-ui-menu-dropdown li.red-ui-menu-divider {
	background: #464646;
}
#red-ui-header ul.red-ui-menu-dropdown li.disabled a {
	color: #666;
}
#red-ui-header ul.red-ui-menu-dropdown > li.disabled > a:hover,
#red-ui-header ul.red-ui-menu-dropdown > li.disabled > a:focus {
	background: none !important;
}
#red-ui-header .red-ui-menu-dropdown-submenu > a:before {
	border-right-color: #c7c7c7;
}
#red-ui-header ul#red-ui-header-button-deploy-options-submenu {
	width: 300px !important;
}
#red-ui-header ul#red-ui-header-button-deploy-options-submenu li a {
	padding: 10px 30px;
	color: #fff;
}
#red-ui-header
	ul#red-ui-header-button-deploy-options-submenu
	li
	a
	span.red-ui-menu-label {
	font-size: 16px;
	display: inline-block;
	text-indent: 0px;
}
#red-ui-header ul#red-ui-header-button-deploy-options-submenu li a > i.fa {
	display: none;
}
#red-ui-header #usermenu-item-username > .red-ui-menu-label {
	color: #fff;
}
#red-ui-header #red-ui-header-button-user .user-profile {
	background-position: center center;
	background-repeat: no-repeat;
	background-size: contain;
	display: inline-block;
	width: 40px;
	height: 35px;
	vertical-align: middle;
}

@media only screen and (max-width: 450px) {
	span.red-ui-header-logo > span {
		display: none;
	}
}
/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-palette {
	position: absolute;
	top: 0px;
	bottom: 0px;
	left: 0px;
	background: #f3f3f3;
	width: 180px;
	text-align: center;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	border: 1px solid #bbbbbb;
	box-sizing: border-box;
	transition: width 0.2s ease-in-out;
}

.red-ui-palette-closed #red-ui-palette {
	width: 8px;
}
.red-ui-palette-closed #red-ui-palette .red-ui-component-footer {
	display: none;
}
.red-ui-palette-closed #red-ui-palette-search {
	display: none;
}
.red-ui-palette-closed #red-ui-palette-container {
	display: none;
}

.red-ui-palette-scroll {
	position: absolute;
	top: 35px;
	right: 0;
	bottom: 25px;
	left: 0;
	padding: 0;
	overflow-y: auto;
	box-sizing: border-box;
}

#red-ui-palette > .red-ui-palette-spinner {
	padding-top: 80px;
}

.red-ui-palette-search {
	position: relative;
	overflow: hidden;
	background: #fff;
	text-align: center;
	height: 35px;
	padding: 3px;
	border-bottom: 1px solid #bbbbbb;
	box-sizing: border-box;
}

.red-ui-palette-category {
	border-bottom: 1px solid #dddddd;
}

.red-ui-palette-content {
	background: #fff;
	padding: 3px;
}

.red-ui-palette-header {
	position: relative;
	background: #f3f3f3;
	color: #444;
	cursor: pointer;
	text-align: left;
	padding: 9px;
	font-weight: bold;
	padding-left: 30px;
	overflow: hidden;
	user-select: none;
}
.red-ui-palette-header:hover {
	background: #f3f3f3 !important;
}

.red-ui-palette-header > i {
	position: absolute;
	left: 11px;
	top: 12px;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-o-transform: rotate(-90deg);
}

.red-ui-palette-header i.expanded {
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
}

.red-ui-palette-header span {
	clear: both;
}

.red-ui-palette-label {
	color: #333;
	font-size: 13px;
	margin: 4px 0 4px 32px;
	line-height: 20px;
	overflow: hidden;
	text-align: center;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.red-ui-palette-label-right {
	margin: 4px 32px 4px 0;
}

.red-ui-palette-node {
	cursor: move;
	background: #fff;
	margin: 10px auto;
	height: 25px;
	border-radius: 5px;
	border: 1px solid #999;
	background-position: 5% 50%;
	background-repeat: no-repeat;
	width: 120px;
	background-size: contain;
	position: relative;
}
.red-ui-palette-node:not(.red-ui-palette-node-config):not(.red-ui-palette-node-small):first-child {
	margin-top: 15px;
}
.red-ui-palette-node:not(.red-ui-palette-node-config):not(.red-ui-palette-node-small):last-child {
	margin-bottom: 15px;
}

.red-ui-palette-node:hover {
	border-color: transparent;
	box-shadow: 0 0 0 2px #ff7f0e;
}

.red-ui-palette-port {
	position: absolute;
	top: 8px;
	left: -5px;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	background: #d9d9d9;
	border-radius: 3px;
	width: 10px;
	height: 10px;
	border: 1px solid #999;
}

.red-ui-palette-port-output {
	left: auto;
	right: -6px;
}

.red-ui-palette-node:hover .red-ui-palette-port {
	background-color: #eee;
}

.red-ui-palette-icon-container {
	position: absolute;
	text-align: center;
	top: 0;
	bottom: 0;
	left: 0;
	width: 30px;
	border-right: 1px solid rgba(0, 0, 0, 0.05);
	background-color: rgba(0, 0, 0, 0.05);
}

.red-ui-palette-icon-container-right {
	left: auto;
	right: 0;
	border-right: none;
	border-left: 1px solid rgba(0, 0, 0, 0.05);
}

.red-ui-palette-icon {
	display: inline-block;
	width: 20px;
	height: 100%;
	background-position: 50% 50%;
	background-size: contain;
	background-repeat: no-repeat;
}

.red-ui-search-result-node.red-ui-palette-icon-flow,
.red-ui-search-result-node.red-ui-palette-icon-group,
.red-ui-search-result-node.red-ui-palette-icon-selection {
	background: none;
	border-color: transparent;
}
.red-ui-search-result-node.red-ui-palette-icon-flow
	.red-ui-palette-icon-container,
.red-ui-search-result-node.red-ui-palette-icon-group
	.red-ui-palette-icon-container,
.red-ui-search-result-node.red-ui-palette-icon-selection
	.red-ui-palette-icon-container {
	background: none;
}
.red-ui-search-result-node.red-ui-palette-icon-flow .red-ui-palette-icon-fa,
.red-ui-search-result-node.red-ui-palette-icon-group .red-ui-palette-icon-fa,
.red-ui-search-result-node.red-ui-palette-icon-selection
	.red-ui-palette-icon-fa {
	color: #888;
	font-size: 18px;
}

.red-ui-palette-icon-fa {
	color: white;
	position: absolute;
	top: calc(50% - 7px);
	left: 3px;
}

.red-ui-palette-node-small {
	display: inline-block;
	position: relative;
	width: 18px;
	height: 15px;
	margin: 3px 0px;
	vertical-align: middle;
	cursor: default;
}
.red-ui-palette-node-small .red-ui-palette-icon-container {
	width: 18px;
	border-right: none;
}
.red-ui-palette-node-small .red-ui-palette-icon {
	margin-left: -1px;
	width: 15px;
}
.red-ui-palette-node-small .red-ui-palette-icon-fa {
	top: 2px;
	left: 1px;
}

.red-ui-node-list-item {
	display: inline-block;
	padding: 0;
	font-size: 13px;
	border: none;
}

.red-ui-node-icon {
	display: inline-block;
	width: 24px;
	height: 20px;
	line-height: 20px;
	margin-top: 1px;
	border-radius: 3px;
	border: 1px solid #999;
	background-position: 5% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;
	background-color: rgba(0, 0, 0, 0.05);
	text-align: center;
}
.red-ui-node-icon .red-ui-palette-icon {
	width: 20px;
}
.red-ui-node-icon .red-ui-palette-icon-fa {
	font-size: 14px;
	position: relative;
	top: -1px;
	left: 0px;
}
.red-ui-node-icon.red-ui-palette-icon-flow,
.red-ui-node-icon.red-ui-palette-icon-group,
.red-ui-node-icon.red-ui-palette-icon-selection {
	background: none;
	border-color: transparent;
}
.red-ui-node-icon.red-ui-palette-icon-flow .red-ui-palette-icon-container,
.red-ui-node-icon.red-ui-palette-icon-group .red-ui-palette-icon-container,
.red-ui-node-icon.red-ui-palette-icon-selection .red-ui-palette-icon-container {
	background: none;
}
.red-ui-node-icon.red-ui-palette-icon-flow .red-ui-palette-icon-fa,
.red-ui-node-icon.red-ui-palette-icon-group .red-ui-palette-icon-fa,
.red-ui-node-icon.red-ui-palette-icon-selection .red-ui-palette-icon-fa {
	color: #888;
	font-size: 16px;
}

.red-ui-node-icon-container {
	display: inline-flex;
	align-items: center;
}

.red-ui-node-icon-container.red-ui-node-icon-small .red-ui-node-icon {
	width: 18px;
	height: 15px;
	line-height: 15px;
}
.red-ui-node-icon-container.red-ui-node-icon-small
	.red-ui-node-icon
	.red-ui-palette-icon {
	width: 15px;
}
.red-ui-node-icon-container.red-ui-node-icon-small
	.red-ui-node-icon
	.red-ui-palette-icon-fa {
	font-size: 11px;
}
.red-ui-node-icon-container.red-ui-node-icon-small
	.red-ui-node-icon
	.red-ui-icons-flow {
	width: 14px;
}
.red-ui-node-icon-container.red-ui-node-icon-small
	.red-ui-node-icon.red-ui-palette-icon-flow {
	margin-top: -2px;
}
.red-ui-node-icon-container.red-ui-node-icon-small
	.red-ui-node-icon.red-ui-palette-icon-group
	.red-ui-palette-icon-fa {
	font-size: 14px;
}
.red-ui-node-label {
	white-space: nowrap;
	margin-left: 4px;
	color: #888;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-sidebar {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	width: 315px;
	background: #f3f3f3;
	box-sizing: border-box;
	z-index: 10;
	border: 1px solid #bbbbbb;
	box-sizing: border-box;
}

#red-ui-sidebar.closing {
	border-style: dashed;
}

#red-ui-sidebar-content {
	position: absolute;
	background: #fff;
	top: 35px;
	right: 0;
	bottom: 25px;
	left: 0px;
	overflow-y: auto;
}

#red-ui-sidebar-separator {
	position: absolute;
	top: 5px;
	right: 315px;
	bottom: 10px;
	width: 7px;
	background: #f3f3f3 url(../../assets/red/grip.png) no-repeat 50% 50%;
	cursor: col-resize;
}

.red-ui-sidebar-closed > #red-ui-sidebar {
	display: none;
}

.red-ui-sidebar-closed > #red-ui-sidebar-separator {
	right: 0px !important;
}

.red-ui-sidebar-closed > #red-ui-workspace {
	right: 7px !important;
}

.red-ui-sidebar-closed > #red-ui-editor-stack {
	right: 8px !important;
}

#red-ui-sidebar .button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	line-height: 18px;
	font-size: 12px;
	margin-right: 5px;
	padding: 2px 8px;
}
#red-ui-sidebar .button.disabled,
#red-ui-sidebar .button:disabled {
	cursor: default;
	color: #bbb !important;
}
#red-ui-sidebar .button:hover,
#red-ui-sidebar .button:focus {
	text-decoration: none;
}
#red-ui-sidebar .button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
#red-ui-sidebar .button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
#red-ui-sidebar .button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
#red-ui-sidebar .button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group #red-ui-sidebar .button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group #red-ui-sidebar .button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical #red-ui-sidebar .button {
	display: block;
	min-width: 22px;
}
.button-group-vertical #red-ui-sidebar .button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical #red-ui-sidebar .button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group #red-ui-sidebar .button:focus {
	position: relative;
}
.button-row #red-ui-sidebar .button:not(:first-child) {
	margin-left: 15px;
}
#red-ui-sidebar .button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
#red-ui-sidebar .button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
#red-ui-sidebar .button.primary.disabled,
#red-ui-sidebar .button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
#red-ui-sidebar .button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
#red-ui-sidebar .button.secondary {
	background: none;
}
#red-ui-sidebar .button.secondary:not(:hover) {
	border-color: transparent;
}

.sidebar-header,
.red-ui-sidebar-header {
	color: #555;
	text-align: right;
	padding: 8px 10px;
	background: #f3f3f3;
	border-bottom: 1px solid #dddddd;
	white-space: nowrap;
}

/* Deprecated -> red-ui-footer-button */
.sidebar-footer-button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
	width: 19px;
	height: 19px;
	padding: 0;
}
.sidebar-footer-button.disabled,
.sidebar-footer-button:disabled {
	cursor: default;
	color: #bbb !important;
}
.sidebar-footer-button:hover,
.sidebar-footer-button:focus {
	text-decoration: none;
}
.sidebar-footer-button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
.sidebar-footer-button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
.sidebar-footer-button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
.sidebar-footer-button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group .sidebar-footer-button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group .sidebar-footer-button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical .sidebar-footer-button {
	display: block;
	min-width: 22px;
}
.button-group-vertical .sidebar-footer-button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical .sidebar-footer-button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group .sidebar-footer-button:focus {
	position: relative;
}
.button-row .sidebar-footer-button:not(:first-child) {
	margin-left: 15px;
}
.sidebar-footer-button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
.sidebar-footer-button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.sidebar-footer-button.primary.disabled,
.sidebar-footer-button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
.sidebar-footer-button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.sidebar-footer-button.secondary {
	background: none;
}
.sidebar-footer-button.secondary:not(:hover) {
	border-color: transparent;
}
.sidebar-footer-button.text-button {
	width: auto;
	padding: 0 5px;
}

/* Deprecated -> red-ui-footer-button-toggle */
.sidebar-footer-button-toggle {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
	height: 19px;
	width: 19px;
	padding: 0;
}
.sidebar-footer-button-toggle.disabled,
.sidebar-footer-button-toggle:disabled {
	cursor: default;
	color: #bbb !important;
}
.sidebar-footer-button-toggle:hover,
.sidebar-footer-button-toggle:focus {
	text-decoration: none;
}
.sidebar-footer-button-toggle:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
.sidebar-footer-button-toggle:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
.sidebar-footer-button-toggle:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
.sidebar-footer-button-toggle.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group .sidebar-footer-button-toggle:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group .sidebar-footer-button-toggle:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical .sidebar-footer-button-toggle {
	display: block;
	min-width: 22px;
}
.button-group-vertical .sidebar-footer-button-toggle:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical .sidebar-footer-button-toggle:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group .sidebar-footer-button-toggle:focus {
	position: relative;
}
.button-row .sidebar-footer-button-toggle:not(:first-child) {
	margin-left: 15px;
}
.sidebar-footer-button-toggle:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
.sidebar-footer-button-toggle.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.sidebar-footer-button-toggle.primary.disabled,
.sidebar-footer-button-toggle.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
.sidebar-footer-button-toggle.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.sidebar-footer-button-toggle.secondary {
	background: none;
}
.sidebar-footer-button-toggle.secondary:not(:hover) {
	border-color: transparent;
}
.sidebar-footer-button-toggle:not(.single) {
	margin-bottom: 1px;
}
.sidebar-footer-button-toggle:not(.single).selected:not(.disabled):not(:disabled) {
	border-bottom-width: 2px;
	border-bottom-color: #aaa;
	margin-bottom: 0;
	cursor: default;
}
.sidebar-footer-button-toggle:not(.single):not(.selected) {
	margin-top: 1px;
}
.sidebar-footer-button-toggle.text-button {
	width: auto;
	padding: 0 5px;
}

a.sidebar-header-button,
button.sidebar-header-button,
a.red-ui-sidebar-header-button,
button.red-ui-sidebar-header-button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 13px;
	line-height: 13px;
	padding: 5px 8px;
}
a.sidebar-header-button.disabled,
a.sidebar-header-button:disabled,
button.sidebar-header-button.disabled,
button.sidebar-header-button:disabled,
a.red-ui-sidebar-header-button.disabled,
a.red-ui-sidebar-header-button:disabled,
button.red-ui-sidebar-header-button.disabled,
button.red-ui-sidebar-header-button:disabled {
	cursor: default;
	color: #bbb !important;
}
a.sidebar-header-button:hover,
a.sidebar-header-button:focus,
button.sidebar-header-button:hover,
button.sidebar-header-button:focus,
a.red-ui-sidebar-header-button:hover,
a.red-ui-sidebar-header-button:focus,
button.red-ui-sidebar-header-button:hover,
button.red-ui-sidebar-header-button:focus {
	text-decoration: none;
}
a.sidebar-header-button:not(.disabled):not(:disabled):hover,
button.sidebar-header-button:not(.disabled):not(:disabled):hover,
a.red-ui-sidebar-header-button:not(.disabled):not(:disabled):hover,
button.red-ui-sidebar-header-button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.sidebar-header-button:not(.disabled):not(:disabled):focus,
button.sidebar-header-button:not(.disabled):not(:disabled):focus,
a.red-ui-sidebar-header-button:not(.disabled):not(:disabled):focus,
button.red-ui-sidebar-header-button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.sidebar-header-button:not(.disabled):not(:disabled):active,
button.sidebar-header-button:not(.disabled):not(:disabled):active,
a.red-ui-sidebar-header-button:not(.disabled):not(:disabled):active,
button.red-ui-sidebar-header-button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.sidebar-header-button.selected:not(.disabled):not(:disabled),
button.sidebar-header-button.selected:not(.disabled):not(:disabled),
a.red-ui-sidebar-header-button.selected:not(.disabled):not(:disabled),
button.red-ui-sidebar-header-button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.sidebar-header-button:not(:first-child),
.button-group button.sidebar-header-button:not(:first-child),
.button-group a.red-ui-sidebar-header-button:not(:first-child),
.button-group button.red-ui-sidebar-header-button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.sidebar-header-button:not(:last-child),
.button-group button.sidebar-header-button:not(:last-child),
.button-group a.red-ui-sidebar-header-button:not(:last-child),
.button-group button.red-ui-sidebar-header-button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.sidebar-header-button,
.button-group-vertical button.sidebar-header-button,
.button-group-vertical a.red-ui-sidebar-header-button,
.button-group-vertical button.red-ui-sidebar-header-button {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.sidebar-header-button:not(:first-child),
.button-group-vertical button.sidebar-header-button:not(:first-child),
.button-group-vertical a.red-ui-sidebar-header-button:not(:first-child),
.button-group-vertical button.red-ui-sidebar-header-button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.sidebar-header-button:not(:last-child),
.button-group-vertical button.sidebar-header-button:not(:last-child),
.button-group-vertical a.red-ui-sidebar-header-button:not(:last-child),
.button-group-vertical button.red-ui-sidebar-header-button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.sidebar-header-button:focus,
.button-group button.sidebar-header-button:focus,
.button-group a.red-ui-sidebar-header-button:focus,
.button-group button.red-ui-sidebar-header-button:focus {
	position: relative;
}
.button-row a.sidebar-header-button:not(:first-child),
.button-row button.sidebar-header-button:not(:first-child),
.button-row a.red-ui-sidebar-header-button:not(:first-child),
.button-row button.red-ui-sidebar-header-button:not(:first-child) {
	margin-left: 15px;
}
a.sidebar-header-button:focus,
button.sidebar-header-button:focus,
a.red-ui-sidebar-header-button:focus,
button.red-ui-sidebar-header-button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.sidebar-header-button.primary,
button.sidebar-header-button.primary,
a.red-ui-sidebar-header-button.primary,
button.red-ui-sidebar-header-button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.sidebar-header-button.primary.disabled,
a.sidebar-header-button.primary.ui-state-disabled,
button.sidebar-header-button.primary.disabled,
button.sidebar-header-button.primary.ui-state-disabled,
a.red-ui-sidebar-header-button.primary.disabled,
a.red-ui-sidebar-header-button.primary.ui-state-disabled,
button.red-ui-sidebar-header-button.primary.disabled,
button.red-ui-sidebar-header-button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.sidebar-header-button.primary:not(.disabled):not(.ui-button-disabled):hover,
button.sidebar-header-button.primary:not(.disabled):not(.ui-button-disabled):hover,
a.red-ui-sidebar-header-button.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-sidebar-header-button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.sidebar-header-button.secondary,
button.sidebar-header-button.secondary,
a.red-ui-sidebar-header-button.secondary,
button.red-ui-sidebar-header-button.secondary {
	background: none;
}
a.sidebar-header-button.secondary:not(:hover),
button.sidebar-header-button.secondary:not(:hover),
a.red-ui-sidebar-header-button.secondary:not(:hover),
button.red-ui-sidebar-header-button.secondary:not(:hover) {
	border-color: transparent;
}
a.sidebar-header-button.toggle,
button.sidebar-header-button.toggle,
a.red-ui-sidebar-header-button.toggle,
button.red-ui-sidebar-header-button.toggle {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
}
a.sidebar-header-button.toggle.disabled,
a.sidebar-header-button.toggle:disabled,
button.sidebar-header-button.toggle.disabled,
button.sidebar-header-button.toggle:disabled,
a.red-ui-sidebar-header-button.toggle.disabled,
a.red-ui-sidebar-header-button.toggle:disabled,
button.red-ui-sidebar-header-button.toggle.disabled,
button.red-ui-sidebar-header-button.toggle:disabled {
	cursor: default;
	color: #bbb !important;
}
a.sidebar-header-button.toggle:hover,
a.sidebar-header-button.toggle:focus,
button.sidebar-header-button.toggle:hover,
button.sidebar-header-button.toggle:focus,
a.red-ui-sidebar-header-button.toggle:hover,
a.red-ui-sidebar-header-button.toggle:focus,
button.red-ui-sidebar-header-button.toggle:hover,
button.red-ui-sidebar-header-button.toggle:focus {
	text-decoration: none;
}
a.sidebar-header-button.toggle:not(.disabled):not(:disabled):hover,
button.sidebar-header-button.toggle:not(.disabled):not(:disabled):hover,
a.red-ui-sidebar-header-button.toggle:not(.disabled):not(:disabled):hover,
button.red-ui-sidebar-header-button.toggle:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.sidebar-header-button.toggle:not(.disabled):not(:disabled):focus,
button.sidebar-header-button.toggle:not(.disabled):not(:disabled):focus,
a.red-ui-sidebar-header-button.toggle:not(.disabled):not(:disabled):focus,
button.red-ui-sidebar-header-button.toggle:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.sidebar-header-button.toggle:not(.disabled):not(:disabled):active,
button.sidebar-header-button.toggle:not(.disabled):not(:disabled):active,
a.red-ui-sidebar-header-button.toggle:not(.disabled):not(:disabled):active,
button.red-ui-sidebar-header-button.toggle:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.sidebar-header-button.toggle.selected:not(.disabled):not(:disabled),
button.sidebar-header-button.toggle.selected:not(.disabled):not(:disabled),
a.red-ui-sidebar-header-button.toggle.selected:not(.disabled):not(:disabled),
button.red-ui-sidebar-header-button.toggle.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.sidebar-header-button.toggle:not(:first-child),
.button-group button.sidebar-header-button.toggle:not(:first-child),
.button-group a.red-ui-sidebar-header-button.toggle:not(:first-child),
.button-group button.red-ui-sidebar-header-button.toggle:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.sidebar-header-button.toggle:not(:last-child),
.button-group button.sidebar-header-button.toggle:not(:last-child),
.button-group a.red-ui-sidebar-header-button.toggle:not(:last-child),
.button-group button.red-ui-sidebar-header-button.toggle:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.sidebar-header-button.toggle,
.button-group-vertical button.sidebar-header-button.toggle,
.button-group-vertical a.red-ui-sidebar-header-button.toggle,
.button-group-vertical button.red-ui-sidebar-header-button.toggle {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.sidebar-header-button.toggle:not(:first-child),
.button-group-vertical button.sidebar-header-button.toggle:not(:first-child),
.button-group-vertical a.red-ui-sidebar-header-button.toggle:not(:first-child),
.button-group-vertical
	button.red-ui-sidebar-header-button.toggle:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.sidebar-header-button.toggle:not(:last-child),
.button-group-vertical button.sidebar-header-button.toggle:not(:last-child),
.button-group-vertical a.red-ui-sidebar-header-button.toggle:not(:last-child),
.button-group-vertical
	button.red-ui-sidebar-header-button.toggle:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.sidebar-header-button.toggle:focus,
.button-group button.sidebar-header-button.toggle:focus,
.button-group a.red-ui-sidebar-header-button.toggle:focus,
.button-group button.red-ui-sidebar-header-button.toggle:focus {
	position: relative;
}
.button-row a.sidebar-header-button.toggle:not(:first-child),
.button-row button.sidebar-header-button.toggle:not(:first-child),
.button-row a.red-ui-sidebar-header-button.toggle:not(:first-child),
.button-row button.red-ui-sidebar-header-button.toggle:not(:first-child) {
	margin-left: 15px;
}
a.sidebar-header-button.toggle:focus,
button.sidebar-header-button.toggle:focus,
a.red-ui-sidebar-header-button.toggle:focus,
button.red-ui-sidebar-header-button.toggle:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.sidebar-header-button.toggle.primary,
button.sidebar-header-button.toggle.primary,
a.red-ui-sidebar-header-button.toggle.primary,
button.red-ui-sidebar-header-button.toggle.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.sidebar-header-button.toggle.primary.disabled,
a.sidebar-header-button.toggle.primary.ui-state-disabled,
button.sidebar-header-button.toggle.primary.disabled,
button.sidebar-header-button.toggle.primary.ui-state-disabled,
a.red-ui-sidebar-header-button.toggle.primary.disabled,
a.red-ui-sidebar-header-button.toggle.primary.ui-state-disabled,
button.red-ui-sidebar-header-button.toggle.primary.disabled,
button.red-ui-sidebar-header-button.toggle.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.sidebar-header-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
button.sidebar-header-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
a.red-ui-sidebar-header-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-sidebar-header-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.sidebar-header-button.toggle.secondary,
button.sidebar-header-button.toggle.secondary,
a.red-ui-sidebar-header-button.toggle.secondary,
button.red-ui-sidebar-header-button.toggle.secondary {
	background: none;
}
a.sidebar-header-button.toggle.secondary:not(:hover),
button.sidebar-header-button.toggle.secondary:not(:hover),
a.red-ui-sidebar-header-button.toggle.secondary:not(:hover),
button.red-ui-sidebar-header-button.toggle.secondary:not(:hover) {
	border-color: transparent;
}
a.sidebar-header-button.toggle:not(.single),
button.sidebar-header-button.toggle:not(.single),
a.red-ui-sidebar-header-button.toggle:not(.single),
button.red-ui-sidebar-header-button.toggle:not(.single) {
	margin-bottom: 1px;
}
a.sidebar-header-button.toggle:not(.single).selected:not(.disabled):not(:disabled),
button.sidebar-header-button.toggle:not(.single).selected:not(.disabled):not(:disabled),
a.red-ui-sidebar-header-button.toggle:not(.single).selected:not(.disabled):not(:disabled),
button.red-ui-sidebar-header-button.toggle:not(.single).selected:not(.disabled):not(:disabled) {
	border-bottom-width: 2px;
	border-bottom-color: #aaa;
	margin-bottom: 0;
	cursor: default;
}
a.sidebar-header-button.toggle:not(.single):not(.selected),
button.sidebar-header-button.toggle:not(.single):not(.selected),
a.red-ui-sidebar-header-button.toggle:not(.single):not(.selected),
button.red-ui-sidebar-header-button.toggle:not(.single):not(.selected) {
	margin-top: 1px;
}

a.sidebar-header-button-toggle,
button.sidebar-header-button-toggle,
a.red-ui-sidebar-header-button-toggle,
button.red-ui-sidebar-header-button-toggle {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 13px;
	line-height: 13px;
	padding: 5px 8px;
}
a.sidebar-header-button-toggle.disabled,
a.sidebar-header-button-toggle:disabled,
button.sidebar-header-button-toggle.disabled,
button.sidebar-header-button-toggle:disabled,
a.red-ui-sidebar-header-button-toggle.disabled,
a.red-ui-sidebar-header-button-toggle:disabled,
button.red-ui-sidebar-header-button-toggle.disabled,
button.red-ui-sidebar-header-button-toggle:disabled {
	cursor: default;
	color: #bbb !important;
}
a.sidebar-header-button-toggle:hover,
a.sidebar-header-button-toggle:focus,
button.sidebar-header-button-toggle:hover,
button.sidebar-header-button-toggle:focus,
a.red-ui-sidebar-header-button-toggle:hover,
a.red-ui-sidebar-header-button-toggle:focus,
button.red-ui-sidebar-header-button-toggle:hover,
button.red-ui-sidebar-header-button-toggle:focus {
	text-decoration: none;
}
a.sidebar-header-button-toggle:not(.disabled):not(:disabled):hover,
button.sidebar-header-button-toggle:not(.disabled):not(:disabled):hover,
a.red-ui-sidebar-header-button-toggle:not(.disabled):not(:disabled):hover,
button.red-ui-sidebar-header-button-toggle:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.sidebar-header-button-toggle:not(.disabled):not(:disabled):focus,
button.sidebar-header-button-toggle:not(.disabled):not(:disabled):focus,
a.red-ui-sidebar-header-button-toggle:not(.disabled):not(:disabled):focus,
button.red-ui-sidebar-header-button-toggle:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.sidebar-header-button-toggle:not(.disabled):not(:disabled):active,
button.sidebar-header-button-toggle:not(.disabled):not(:disabled):active,
a.red-ui-sidebar-header-button-toggle:not(.disabled):not(:disabled):active,
button.red-ui-sidebar-header-button-toggle:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.sidebar-header-button-toggle.selected:not(.disabled):not(:disabled),
button.sidebar-header-button-toggle.selected:not(.disabled):not(:disabled),
a.red-ui-sidebar-header-button-toggle.selected:not(.disabled):not(:disabled),
button.red-ui-sidebar-header-button-toggle.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.sidebar-header-button-toggle:not(:first-child),
.button-group button.sidebar-header-button-toggle:not(:first-child),
.button-group a.red-ui-sidebar-header-button-toggle:not(:first-child),
.button-group button.red-ui-sidebar-header-button-toggle:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.sidebar-header-button-toggle:not(:last-child),
.button-group button.sidebar-header-button-toggle:not(:last-child),
.button-group a.red-ui-sidebar-header-button-toggle:not(:last-child),
.button-group button.red-ui-sidebar-header-button-toggle:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.sidebar-header-button-toggle,
.button-group-vertical button.sidebar-header-button-toggle,
.button-group-vertical a.red-ui-sidebar-header-button-toggle,
.button-group-vertical button.red-ui-sidebar-header-button-toggle {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.sidebar-header-button-toggle:not(:first-child),
.button-group-vertical button.sidebar-header-button-toggle:not(:first-child),
.button-group-vertical a.red-ui-sidebar-header-button-toggle:not(:first-child),
.button-group-vertical
	button.red-ui-sidebar-header-button-toggle:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.sidebar-header-button-toggle:not(:last-child),
.button-group-vertical button.sidebar-header-button-toggle:not(:last-child),
.button-group-vertical a.red-ui-sidebar-header-button-toggle:not(:last-child),
.button-group-vertical
	button.red-ui-sidebar-header-button-toggle:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.sidebar-header-button-toggle:focus,
.button-group button.sidebar-header-button-toggle:focus,
.button-group a.red-ui-sidebar-header-button-toggle:focus,
.button-group button.red-ui-sidebar-header-button-toggle:focus {
	position: relative;
}
.button-row a.sidebar-header-button-toggle:not(:first-child),
.button-row button.sidebar-header-button-toggle:not(:first-child),
.button-row a.red-ui-sidebar-header-button-toggle:not(:first-child),
.button-row button.red-ui-sidebar-header-button-toggle:not(:first-child) {
	margin-left: 15px;
}
a.sidebar-header-button-toggle:focus,
button.sidebar-header-button-toggle:focus,
a.red-ui-sidebar-header-button-toggle:focus,
button.red-ui-sidebar-header-button-toggle:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.sidebar-header-button-toggle.primary,
button.sidebar-header-button-toggle.primary,
a.red-ui-sidebar-header-button-toggle.primary,
button.red-ui-sidebar-header-button-toggle.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.sidebar-header-button-toggle.primary.disabled,
a.sidebar-header-button-toggle.primary.ui-state-disabled,
button.sidebar-header-button-toggle.primary.disabled,
button.sidebar-header-button-toggle.primary.ui-state-disabled,
a.red-ui-sidebar-header-button-toggle.primary.disabled,
a.red-ui-sidebar-header-button-toggle.primary.ui-state-disabled,
button.red-ui-sidebar-header-button-toggle.primary.disabled,
button.red-ui-sidebar-header-button-toggle.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.sidebar-header-button-toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
button.sidebar-header-button-toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
a.red-ui-sidebar-header-button-toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-sidebar-header-button-toggle.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.sidebar-header-button-toggle.secondary,
button.sidebar-header-button-toggle.secondary,
a.red-ui-sidebar-header-button-toggle.secondary,
button.red-ui-sidebar-header-button-toggle.secondary {
	background: none;
}
a.sidebar-header-button-toggle.secondary:not(:hover),
button.sidebar-header-button-toggle.secondary:not(:hover),
a.red-ui-sidebar-header-button-toggle.secondary:not(:hover),
button.red-ui-sidebar-header-button-toggle.secondary:not(:hover) {
	border-color: transparent;
}
a.sidebar-header-button-toggle:not(.single),
button.sidebar-header-button-toggle:not(.single),
a.red-ui-sidebar-header-button-toggle:not(.single),
button.red-ui-sidebar-header-button-toggle:not(.single) {
	margin-bottom: 1px;
}
a.sidebar-header-button-toggle:not(.single).selected:not(.disabled):not(:disabled),
button.sidebar-header-button-toggle:not(.single).selected:not(.disabled):not(:disabled),
a.red-ui-sidebar-header-button-toggle:not(.single).selected:not(.disabled):not(:disabled),
button.red-ui-sidebar-header-button-toggle:not(.single).selected:not(.disabled):not(:disabled) {
	border-bottom-width: 2px;
	border-bottom-color: #aaa;
	margin-bottom: 0;
	cursor: default;
}
a.sidebar-header-button-toggle:not(.single):not(.selected),
button.sidebar-header-button-toggle:not(.single):not(.selected),
a.red-ui-sidebar-header-button-toggle:not(.single):not(.selected),
button.red-ui-sidebar-header-button-toggle:not(.single):not(.selected) {
	margin-top: 1px;
}

.sidebar-header-button:not(:first-child),
.red-ui-sidebar-header-button:not(:first-child) {
	border-left: none;
}

.red-ui-sidebar-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(160, 160, 160, 0.5);
	z-index: 5;
}

.red-ui-sidebar-control-right {
	display: none;
	position: absolute;
	top: calc(50% - 26px);
	padding: 15px 8px;
	border: 1px solid #bbbbbb;
	background: #f3f3f3;
	color: #888;
	text-align: center;
	cursor: pointer;
	right: calc(100%);
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	z-index: 13;
}

.red-ui-sidebar-control-left {
	display: none;
	position: absolute;
	top: calc(50% - 26px);
	padding: 15px 8px;
	border: 1px solid #bbbbbb;
	background: #f3f3f3;
	color: #888;
	text-align: center;
	cursor: pointer;
	left: calc(100%);
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-workspace {
	position: absolute;
	margin: 0;
	top: 0px;
	left: 179px;
	bottom: 0px;
	right: 322px;
	overflow: hidden;
	border: 1px solid #bbbbbb;
	box-sizing: border-box;
	transition: left 0.1s ease-in-out;
}

#red-ui-workspace-chart {
	overflow: auto;
	position: absolute;
	bottom: 25px;
	top: 35px;
	left: 0px;
	right: 0px;
	box-sizing: border-box;
	transition: right 0.2s ease;
}
#red-ui-workspace-chart:focus {
	outline: none;
}

#red-ui-workspace-tabs-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(160, 160, 160, 0.5);
	z-index: 5;
	z-index: 2;
	bottom: auto;
	height: 35px;
}

.red-ui-workspace-chart-background {
	fill: #fff;
}

.red-ui-workspace-chart-grid line {
	fill: none;
	shape-rendering: crispEdges;
	stroke: #eee;
	stroke-width: 1px;
}

.red-ui-workspace-select-mode .red-ui-workspace-chart-background {
	opacity: 0.7;
}
.red-ui-workspace-select-mode .red-ui-workspace-chart-grid line {
	opacity: 0.8;
}

.red-ui-palette-closed #red-ui-workspace {
	left: 7px;
}

#red-ui-workspace-tabs:not(.red-ui-workspace-focussed) {
	opacity: 0.8;
}

.red-ui-workspace-disabled-icon {
	display: none;
}

.red-ui-workspace-disabled.red-ui-tab {
	border-top-style: dashed;
	border-left-style: dashed;
	border-right-style: dashed;
}
.red-ui-workspace-disabled.red-ui-tab a {
	font-style: italic;
	color: #aaa !important;
}
.red-ui-workspace-disabled.red-ui-tab.active a {
	font-weight: normal;
	color: #999 !important;
}
.red-ui-workspace-disabled.red-ui-tab .red-ui-workspace-disabled-icon {
	display: inline;
}

#red-ui-navigator-canvas {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 101;
	border-left: 1px solid #bbbbbb;
	border-top: 1px solid #bbbbbb;
	background: rgba(245, 245, 245, 0.8);
	box-shadow: -1px 0 3px rgba(0, 0, 0, 0.2);
}

.red-ui-navigator-border {
	stroke-dasharray: 5, 5;
	pointer-events: none;
	stroke: #dddddd;
	stroke-width: 1;
	fill: #fff;
}

.red-ui-component-footer {
	border-top: 1px solid #bbbbbb;
	background: #f3f3f3;
	text-align: right;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 25px;
	line-height: 25px;
	padding: 0 6px;
	user-select: none;
}
.red-ui-component-footer .button-group:not(:last-child) {
	margin-right: 5px;
}
.red-ui-component-footer > .button-group {
	display: inline-block;
}
.red-ui-component-footer > .button-group > .red-ui-footer-button {
	margin-top: -1px;
}

a.red-ui-footer-button,
button.red-ui-footer-button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
	width: 19px;
	height: 19px;
	padding: 0;
}
a.red-ui-footer-button.disabled,
a.red-ui-footer-button:disabled,
button.red-ui-footer-button.disabled,
button.red-ui-footer-button:disabled {
	cursor: default;
	color: #bbb !important;
}
a.red-ui-footer-button:hover,
a.red-ui-footer-button:focus,
button.red-ui-footer-button:hover,
button.red-ui-footer-button:focus {
	text-decoration: none;
}
a.red-ui-footer-button:not(.disabled):not(:disabled):hover,
button.red-ui-footer-button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.red-ui-footer-button:not(.disabled):not(:disabled):focus,
button.red-ui-footer-button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.red-ui-footer-button:not(.disabled):not(:disabled):active,
button.red-ui-footer-button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.red-ui-footer-button.selected:not(.disabled):not(:disabled),
button.red-ui-footer-button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.red-ui-footer-button:not(:first-child),
.button-group button.red-ui-footer-button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.red-ui-footer-button:not(:last-child),
.button-group button.red-ui-footer-button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.red-ui-footer-button,
.button-group-vertical button.red-ui-footer-button {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.red-ui-footer-button:not(:first-child),
.button-group-vertical button.red-ui-footer-button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.red-ui-footer-button:not(:last-child),
.button-group-vertical button.red-ui-footer-button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.red-ui-footer-button:focus,
.button-group button.red-ui-footer-button:focus {
	position: relative;
}
.button-row a.red-ui-footer-button:not(:first-child),
.button-row button.red-ui-footer-button:not(:first-child) {
	margin-left: 15px;
}
a.red-ui-footer-button:focus,
button.red-ui-footer-button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.red-ui-footer-button.primary,
button.red-ui-footer-button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.red-ui-footer-button.primary.disabled,
a.red-ui-footer-button.primary.ui-state-disabled,
button.red-ui-footer-button.primary.disabled,
button.red-ui-footer-button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.red-ui-footer-button.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-footer-button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.red-ui-footer-button.secondary,
button.red-ui-footer-button.secondary {
	background: none;
}
a.red-ui-footer-button.secondary:not(:hover),
button.red-ui-footer-button.secondary:not(:hover) {
	border-color: transparent;
}
a.red-ui-footer-button.text-button,
button.red-ui-footer-button.text-button {
	width: auto;
	padding: 0 5px;
}

a.red-ui-footer-button-toggle,
button.red-ui-footer-button-toggle {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 12px;
	line-height: 18px;
	height: 19px;
	width: 19px;
	padding: 0;
}
a.red-ui-footer-button-toggle.disabled,
a.red-ui-footer-button-toggle:disabled,
button.red-ui-footer-button-toggle.disabled,
button.red-ui-footer-button-toggle:disabled {
	cursor: default;
	color: #bbb !important;
}
a.red-ui-footer-button-toggle:hover,
a.red-ui-footer-button-toggle:focus,
button.red-ui-footer-button-toggle:hover,
button.red-ui-footer-button-toggle:focus {
	text-decoration: none;
}
a.red-ui-footer-button-toggle:not(.disabled):not(:disabled):hover,
button.red-ui-footer-button-toggle:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.red-ui-footer-button-toggle:not(.disabled):not(:disabled):focus,
button.red-ui-footer-button-toggle:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.red-ui-footer-button-toggle:not(.disabled):not(:disabled):active,
button.red-ui-footer-button-toggle:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.red-ui-footer-button-toggle.selected:not(.disabled):not(:disabled),
button.red-ui-footer-button-toggle.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.red-ui-footer-button-toggle:not(:first-child),
.button-group button.red-ui-footer-button-toggle:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.red-ui-footer-button-toggle:not(:last-child),
.button-group button.red-ui-footer-button-toggle:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.red-ui-footer-button-toggle,
.button-group-vertical button.red-ui-footer-button-toggle {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.red-ui-footer-button-toggle:not(:first-child),
.button-group-vertical button.red-ui-footer-button-toggle:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.red-ui-footer-button-toggle:not(:last-child),
.button-group-vertical button.red-ui-footer-button-toggle:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.red-ui-footer-button-toggle:focus,
.button-group button.red-ui-footer-button-toggle:focus {
	position: relative;
}
.button-row a.red-ui-footer-button-toggle:not(:first-child),
.button-row button.red-ui-footer-button-toggle:not(:first-child) {
	margin-left: 15px;
}
a.red-ui-footer-button-toggle:focus,
button.red-ui-footer-button-toggle:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.red-ui-footer-button-toggle.primary,
button.red-ui-footer-button-toggle.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.red-ui-footer-button-toggle.primary.disabled,
a.red-ui-footer-button-toggle.primary.ui-state-disabled,
button.red-ui-footer-button-toggle.primary.disabled,
button.red-ui-footer-button-toggle.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.red-ui-footer-button-toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-footer-button-toggle.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.red-ui-footer-button-toggle.secondary,
button.red-ui-footer-button-toggle.secondary {
	background: none;
}
a.red-ui-footer-button-toggle.secondary:not(:hover),
button.red-ui-footer-button-toggle.secondary:not(:hover) {
	border-color: transparent;
}
a.red-ui-footer-button-toggle:not(.single),
button.red-ui-footer-button-toggle:not(.single) {
	margin-bottom: 1px;
}
a.red-ui-footer-button-toggle:not(.single).selected:not(.disabled):not(:disabled),
button.red-ui-footer-button-toggle:not(.single).selected:not(.disabled):not(:disabled) {
	border-bottom-width: 2px;
	border-bottom-color: #aaa;
	margin-bottom: 0;
	cursor: default;
}
a.red-ui-footer-button-toggle:not(.single):not(.selected),
button.red-ui-footer-button-toggle:not(.single):not(.selected) {
	margin-top: 1px;
}
a.red-ui-footer-button-toggle.text-button,
button.red-ui-footer-button-toggle.text-button {
	width: auto;
	padding: 0 5px;
}

.red-ui-statusbar-widget {
	margin: 0 2px;
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	line-height: 20px;
}

.red-ui-statusbar-bucket {
	position: absolute;
	top: 0;
	bottom: 0;
}

.red-ui-statusbar-bucket-left {
	left: 10px;
}
.red-ui-statusbar-bucket-left .red-ui-statusbar-widget:first-child {
	margin-left: 0;
}

.red-ui-statusbar-bucket-right {
	right: 10px;
}
.red-ui-statusbar-bucket-right .red-ui-statusbar-widget:last-child {
	margin-right: 0;
}

#red-ui-loading-progress {
	position: absolute;
	background: #f3f3f3;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	z-index: 200;
}
#red-ui-loading-progress > div {
	position: absolute;
	top: 30%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 300px;
	height: 80px;
	text-align: center;
	color: #888;
}

.red-ui-loading-bar {
	box-sizing: border-box;
	width: 300px;
	height: 30px;
	border: 2px solid #bbbbbb;
	border-radius: 4px;
}
.red-ui-loading-bar > span {
	display: block;
	height: 100%;
	background: #dddddd;
	transition: width 0.2s;
	width: 10%;
}

.red-ui-loading-bar-label {
	font-size: 13px;
	margin-bottom: 2px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-workspace-toolbar {
	display: none;
	color: #888;
	font-size: 12px;
	line-height: 18px;
	position: absolute;
	top: 35px;
	left: 0;
	right: 0;
	padding: 7px;
	height: 40px;
	box-sizing: border-box;
	background: #fff;
	border-bottom: 1px solid #dddddd;
	white-space: nowrap;
	transition: right 0.2s ease;
	overflow: hidden;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
#red-ui-workspace-toolbar label {
	padding: 1px 8px;
	margin: 0;
	font-size: 12px;
}
#red-ui-workspace-toolbar input[type='checkbox'] {
	margin: 0 3px 0 0;
	padding: 0;
}
#red-ui-workspace-toolbar .button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	margin-right: 10px;
	padding: 2px 8px;
}
#red-ui-workspace-toolbar .button.disabled,
#red-ui-workspace-toolbar .button:disabled {
	cursor: default;
	color: #bbb !important;
}
#red-ui-workspace-toolbar .button:hover,
#red-ui-workspace-toolbar .button:focus {
	text-decoration: none;
}
#red-ui-workspace-toolbar .button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
#red-ui-workspace-toolbar .button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
#red-ui-workspace-toolbar .button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
#red-ui-workspace-toolbar .button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group #red-ui-workspace-toolbar .button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group #red-ui-workspace-toolbar .button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical #red-ui-workspace-toolbar .button {
	display: block;
	min-width: 22px;
}
.button-group-vertical #red-ui-workspace-toolbar .button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical #red-ui-workspace-toolbar .button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group #red-ui-workspace-toolbar .button:focus {
	position: relative;
}
.button-row #red-ui-workspace-toolbar .button:not(:first-child) {
	margin-left: 15px;
}
#red-ui-workspace-toolbar .button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
#red-ui-workspace-toolbar .button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
#red-ui-workspace-toolbar .button.primary.disabled,
#red-ui-workspace-toolbar .button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
#red-ui-workspace-toolbar
	.button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
#red-ui-workspace-toolbar .button.secondary {
	background: none;
}
#red-ui-workspace-toolbar .button.secondary:not(:hover) {
	border-color: transparent;
}
#red-ui-workspace-toolbar .button-group {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
#red-ui-workspace-toolbar .button-group .button:first-child {
	margin-right: 0;
}
#red-ui-workspace-toolbar
	.button-group:not(.spinner-group)
	.button:not(:first-child) {
	border-left: none;
}
#red-ui-workspace-toolbar .button-group .button.active {
	background: #efefef;
	cursor: default;
}
#red-ui-workspace-toolbar .spinner-value {
	width: 25px;
	color: #888;
	padding: 0 5px;
	display: inline-block;
	text-align: center;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	margin: 0;
	height: 24px;
	font-size: 12px;
	background: #fff;
	line-height: 22px;
	box-sizing: border-box;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-notifications {
	z-index: 100;
	width: 500px;
	margin-left: -250px;
	left: 50%;
	position: absolute;
	top: 1px;
}

.red-ui-notification {
	box-sizing: border-box;
	position: relative;
	padding: 8px 18px 0px;
	margin-bottom: 4px;
	box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	color: #555;
	border: 1px solid #325c80;
	border-left-width: 16px;
	overflow: hidden;
}
.red-ui-notification .ui-dialog-buttonset {
	margin-top: 20px;
	margin-bottom: 10px;
}

.red-ui-notification p:first-child {
	font-size: 1.1em;
	font-weight: 400;
}

.red-ui-notification a {
	text-decoration: none;
}
.red-ui-notification a:hover {
	text-decoration: underline;
}

.red-ui-notification-success {
	border-color: #4b8400;
}

.red-ui-notification-warning {
	border-color: #ffcf70;
}

.red-ui-notification-error {
	border-color: #df2935;
}

.red-ui-notification-compact .ui-dialog-buttonset {
	margin-top: 0;
	position: absolute;
	top: 6px;
	right: 10px;
}
.red-ui-notification-compact .ui-dialog-buttonset button {
	line-height: 12px;
}

.red-ui-notification-shake-horizontal {
	animation: red-ui-notification-shake-horizontal 0.3s steps(2, end) both;
}

@keyframes red-ui-notification-shake-horizontal {
	0%,
	100% {
		-webkit-transform: translateX(0);
		transform: translateX(0);
	}
	10%,
	30%,
	50%,
	70% {
		-webkit-transform: translateX(-1px);
		transform: translateX(-1px);
	}
	20%,
	40%,
	60% {
		-webkit-transform: translateX(1px);
		transform: translateX(1px);
	}
}
/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-editor-stack {
	position: absolute;
	margin: 0;
	top: 0;
	bottom: 0px;
	right: 323px;
	width: 0;
	z-index: 5;
}

.red-ui-tray {
	position: absolute;
	margin: 0;
	top: 0;
	width: auto;
	right: -1000px;
	bottom: 0;
	background: #fff;
	border-left: 1px solid #dddddd;
	border-bottom: 1px solid #bbbbbb;
	box-sizing: content-box;
}

.red-ui-tray.open {
	right: 0;
}

.red-ui-tray-body-wrapper {
	width: 100%;
	box-sizing: border-box;
	overflow: auto;
}

.red-ui-tray-body {
	position: relative;
	box-sizing: border-box;
	padding: 0.1px;
}

.red-ui-tray-content {
	overflow: auto;
	position: relative;
}
.red-ui-tray-content .red-ui-palette-icon-fa {
	top: 6px;
	left: 4px;
}

.red-ui-tray-header {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	position: relative;
	box-sizing: border-box;
	font-weight: bold;
	border-bottom: 1px solid #dddddd;
	background: #f3f3f3;
}
.red-ui-tray-header:after {
	content: '';
	display: table;
	clear: both;
}

.red-ui-tray-footer {
	border-top: 1px solid #bbbbbb;
	background: #f3f3f3;
	text-align: right;
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 25px;
	line-height: 25px;
	padding: 0 6px;
	user-select: none;
	height: 35px;
	font-size: 14px !important;
	line-height: 35px;
	vertical-align: middle;
}
.red-ui-tray-footer .button-group:not(:last-child) {
	margin-right: 5px;
}
.red-ui-tray-footer button.red-ui-button {
	padding: 0px 8px;
	height: 26px;
	line-height: 26px;
}
.red-ui-tray-footer button.red-ui-button.toggle:not(.selected) {
	color: #666 !important;
	background: #efefef;
}
.red-ui-tray-footer .red-ui-tray-footer-left {
	display: inline-block;
	margin-right: 20px;
	float: left;
}
.red-ui-tray-footer .red-ui-tray-footer-right {
	float: right;
}

.red-ui-tray-toolbar {
	text-align: right;
	padding: 6px;
}
.red-ui-tray-toolbar button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	font-size: 14px;
	padding: 6px 14px;
	margin-right: 8px;
}
.red-ui-tray-toolbar button.disabled,
.red-ui-tray-toolbar button:disabled {
	cursor: default;
	color: #bbb !important;
}
.red-ui-tray-toolbar button:hover,
.red-ui-tray-toolbar button:focus {
	text-decoration: none;
}
.red-ui-tray-toolbar button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
.red-ui-tray-toolbar button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
.red-ui-tray-toolbar button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
.red-ui-tray-toolbar button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group .red-ui-tray-toolbar button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group .red-ui-tray-toolbar button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical .red-ui-tray-toolbar button {
	display: block;
	min-width: 22px;
}
.button-group-vertical .red-ui-tray-toolbar button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical .red-ui-tray-toolbar button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group .red-ui-tray-toolbar button:focus {
	position: relative;
}
.button-row .red-ui-tray-toolbar button:not(:first-child) {
	margin-left: 15px;
}
.red-ui-tray-toolbar button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
.red-ui-tray-toolbar button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.red-ui-tray-toolbar button.primary.disabled,
.red-ui-tray-toolbar button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
.red-ui-tray-toolbar
	button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.red-ui-tray-toolbar button.secondary {
	background: none;
}
.red-ui-tray-toolbar button.secondary:not(:hover) {
	border-color: transparent;
}
.red-ui-tray-toolbar button.disabled {
	background: none;
}
.red-ui-tray-toolbar button.disabled:focus {
	outline: none;
}
.red-ui-tray-toolbar button.leftButton {
	float: left;
	margin-top: 1px;
}
.red-ui-tray-toolbar button:not(.leftButton):not(:last-child) {
	margin-right: 16px;
}
.red-ui-tray-toolbar button.ui-state-disabled {
	opacity: 1;
}
.red-ui-tray-toolbar button.toggle {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
}
.red-ui-tray-toolbar button.toggle.disabled,
.red-ui-tray-toolbar button.toggle:disabled {
	cursor: default;
	color: #bbb !important;
}
.red-ui-tray-toolbar button.toggle:hover,
.red-ui-tray-toolbar button.toggle:focus {
	text-decoration: none;
}
.red-ui-tray-toolbar button.toggle:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
.red-ui-tray-toolbar button.toggle:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
.red-ui-tray-toolbar button.toggle:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
.red-ui-tray-toolbar button.toggle.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group .red-ui-tray-toolbar button.toggle:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group .red-ui-tray-toolbar button.toggle:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical .red-ui-tray-toolbar button.toggle {
	display: block;
	min-width: 22px;
}
.button-group-vertical .red-ui-tray-toolbar button.toggle:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical .red-ui-tray-toolbar button.toggle:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group .red-ui-tray-toolbar button.toggle:focus {
	position: relative;
}
.button-row .red-ui-tray-toolbar button.toggle:not(:first-child) {
	margin-left: 15px;
}
.red-ui-tray-toolbar button.toggle:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
.red-ui-tray-toolbar button.toggle.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.red-ui-tray-toolbar button.toggle.primary.disabled,
.red-ui-tray-toolbar button.toggle.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
.red-ui-tray-toolbar
	button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.red-ui-tray-toolbar button.toggle.secondary {
	background: none;
}
.red-ui-tray-toolbar button.toggle.secondary:not(:hover) {
	border-color: transparent;
}
.red-ui-tray-toolbar button.toggle:not(.single) {
	margin-bottom: 1px;
}
.red-ui-tray-toolbar
	button.toggle:not(.single).selected:not(.disabled):not(:disabled) {
	border-bottom-width: 2px;
	border-bottom-color: #aaa;
	margin-bottom: 0;
	cursor: default;
}
.red-ui-tray-toolbar button.toggle:not(.single):not(.selected) {
	margin-top: 1px;
}

.red-ui-tray-titlebar {
	color: #444;
	border-bottom: 1px solid #dddddd;
	padding: 8px;
}

.red-ui-editor ul.red-ui-tray-breadcrumbs {
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.red-ui-editor ul.red-ui-tray-breadcrumbs li {
	display: inline-block;
	padding: 0;
	margin: 0;
}
.red-ui-editor ul.red-ui-tray-breadcrumbs li:not(:last-child) {
	color: #888;
	font-weight: normal;
}
.red-ui-editor ul.red-ui-tray-breadcrumbs li:not(:last-child):after {
	display: inline-block;
	content: '>';
	margin: 0 5px;
}

.red-ui-tray-resize-handle {
	position: absolute;
	top: 0px;
	bottom: 0px;
	width: 7px;
	left: -9px;
	background: #f3f3f3 url(../../assets/red/grip.png) no-repeat 50% 50%;
	cursor: col-resize;
	border-left: 1px solid #bbbbbb;
	box-shadow: -1px 0 6px rgba(0, 0, 0, 0.2);
}
.red-ui-tray-resize-handle.red-ui-tray-resize-maximised {
	background: #f3f3f3;
	cursor: default;
}

a.red-ui-tray-resize-button,
button.red-ui-tray-resize-button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	display: block;
	height: 37px;
	line-height: 35px;
	border: none;
	border-bottom: 1px solid #dddddd;
	margin: 0;
	background: #f3f3f3;
	color: #888;
}
a.red-ui-tray-resize-button.disabled,
a.red-ui-tray-resize-button:disabled,
button.red-ui-tray-resize-button.disabled,
button.red-ui-tray-resize-button:disabled {
	cursor: default;
	color: #bbb !important;
}
a.red-ui-tray-resize-button:hover,
a.red-ui-tray-resize-button:focus,
button.red-ui-tray-resize-button:hover,
button.red-ui-tray-resize-button:focus {
	text-decoration: none;
}
a.red-ui-tray-resize-button:not(.disabled):not(:disabled):hover,
button.red-ui-tray-resize-button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.red-ui-tray-resize-button:not(.disabled):not(:disabled):focus,
button.red-ui-tray-resize-button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.red-ui-tray-resize-button:not(.disabled):not(:disabled):active,
button.red-ui-tray-resize-button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.red-ui-tray-resize-button.selected:not(.disabled):not(:disabled),
button.red-ui-tray-resize-button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.red-ui-tray-resize-button:not(:first-child),
.button-group button.red-ui-tray-resize-button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.red-ui-tray-resize-button:not(:last-child),
.button-group button.red-ui-tray-resize-button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.red-ui-tray-resize-button,
.button-group-vertical button.red-ui-tray-resize-button {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.red-ui-tray-resize-button:not(:first-child),
.button-group-vertical button.red-ui-tray-resize-button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.red-ui-tray-resize-button:not(:last-child),
.button-group-vertical button.red-ui-tray-resize-button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.red-ui-tray-resize-button:focus,
.button-group button.red-ui-tray-resize-button:focus {
	position: relative;
}
.button-row a.red-ui-tray-resize-button:not(:first-child),
.button-row button.red-ui-tray-resize-button:not(:first-child) {
	margin-left: 15px;
}
a.red-ui-tray-resize-button:focus,
button.red-ui-tray-resize-button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.red-ui-tray-resize-button.primary,
button.red-ui-tray-resize-button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.red-ui-tray-resize-button.primary.disabled,
a.red-ui-tray-resize-button.primary.ui-state-disabled,
button.red-ui-tray-resize-button.primary.disabled,
button.red-ui-tray-resize-button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.red-ui-tray-resize-button.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-tray-resize-button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.red-ui-tray-resize-button.secondary,
button.red-ui-tray-resize-button.secondary {
	background: none;
}
a.red-ui-tray-resize-button.secondary:not(:hover),
button.red-ui-tray-resize-button.secondary:not(:hover) {
	border-color: transparent;
}

.red-ui-editor .red-ui-tray .dialog-form,
.red-ui-editor .red-ui-tray #dialog-form,
.red-ui-editor .red-ui-tray #node-config-dialog-edit-form {
	margin: 10px 20px;
	height: calc(100% - 20px);
}

.red-ui-editor .dialog-form,
.red-ui-editor #dialog-form,
.red-ui-editor #node-config-dialog-edit-form,
.red-ui-editor-dialog .dialog-form,
.red-ui-editor-dialog #dialog-form,
.red-ui-editor-dialog #node-config-dialog-edit-form {
	margin: 0;
	height: 100%;
}
.red-ui-editor .input-error,
.red-ui-editor-dialog .input-error {
	border-color: #d6615f !important;
}
.red-ui-editor .input-updated,
.red-ui-editor-dialog .input-updated {
	border-color: #ff7f0e !important;
}
.red-ui-editor .form-row,
.red-ui-editor-dialog .form-row {
	clear: both;
	color: #555;
	margin-bottom: 12px;
}
.red-ui-editor .form-row label,
.red-ui-editor-dialog .form-row label {
	display: inline-block;
	width: 100px;
}
.red-ui-editor .form-row input,
.red-ui-editor .form-row div[contenteditable='true'],
.red-ui-editor-dialog .form-row input,
.red-ui-editor-dialog .form-row div[contenteditable='true'] {
	width: 70%;
}
.red-ui-editor .form-tips,
.red-ui-editor-dialog .form-tips {
	background: #ffe;
	padding: 8px;
	border-radius: 2px;
	border: 1px solid #dddddd;
	max-width: 450px;
}
.red-ui-editor .form-tips code,
.red-ui-editor-dialog .form-tips code {
	border: none;
	padding: auto;
}
.red-ui-editor .form-tips a,
.red-ui-editor-dialog .form-tips a {
	text-decoration: underline;
}
.red-ui-editor .form-warning,
.red-ui-editor-dialog .form-warning {
	border-color: #ad1625;
}

.node-text-editor {
	position: relative;
}
.node-text-editor .red-ui-editor-text-help {
	position: absolute;
	bottom: 0px;
	right: 1px;
	border-bottom-right-radius: 5px;
	z-index: 8;
	border-bottom: none;
	border-right: none;
}

.red-ui-editor-text-container {
	border: 1px solid #ccc;
	border-radius: 5px;
	overflow: hidden;
	font-size: 14px !important;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace !important;
	height: 100%;
}
.red-ui-editor-text-container.red-ui-editor-text-container-toolbar {
	height: calc(100% - 40px);
}

a.editor-button,
button.editor-button,
a.red-ui-button,
button.red-ui-button {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	height: 34px;
	line-height: 32px;
	font-size: 13px;
	border-radius: 2px;
	padding: 0 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
}
a.editor-button.disabled,
a.editor-button:disabled,
button.editor-button.disabled,
button.editor-button:disabled,
a.red-ui-button.disabled,
a.red-ui-button:disabled,
button.red-ui-button.disabled,
button.red-ui-button:disabled {
	cursor: default;
	color: #bbb !important;
}
a.editor-button:hover,
a.editor-button:focus,
button.editor-button:hover,
button.editor-button:focus,
a.red-ui-button:hover,
a.red-ui-button:focus,
button.red-ui-button:hover,
button.red-ui-button:focus {
	text-decoration: none;
}
a.editor-button:not(.disabled):not(:disabled):hover,
button.editor-button:not(.disabled):not(:disabled):hover,
a.red-ui-button:not(.disabled):not(:disabled):hover,
button.red-ui-button:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.editor-button:not(.disabled):not(:disabled):focus,
button.editor-button:not(.disabled):not(:disabled):focus,
a.red-ui-button:not(.disabled):not(:disabled):focus,
button.red-ui-button:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.editor-button:not(.disabled):not(:disabled):active,
button.editor-button:not(.disabled):not(:disabled):active,
a.red-ui-button:not(.disabled):not(:disabled):active,
button.red-ui-button:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.editor-button.selected:not(.disabled):not(:disabled),
button.editor-button.selected:not(.disabled):not(:disabled),
a.red-ui-button.selected:not(.disabled):not(:disabled),
button.red-ui-button.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.editor-button:not(:first-child),
.button-group button.editor-button:not(:first-child),
.button-group a.red-ui-button:not(:first-child),
.button-group button.red-ui-button:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.editor-button:not(:last-child),
.button-group button.editor-button:not(:last-child),
.button-group a.red-ui-button:not(:last-child),
.button-group button.red-ui-button:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.editor-button,
.button-group-vertical button.editor-button,
.button-group-vertical a.red-ui-button,
.button-group-vertical button.red-ui-button {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.editor-button:not(:first-child),
.button-group-vertical button.editor-button:not(:first-child),
.button-group-vertical a.red-ui-button:not(:first-child),
.button-group-vertical button.red-ui-button:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.editor-button:not(:last-child),
.button-group-vertical button.editor-button:not(:last-child),
.button-group-vertical a.red-ui-button:not(:last-child),
.button-group-vertical button.red-ui-button:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.editor-button:focus,
.button-group button.editor-button:focus,
.button-group a.red-ui-button:focus,
.button-group button.red-ui-button:focus {
	position: relative;
}
.button-row a.editor-button:not(:first-child),
.button-row button.editor-button:not(:first-child),
.button-row a.red-ui-button:not(:first-child),
.button-row button.red-ui-button:not(:first-child) {
	margin-left: 15px;
}
a.editor-button:focus,
button.editor-button:focus,
a.red-ui-button:focus,
button.red-ui-button:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.editor-button.primary,
button.editor-button.primary,
a.red-ui-button.primary,
button.red-ui-button.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.editor-button.primary.disabled,
a.editor-button.primary.ui-state-disabled,
button.editor-button.primary.disabled,
button.editor-button.primary.ui-state-disabled,
a.red-ui-button.primary.disabled,
a.red-ui-button.primary.ui-state-disabled,
button.red-ui-button.primary.disabled,
button.red-ui-button.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.editor-button.primary:not(.disabled):not(.ui-button-disabled):hover,
button.editor-button.primary:not(.disabled):not(.ui-button-disabled):hover,
a.red-ui-button.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.editor-button.secondary,
button.editor-button.secondary,
a.red-ui-button.secondary,
button.red-ui-button.secondary {
	background: none;
}
a.editor-button.secondary:not(:hover),
button.editor-button.secondary:not(:hover),
a.red-ui-button.secondary:not(:hover),
button.red-ui-button.secondary:not(:hover) {
	border-color: transparent;
}
a.editor-button.toggle,
button.editor-button.toggle,
a.red-ui-button.toggle,
button.red-ui-button.toggle {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
}
a.editor-button.toggle.disabled,
a.editor-button.toggle:disabled,
button.editor-button.toggle.disabled,
button.editor-button.toggle:disabled,
a.red-ui-button.toggle.disabled,
a.red-ui-button.toggle:disabled,
button.red-ui-button.toggle.disabled,
button.red-ui-button.toggle:disabled {
	cursor: default;
	color: #bbb !important;
}
a.editor-button.toggle:hover,
a.editor-button.toggle:focus,
button.editor-button.toggle:hover,
button.editor-button.toggle:focus,
a.red-ui-button.toggle:hover,
a.red-ui-button.toggle:focus,
button.red-ui-button.toggle:hover,
button.red-ui-button.toggle:focus {
	text-decoration: none;
}
a.editor-button.toggle:not(.disabled):not(:disabled):hover,
button.editor-button.toggle:not(.disabled):not(:disabled):hover,
a.red-ui-button.toggle:not(.disabled):not(:disabled):hover,
button.red-ui-button.toggle:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
a.editor-button.toggle:not(.disabled):not(:disabled):focus,
button.editor-button.toggle:not(.disabled):not(:disabled):focus,
a.red-ui-button.toggle:not(.disabled):not(:disabled):focus,
button.red-ui-button.toggle:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
a.editor-button.toggle:not(.disabled):not(:disabled):active,
button.editor-button.toggle:not(.disabled):not(:disabled):active,
a.red-ui-button.toggle:not(.disabled):not(:disabled):active,
button.red-ui-button.toggle:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
a.editor-button.toggle.selected:not(.disabled):not(:disabled),
button.editor-button.toggle.selected:not(.disabled):not(:disabled),
a.red-ui-button.toggle.selected:not(.disabled):not(:disabled),
button.red-ui-button.toggle.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group a.editor-button.toggle:not(:first-child),
.button-group button.editor-button.toggle:not(:first-child),
.button-group a.red-ui-button.toggle:not(:first-child),
.button-group button.red-ui-button.toggle:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group a.editor-button.toggle:not(:last-child),
.button-group button.editor-button.toggle:not(:last-child),
.button-group a.red-ui-button.toggle:not(:last-child),
.button-group button.red-ui-button.toggle:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical a.editor-button.toggle,
.button-group-vertical button.editor-button.toggle,
.button-group-vertical a.red-ui-button.toggle,
.button-group-vertical button.red-ui-button.toggle {
	display: block;
	min-width: 22px;
}
.button-group-vertical a.editor-button.toggle:not(:first-child),
.button-group-vertical button.editor-button.toggle:not(:first-child),
.button-group-vertical a.red-ui-button.toggle:not(:first-child),
.button-group-vertical button.red-ui-button.toggle:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical a.editor-button.toggle:not(:last-child),
.button-group-vertical button.editor-button.toggle:not(:last-child),
.button-group-vertical a.red-ui-button.toggle:not(:last-child),
.button-group-vertical button.red-ui-button.toggle:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group a.editor-button.toggle:focus,
.button-group button.editor-button.toggle:focus,
.button-group a.red-ui-button.toggle:focus,
.button-group button.red-ui-button.toggle:focus {
	position: relative;
}
.button-row a.editor-button.toggle:not(:first-child),
.button-row button.editor-button.toggle:not(:first-child),
.button-row a.red-ui-button.toggle:not(:first-child),
.button-row button.red-ui-button.toggle:not(:first-child) {
	margin-left: 15px;
}
a.editor-button.toggle:focus,
button.editor-button.toggle:focus,
a.red-ui-button.toggle:focus,
button.red-ui-button.toggle:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
a.editor-button.toggle.primary,
button.editor-button.toggle.primary,
a.red-ui-button.toggle.primary,
button.red-ui-button.toggle.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
a.editor-button.toggle.primary.disabled,
a.editor-button.toggle.primary.ui-state-disabled,
button.editor-button.toggle.primary.disabled,
button.editor-button.toggle.primary.ui-state-disabled,
a.red-ui-button.toggle.primary.disabled,
a.red-ui-button.toggle.primary.ui-state-disabled,
button.red-ui-button.toggle.primary.disabled,
button.red-ui-button.toggle.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
a.editor-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
button.editor-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
a.red-ui-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover,
button.red-ui-button.toggle.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
a.editor-button.toggle.secondary,
button.editor-button.toggle.secondary,
a.red-ui-button.toggle.secondary,
button.red-ui-button.toggle.secondary {
	background: none;
}
a.editor-button.toggle.secondary:not(:hover),
button.editor-button.toggle.secondary:not(:hover),
a.red-ui-button.toggle.secondary:not(:hover),
button.red-ui-button.toggle.secondary:not(:hover) {
	border-color: transparent;
}
a.editor-button.toggle:not(.single),
button.editor-button.toggle:not(.single),
a.red-ui-button.toggle:not(.single),
button.red-ui-button.toggle:not(.single) {
	margin-bottom: 1px;
}
a.editor-button.toggle:not(.single).selected:not(.disabled):not(:disabled),
button.editor-button.toggle:not(.single).selected:not(.disabled):not(:disabled),
a.red-ui-button.toggle:not(.single).selected:not(.disabled):not(:disabled),
button.red-ui-button.toggle:not(.single).selected:not(.disabled):not(:disabled) {
	border-bottom-width: 2px;
	border-bottom-color: #aaa;
	margin-bottom: 0;
	cursor: default;
}
a.editor-button.toggle:not(.single):not(.selected),
button.editor-button.toggle:not(.single):not(.selected),
a.red-ui-button.toggle:not(.single):not(.selected),
button.red-ui-button.toggle:not(.single):not(.selected) {
	margin-top: 1px;
}

a.editor-button-small,
button.editor-button-small,
a.red-ui-button-small,
button.red-ui-button-small {
	height: 20px;
	min-width: 20px;
	line-height: 18px;
	font-size: 10px;
	border-radius: 2px;
	padding: 0 5px;
}

#red-ui-editor-config-scope-warning {
	display: inline-block;
	margin-right: 5px;
	color: #cab200;
	vertical-align: middle;
}

#red-ui-editor-config-scope {
	margin: 1px 0 0 0;
	padding: 0;
	height: 22px;
	width: 200px;
}

.red-ui-editor .red-ui-tray .red-ui-editor-type-expression #dialog-form {
	margin: 0;
	height: 100%;
}
.red-ui-editor
	.red-ui-tray
	.red-ui-editor-type-expression
	#dialog-form
	.red-ui-panel:first-child {
	padding: 20px 20px 0;
}

.red-ui-editor-type-expression-tab-content {
	position: relative;
	padding: 0 20px;
}

#red-ui-editor-type-expression-help {
	position: absolute;
	top: 35px;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 0 20px;
	overflow: auto;
	box-sizing: border-box;
}

#red-ui-editor-type-expression-panel-info > .form-row {
	margin: 0;
}
#red-ui-editor-type-expression-panel-info > .form-row > div:first-child {
	margin-top: 10px;
}

.red-ui-editor-type-expression-legacy {
	float: left;
}

.red-ui-editor-type-buffer-type {
	text-align: right;
}

.red-ui-editor .red-ui-tray .red-ui-editor-type-markdown-editor #dialog-form {
	margin: 0;
	height: 100%;
}
.red-ui-editor
	.red-ui-tray
	.red-ui-editor-type-markdown-editor
	#dialog-form
	.red-ui-panel {
	padding: 20px 20px 10px;
}
.red-ui-editor
	.red-ui-tray
	.red-ui-editor-type-markdown-editor
	#dialog-form
	.red-ui-panel:last-child {
	padding-top: 60px;
	background: #f3f3f3;
}

.red-ui-editor-type-markdown-panel-preview {
	padding: 10px;
	border: 1px solid #dddddd;
	border-radius: 5px;
	height: calc(100% - 21px);
	overflow-y: scroll;
	background: #fff;
}

#red-ui-clipboard-hidden {
	position: absolute;
	top: -3000px;
}

.form-row .red-ui-editor-node-label-form-row {
	margin: 5px 0 0 50px;
}
.form-row .red-ui-editor-node-label-form-row label {
	margin-right: 20px;
	text-align: right;
	width: 30px;
}
.form-row .red-ui-editor-node-label-form-row button {
	margin-left: 10px;
}
.form-row .red-ui-editor-node-label-form-row input {
	width: calc(100% - 100px);
}
.form-row .red-ui-editor-node-label-form-row #red-ui-editor-node-icon-module {
	width: calc(55% - 50px);
}
.form-row .red-ui-editor-node-label-form-row #red-ui-editor-node-icon-file {
	width: calc(45% - 55px);
	margin-left: 5px;
}

.red-ui-editor-node-label-form-none span {
	padding-left: 50px;
	width: 100px;
	color: #888;
}

button.red-ui-button.red-ui-editor-node-appearance-button {
	position: relative;
	height: 35px !important;
	text-align: left;
	padding: 0 6px 0 3px;
}
button.red-ui-button.red-ui-editor-node-appearance-button > i {
	width: 15px;
	vertical-align: middle;
	padding-left: 2px;
}
button.red-ui-button.red-ui-editor-node-appearance-button
	.red-ui-search-result-node {
	vertical-align: middle;
	float: none;
	position: relative;
	top: -1px;
}

.red-ui-group-layout-picker {
	padding: 5px;
	background: #fff;
}

.red-ui-group-layout-picker-cell-text {
	position: absolute;
	width: 14px;
	height: 2px;
	border-top: 2px solid #888;
	border-bottom: 2px solid #888;
	margin: 2px;
}
.red-ui-group-layout-picker-cell-text.red-ui-group-layout-text-pos-nw {
	top: 0;
	left: 0;
}
.red-ui-group-layout-picker-cell-text.red-ui-group-layout-text-pos-n {
	top: 0;
	left: calc(50% - 9px);
}
.red-ui-group-layout-picker-cell-text.red-ui-group-layout-text-pos-ne {
	top: 0;
	right: 0;
}
.red-ui-group-layout-picker-cell-text.red-ui-group-layout-text-pos-sw {
	bottom: 0;
	left: 0;
}
.red-ui-group-layout-picker-cell-text.red-ui-group-layout-text-pos-s {
	bottom: 0;
	left: calc(50% - 9px);
}
.red-ui-group-layout-picker-cell-text.red-ui-group-layout-text-pos-se {
	bottom: 0;
	right: 0;
}
.red-ui-group-layout-picker-cell-text.red-ui-group-layout-text-pos- {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	margin: 0;
	background-color: #fff;
	background-size: 100% 100%;
	background-position: 0 0, 50% 50%;
	background-image: linear-gradient(
			45deg,
			transparent 45%,
			#dddddd 45%,
			#dddddd 55%,
			transparent 55%,
			transparent
		),
		linear-gradient(
			-45deg,
			transparent 45%,
			#dddddd 45%,
			#dddddd 55%,
			transparent 55%,
			transparent
		);
	border: none;
}

.red-ui-group-layout-picker button.red-ui-search-result-node {
	float: none;
	position: relative;
	padding: 0;
	margin: 5px;
	width: 32px;
	height: 27px;
}

button.red-ui-group-layout-picker-none {
	width: 100%;
}

.red-ui-color-picker {
	background: #f3f3f3;
}
.red-ui-color-picker input[type='text'] {
	border-radius: 0;
	width: 100%;
	margin-bottom: 0;
	border: none;
	border-bottom: 1px solid #ccc;
}
.red-ui-color-picker small {
	color: #888;
	margin-left: 5px;
	margin-right: 4px;
	display: inline-block;
	min-width: 35px;
	text-align: right;
}

.red-ui-editor-node-appearance-button .red-ui-search-result-node {
	overflow: hidden;
}

.red-ui-color-picker-cell {
	padding: 0;
	border-style: solid;
	border-width: 1px;
	border-color: #dddddd;
}

.red-ui-color-picker-swatch {
	position: absolute;
	top: -1px;
	right: -1px;
	left: -1px;
	bottom: -1px;
	border-radius: 4px;
}

.red-ui-color-picker-cell-none {
	height: 100%;
	background-color: #fff;
	background-size: 100% 100%;
	background-position: 0 0, 50% 50%;
	background-image: linear-gradient(
			45deg,
			transparent 45%,
			#dddddd 45%,
			#dddddd 55%,
			transparent 55%,
			transparent
		),
		linear-gradient(
			-45deg,
			transparent 45%,
			#dddddd 45%,
			#dddddd 55%,
			transparent 55%,
			transparent
		);
}

.red-ui-search-result-node .red-ui-color-picker-cell-none {
	border-radius: 4px;
	background-size: 50% 50%;
	background-image: linear-gradient(
			45deg,
			#eee 25%,
			transparent 25%,
			transparent 75%,
			#eee 75%,
			#eee
		),
		linear-gradient(
			45deg,
			#eee 25%,
			transparent 25%,
			transparent 75%,
			#eee 75%,
			#eee
		);
}

.red-ui-color-picker-opacity-slider {
	position: relative;
	vertical-align: middle;
	display: inline-block;
	width: calc(100% - 50px);
	height: 14px;
	margin: 6px 3px 8px;
	box-sizing: border-box;
	background-color: white;
	background-image: linear-gradient(
			45deg,
			#eee 25%,
			transparent 25%,
			transparent 75%,
			#eee 25%
		),
		linear-gradient(
			-45deg,
			#eee 25%,
			transparent 25%,
			transparent 75%,
			#eee 25%
		);
	background-size: 6px 6px;
}

.red-ui-color-picker-opacity-slider-overlay {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background-image: linear-gradient(90deg, transparent 0%, #f00 100%);
	background-size: 100% 100%;
	border: 1px solid #bbbbbb;
}

div.red-ui-button-small.red-ui-color-picker-opacity-slider-handle {
	z-index: 10;
	top: -4px;
	cursor: pointer;
	min-width: 0;
	width: 10px;
	height: 22px;
	padding: 0;
	border: 1px solid #bbbbbb;
	border-radius: 1px;
	background: #fff;
	box-sizing: border-box;
}

.red-ui-icon-picker select {
	box-sizing: border-box;
	margin: 3px;
	width: calc(100% - 6px);
}

.red-ui-icon-list {
	width: 308px;
	height: 200px;
	overflow-y: scroll;
	line-height: 0px;
	position: relative;
}
.red-ui-icon-list.red-ui-icon-list-dark .red-ui-palette-icon-fa {
	color: #888;
}
.red-ui-icon-list.red-ui-icon-list-dark .red-ui-palette-icon-container {
	background: #fff;
	border-radius: 4px;
}

.red-ui-icon-list-icon {
	display: inline-block;
	margin: 2px;
	padding: 4px;
	cursor: pointer;
	border-radius: 4px;
}
.red-ui-icon-list-icon:hover {
	background: #e6e6e6;
}
.red-ui-icon-list-icon.selected {
	background: #ffebc7;
}
.red-ui-icon-list-icon .red-ui-palette-icon-fa {
	top: 6px;
	left: 3px;
}

.red-ui-icon-list-module {
	background: #f3f3f3;
	font-size: 0.9em;
	padding: 3px;
	color: #888;
	clear: both;
}
.red-ui-icon-list-module i {
	margin-right: 5px;
}

.red-ui-icon-meta {
	border-top: 1px solid #dddddd;
	background: #f7f7f7;
	height: 24px;
}
.red-ui-icon-meta span {
	padding: 4px;
	color: #888;
	font-size: 0.9em;
	line-height: 24px;
}
.red-ui-icon-meta button {
	float: right;
	margin: 2px;
}

.red-ui-editor input.red-ui-editor-type-json-editor-key {
	width: 150px;
}

.red-ui-editor-type-json-editor {
	height: calc(100% - 10px);
}
.red-ui-editor-type-json-editor .red-ui-treeList-container {
	background: #fff;
}
.red-ui-editor-type-json-editor .red-ui-treeList-label {
	padding-top: 0;
	padding-bottom: 0;
	white-space: nowrap;
	min-height: 35px;
}
.red-ui-editor-type-json-editor
	.red-ui-treeList-label
	.red-ui-treeList-icon:before {
	content: '';
	display: inline-block;
	height: 35px;
	vertical-align: middle;
}
.red-ui-editor-type-json-editor .red-ui-treeList-label > span,
.red-ui-editor-type-json-editor .red-ui-treeList-label > span > span {
	vertical-align: middle;
}
.red-ui-editor-type-json-editor .red-ui-treeList-label:hover,
.red-ui-editor-type-json-editor
	.red-ui-treeList-label:hover
	.red-ui-treeList-sublabel-text {
	background: #f9f9f9;
}
.red-ui-editor-type-json-editor
	.red-ui-treeList-label:hover
	.red-ui-editor-type-json-editor-item-gutter
	> span,
.red-ui-editor-type-json-editor
	.red-ui-treeList-label:hover
	.red-ui-editor-type-json-editor-item-gutter
	> button,
.red-ui-editor-type-json-editor
	.red-ui-treeList-label:hover
	.red-ui-treeList-sublabel-text
	.red-ui-editor-type-json-editor-item-gutter
	> span,
.red-ui-editor-type-json-editor
	.red-ui-treeList-label:hover
	.red-ui-treeList-sublabel-text
	.red-ui-editor-type-json-editor-item-gutter
	> button {
	display: inline-block;
}
.red-ui-editor-type-json-editor
	.red-ui-treeList-label.selected
	.red-ui-editor-type-json-editor-item-gutter {
	background: #e6e6e6;
}
.red-ui-editor-type-json-editor
	.red-ui-treeList-label.selected:hover
	.red-ui-editor-type-json-editor-item-gutter {
	background: #efefef;
}
.red-ui-editor-type-json-editor
	.red-ui-treeList-label.red-ui-editor-type-json-root-node
	.red-ui-editor-type-json-editor-item-gutter
	> span,
.red-ui-editor-type-json-editor
	.red-ui-treeList-label.red-ui-editor-type-json-root-node
	.red-ui-editor-type-json-editor-item-gutter
	> button {
	display: inline-block;
}

.red-ui-editor-type-json-editor-controls {
	height: 34px;
	line-height: 34px;
	display: none;
}

.red-ui-editor-type-json-editor-key {
	width: 100px;
}

.red-ui-editor-type-json-editor-label {
	display: inline-block;
	white-space: pre-wrap;
}

.red-ui-editor-type-json-editor-label-value {
	min-width: 200px;
}

.red-ui-editor-type-json-editor-label-value,
.red-ui-editor-type-json-editor-label-key {
	display: inline-block;
	box-sizing: border-box;
	min-height: 34px;
	line-height: 30px;
	padding: 0 2px;
	border: 2px solid rgba(0, 0, 0, 0);
	border-radius: 3px;
}
.red-ui-editor-type-json-editor-label-value:not(.red-ui-editor-type-json-editor-label-array-key):hover,
.red-ui-editor-type-json-editor-label-key:not(.red-ui-editor-type-json-editor-label-array-key):hover {
	border-color: #e6e6e6;
	border-style: dashed;
}

.red-ui-editor-type-json-editor-item-gutter {
	width: 48px;
	padding-left: 4px;
	height: 100%;
	line-height: 35px;
	color: #aaa;
	background: #f9f9f9;
}
.red-ui-editor-type-json-editor-item-gutter > span {
	display: inline-block;
	height: 35px;
	line-height: 35px;
	width: 20px;
	text-align: center;
}
.red-ui-editor-type-json-editor-item-gutter > span,
.red-ui-editor-type-json-editor-item-gutter > button {
	display: none;
}

.red-ui-editor-type-json-editor-item-handle {
	cursor: move;
}

.red-ui-editor-type-json-tab-content {
	position: relative;
	height: calc(100% - 40px);
}

button.red-ui-toggleButton.toggle {
	text-align: left;
}
button.red-ui-toggleButton.toggle i {
	min-width: 15px;
}

.red-ui-editor-subflow-env-ui-row {
	margin-right: 3px;
}
.red-ui-editor-subflow-env-ui-row > div {
	display: grid;
	grid-template-columns: 16px 40px 35% auto;
}
.red-ui-editor-subflow-env-ui-row > div:first-child {
	font-size: 0.9em;
	color: #aaa;
	margin: 3px 0 -4px;
}
.red-ui-editor-subflow-env-ui-row > div:first-child > div {
	padding-left: 3px;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div {
	height: 40px;
	line-height: 30px;
	display: inline-block;
	box-sizing: border-box;
	border-right: none;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:not(:first-child) {
	padding: 3px;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div .placeholder-input {
	position: relative;
	padding: 0 3px;
	line-height: 24px;
	opacity: 0.8;
}
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-value-label
	select,
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-value-label
	.placeholder-input,
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-option-label
	select,
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-option-label
	.placeholder-input {
	margin: 3px;
	height: 24px;
	width: calc(100% - 10px);
	padding-left: 3px;
}
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-value-label
	select,
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-option-label
	select {
	padding: 0 3px;
	font-size: 11px;
}
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-value-label
	.placeholder-input
	span:first-child,
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-option-label
	.placeholder-input
	span:first-child {
	display: inline-block;
	height: 100%;
	width: 20px;
	text-align: center;
	border-right: 1px solid #dddddd;
	background: #f7f7f7;
}
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-value-label
	input[type='checkbox'],
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div
	.red-ui-typedInput-option-label
	input[type='checkbox'] {
	margin-left: 8px;
	margin-top: 0;
	height: 100%;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:nth-child(1) {
	border: none;
	padding: 2px;
}
.red-ui-editor-subflow-env-ui-row
	> div:last-child
	> div:nth-child(1)
	.red-ui-editableList-item-handle {
	position: relative;
	top: 0px;
	color: #aaa;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:nth-child(2) {
	margin: 4px;
	height: 32px;
	border: 1px dashed #dddddd;
	text-align: center;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:nth-child(2) a {
	display: block;
	width: 100%;
	height: 100%;
	line-height: 32px;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:nth-child(2) a:hover {
	background: #e6e6e6;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:nth-child(2) a i {
	height: 100%;
	vertical-align: middle;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:nth-child(3) {
	position: relative;
}
.red-ui-editor-subflow-env-ui-row > div:last-child > div:nth-child(3) input {
	width: 100%;
}

span.red-ui-editor-subflow-env-lang-icon {
	position: absolute;
	display: inline-block;
	background: #fff;
	opacity: 0.8;
	width: 20px;
	line-height: 32px;
	height: 32px;
	text-align: center;
	top: 4px;
	right: 4px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}

.red-ui-editor-subflow-env-input-type {
	background: #fff;
	height: 100%;
	box-sizing: border-box;
}

.red-ui-editor-subflow-env-input-type-placeholder {
	color: #aaa;
	padding-left: 4px;
}

.red-ui-editor-subflow-ui-edit-panel {
	padding-bottom: 3px;
	background: #f3f3f3;
}
.red-ui-editor-subflow-ui-edit-panel .red-ui-editableList-border {
	border: none;
	border-radius: 0;
	border-bottom: 1px solid #dddddd;
}
.red-ui-editor-subflow-ui-edit-panel .red-ui-editableList-addButton {
	margin-left: 2px;
}
.red-ui-editor-subflow-ui-edit-panel .red-ui-editableList-header {
	background: #f3f3f3;
	display: grid;
	grid-template-columns: 50% 50%;
	color: #888;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-header
	div:first-child {
	padding-left: 23px;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-header
	div:last-child {
	padding-left: 3px;
}
.red-ui-editor-subflow-ui-edit-panel .red-ui-editableList-container {
	padding: 0 1px;
}
.red-ui-editor-subflow-ui-edit-panel .red-ui-editableList-container li {
	background: #fff;
	padding: 0;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	.red-ui-editableList-item-content {
	display: grid;
	grid-template-columns: 50% 50%;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	.red-ui-editableList-item-content
	> div {
	position: relative;
}
.red-ui-editor-subflow-ui-edit-panel .red-ui-editableList-container li input {
	margin-bottom: 0;
	border: none;
	width: 100%;
	border-right: 1px solid #dddddd;
	border-radius: 0;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	input:focus {
	box-shadow: 0 0 0 1px inset rgba(85, 150, 230, 0.8);
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	input:first-child {
	border-left: 1px solid #dddddd;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	button.red-ui-typedInput-type-select,
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	button.red-ui-typedInput-option-expand,
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	button.red-ui-typedInput-option-trigger {
	border-radius: 0;
	height: 34px;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	.red-ui-typedInput-container {
	border-radius: 0;
	border: none;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	.red-ui-typedInput-container
	input.red-ui-typedInput-input {
	height: 34px;
	border-right: none;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	.red-ui-editor-subflow-env-lang-icon {
	top: 1px;
	right: 1px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.red-ui-editor-subflow-ui-edit-panel
	.red-ui-editableList-container
	li
	.red-ui-editableList-item-remove {
	right: 3px;
}

.node-input-env-locales-row {
	position: relative;
	top: -20px;
	float: right;
}
.node-input-env-locales-row select {
	padding: 2px;
	width: 160px;
	height: auto;
	min-width: 20px;
	line-height: 18px;
	font-size: 10px;
}

.node-input-env-container-row {
	min-width: 470px;
	position: relative;
}
.node-input-env-container-row .red-ui-editableList-item-content label {
	margin-bottom: 0;
	line-height: 32px;
}
.node-input-env-container-row .red-ui-editableList-item-content label span {
	display: inline-block;
	width: 20px;
	text-align: center;
}
.node-input-env-container-row
	.red-ui-editableList-item-content
	> div:first-child {
	display: grid;
	padding-left: 5px;
	grid-template-columns: 40% auto 37px;
}
.node-input-env-container-row
	.red-ui-editableList-item-content
	> div:first-child
	> :first-child {
	width: calc(100% - 5px);
}
.node-input-env-container-row
	.red-ui-editableList-item-content
	> div:first-child
	input {
	width: calc(100% - 5px);
}
.node-input-env-container-row
	.red-ui-editableList-item-content.red-ui-editor-subflow-env-editable
	> div:first-child {
	padding-left: 0;
	grid-template-columns: 24px 40% auto 37px;
}
.node-input-env-container-row
	.red-ui-editableList-item-content.red-ui-editor-subflow-env-editable
	> div:first-child
	> a:first-child {
	text-align: center;
	line-height: 32px;
}
.node-input-env-container-row
	.red-ui-editableList-item-content.red-ui-editor-subflow-env-editable
	> div:first-child
	> a:first-child
	i.fa-angle-right {
	transition: all 0.2s linear;
}
.node-input-env-container-row
	.red-ui-editableList-item-content.red-ui-editor-subflow-env-editable
	> div:first-child
	> a:first-child.expanded
	i.fa-angle-right {
	transform: rotate(90deg);
}
.node-input-env-container-row
	.red-ui-editableList-border
	.red-ui-editableList-header {
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background: #f7f7f7;
	padding: 0;
}
.node-input-env-container-row
	.red-ui-editableList-border
	.red-ui-editableList-header
	> div {
	display: grid;
	grid-template-columns: 24px 40% auto 37px;
}
.node-input-env-container-row
	.red-ui-editableList-border
	.red-ui-editableList-header
	> div
	> div {
	display: inline-block;
}
.node-input-env-container-row .red-ui-editableList-container {
	padding: 0;
}
.node-input-env-container-row
	.red-ui-editableList-container
	.red-ui-editableList-item-handle {
	top: 25px;
}
.node-input-env-container-row
	.red-ui-editableList-container
	.red-ui-editableList-item-remove {
	top: 25px;
	right: 5px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-clipboard-import-error pre {
	margin: 10px 0;
	border: none;
	color: #555;
}
.red-ui-clipboard-import-error pre span {
	padding: 5px 0;
}
.red-ui-clipboard-import-error pre span.error {
	padding: 5px;
	border: 1px solid #d6615f;
	margin: 0 1px;
}

#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-treeList-container,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-editableList-border {
	border: none;
	border-radius: 0;
}

#red-ui-clipboard-dialog-export-tab-clipboard-json {
	padding: 10px 10px 0;
}

#red-ui-clipboard-dialog-import-tab-clipboard {
	padding: 10px;
}

.red-ui-clipboard-dialog-export-tab-clipboard-tab {
	position: absolute;
	top: 40px;
	right: 0;
	left: 0;
	bottom: 0;
}

.red-ui-clipboard-dialog-tab-clipboard textarea {
	color: #666 !important;
	resize: none;
	width: 100%;
	border-radius: 4px;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace !important;
	font-size: 13px !important;
	height: 100%;
	line-height: 1.3em;
	padding: 6px 10px;
	background: #f3e7e7;
}

.red-ui-clipboard-dialog-tabs {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 120px;
}

.red-ui-clipboard-dialog-tabs-content {
	position: absolute;
	top: 0;
	left: 120px;
	right: 0;
	bottom: 0;
	padding: 0;
	background: #fff;
}
.red-ui-clipboard-dialog-tabs-content > div {
	height: 100%;
	box-sizing: border-box;
}

.red-ui-clipboard-dialog-box {
	height: 400px;
	position: relative;
	border: 1px solid #bbbbbb;
}

#red-ui-clipboard-dialog-export-tab-library-filename {
	height: auto !important;
	margin-left: 10px;
}

#red-ui-clipboard-dialog form {
	margin-bottom: 0;
}
#red-ui-clipboard-dialog .form-row:last-child {
	margin-bottom: 0;
}

#red-ui-clipboard-dialog-tab-library-name {
	width: calc(100% - 120px);
}

.red-ui-clipboard-dialog-tabs-content
	> div.red-ui-clipboard-dialog-export-tab-library-browser {
	height: calc(100% - 60px);
	margin-bottom: 13px;
	border-bottom: 1px solid #bbbbbb;
	box-sizing: border-box;
}

#red-ui-clipboard-dialog-import-tab-library-browser {
	height: 100%;
	box-sizing: border-box;
}

.red-ui-library-browser {
	position: relative;
	height: 100%;
}
.red-ui-library-browser .red-ui-treeList-container {
	background: #fff;
	border: none;
	border-radius: 0;
}
.red-ui-library-browser .red-ui-treeList-container li {
	background: none;
}
.red-ui-library-browser .red-ui-treeList-container label {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.red-ui-library-browser .red-ui-editableList-border {
	border-radius: 0;
}
.red-ui-library-browser .red-ui-treeList-label input.red-ui-treeList-input {
	border-radius: 2px;
	margin-top: -6px;
	margin-bottom: -6px;
}

#red-ui-library-dialog-save-browser {
	height: calc(100% - 60px);
	border: 1px solid #bbbbbb;
	margin-bottom: 10px;
}

#red-ui-library-dialog-load-panes {
	border: 1px solid #bbbbbb;
}

#red-ui-library-dialog-load-preview {
	height: 100%;
}

#red-ui-library-dialog-load-preview-text {
	box-sizing: border-box;
}

#red-ui-library-dialog-load-preview-details {
	box-sizing: border-box;
}
#red-ui-library-dialog-load-preview-details .red-ui-help-info-row:first-child {
	border-top: none;
}

.red-ui-clipboard-dialog-import-conflicts-list-container {
	min-height: 300px;
	position: relative;
}
.red-ui-clipboard-dialog-import-conflicts-item-header {
	background: #f7f7f7;
}
.red-ui-clipboard-dialog-import-conflicts-item-header > span:first-child {
	color: #444;
	padding-left: 4px;
	font-size: 12px;
}

.red-ui-clipboard-dialog-import-conflicts-controls {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0px;
	text-align: center;
	color: #555;
}
.form-row .red-ui-clipboard-dialog-import-conflicts-controls label {
	padding: 2px 0;
	line-height: 23px;
	margin-bottom: 0;
	width: 80px;
	display: inline-block;
	position: relative;
	height: 100%;
	width: 80px;
	text-align: center;
	border-left: 1px solid #dddddd;
}
.red-ui-clipboard-dialog-import-conflicts-controls input[type='checkbox'] {
	display: inline-block;
	width: auto;
	margin: 0;
}

#red-ui-clipboard-dialog-import-conflicts-list
	.disabled
	.red-ui-info-outline-item {
	opacity: 0.4;
}

.form-row label.red-ui-clipboard-dialog-import-conflicts-gutter {
	box-sizing: border-box;
	width: 22px;
	text-align: center;
}
.red-ui-editor-dialog
	.form-row
	label.red-ui-clipboard-dialog-import-conflicts-gutter
	input[type='checkbox'] {
	width: auto;
	padding: 0;
	margin: 0;
}

#red-ui-settings-tab-library-manager {
	box-sizing: border-box;
	padding: 10px;
	position: relative;
	height: 100%;
}
#red-ui-settings-tab-library-manager li {
	padding: 0;
}

.red-ui-settings-tab-library-entry {
	display: flex;
	flex-direction: row;
}
.red-ui-settings-tab-library-entry span:not(:nth-child(2)) {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.red-ui-settings-tab-library-entry span {
	padding: 8px 0;
}
.red-ui-settings-tab-library-entry span:first-child {
	display: inline-block;
	width: 20px;
	padding-right: 8px;
	text-align: center;
	flex-grow: 0;
}
.red-ui-settings-tab-library-entry span:nth-child(2) {
	flex-grow: 1;
}
.red-ui-settings-tab-library-entry span:nth-child(3),
.red-ui-settings-tab-library-entry span:nth-child(4) {
	flex-grow: 0;
	padding-right: 5px;
	color: #888;
	font-size: 0.9em;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-search {
	z-index: 1000;
	display: none;
	position: absolute;
	width: 500px;
	left: 50%;
	margin-left: -250px;
	top: 0px;
	border: 1px solid #bbbbbb;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	background: #fff;
}
.red-ui-search .red-ui-searchBox-container {
	display: inline-block;
	margin-right: 6px;
	width: 100%;
}
.red-ui-search:not(.red-ui-type-search) .red-ui-searchBox-container {
	width: calc(100% - 30px);
}

.red-ui-type-search {
	box-shadow: 0 1px 6px -3px black;
	background: none;
	width: 300px;
	margin-left: 0px;
	border: none;
}
.red-ui-type-search .red-ui-search-container {
	border-top-left-radius: 5px;
	border-top-right-radius: 5px;
	border: 1px dashed #bbbbbb;
	border-bottom: none;
	padding: 0;
	width: 100%;
}
.red-ui-type-search .red-ui-search-results-container {
	display: none;
	height: 150px;
}
.red-ui-type-search
	.red-ui-search-results-container
	.red-ui-editableList-container {
	border: 1px dashed #bbbbbb;
	border-top: 1px solid #dddddd;
}
.red-ui-type-search .red-ui-search-result {
	padding: 2px 2px 2px 5px;
	font-size: 13px;
	border-left-width: 3px;
	border-right-width: 3px;
}
.red-ui-type-search .red-ui-search-result .red-ui-palette-icon-fa {
	position: relative;
	top: -2.5px;
	left: 0px;
}
.red-ui-type-search .red-ui-search-result-separator {
	border-bottom: 3px solid #dddddd;
}
.red-ui-type-search .red-ui-search-result-node {
	position: relative;
	width: 18px;
	height: 15px;
	margin-top: 1px;
}
.red-ui-type-search .red-ui-search-result-node-port {
	position: absolute;
	border-radius: 2px;
	border: 1px solid #999;
	width: 6px;
	height: 7px;
	top: 4px;
	left: -4px;
	background: #d9d9d9;
	box-sizing: border-box;
}
.red-ui-type-search .red-ui-search-result-node-output {
	left: 16px;
}
.red-ui-type-search .red-ui-palette-icon-container {
	width: 18px;
}
.red-ui-type-search .red-ui-palette-icon {
	width: 15px;
	position: relative;
	left: -1px;
}
.red-ui-type-search .red-ui-search-result-description {
	margin-left: 8px;
}
.red-ui-type-search .red-ui-search-result-node-label {
	color: #888;
}

.red-ui-search-container {
	padding: 3px;
	background: #fff;
	border-bottom: 1px solid #dddddd;
}

.red-ui-search-results-container {
	position: relative;
	height: 300px;
	padding: 5px;
	background: #f3f3f3;
}
.red-ui-search-results-container .red-ui-editableList-container {
	padding: 0;
	background: #f3f3f3;
}
.red-ui-search-results-container .red-ui-editableList-container li {
	padding: 0;
}

.red-ui-search-result {
	padding: 8px 2px 8px 5px;
	display: flex;
	align-items: start;
	cursor: pointer;
	color: #555;
	background: #fff;
	border-left: 3px solid #fff;
	border-right: 3px solid #fff;
}
li.selected .red-ui-search-result {
	background: #ffebc7;
	border-left-color: #666;
	border-right-color: #666;
}
.red-ui-search-result:hover {
	text-decoration: none;
	color: #555;
	background: #e6e6e6;
	border-left-color: #e6e6e6;
	border-right-color: #e6e6e6;
}
.red-ui-search-result:after {
	content: '';
	display: table;
	clear: both;
}

.red-ui-search-result-node {
	display: inline-block;
	width: 30px;
	float: left;
	height: 25px;
	border-radius: 3px;
	border: 1px solid #999;
	background-position: 5% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;
}
.red-ui-search-result-node .red-ui-palette-icon-container {
	border-right: none;
}

.red-ui-search-result-node-description {
	margin-left: 10px;
	margin-right: 5px;
	flex-grow: 1;
}

.red-ui-search-result-node-label {
	color: #555;
}

.red-ui-search-result-node-type {
	font-style: italic;
	font-size: 0.9em;
	color: #aaa;
}

.red-ui-search-result-node-flow {
	float: right;
	font-size: 0.8em;
	color: #aaa;
}

.red-ui-search-result-node-id {
	display: none;
	font-size: 0.8em;
	color: #aaa;
}

.red-ui-search-empty {
	padding: 10px;
	text-align: center;
	font-style: italic;
	color: #aaa;
}

.red-ui-search-result-action {
	color: #555;
}

.red-ui-search-result-action-key {
	position: absolute;
	top: 9px;
	right: 0;
	margin-right: 10px;
	color: #aaa;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-panels {
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
}
.red-ui-panels > div {
	box-sizing: border-box;
}
.red-ui-panels > .red-ui-panel:first-child {
	flex: 0 0 auto;
}
.red-ui-panels > .red-ui-panel:last-child {
	flex: 1 1 auto;
}

.red-ui-panels-separator {
	flex: 0 0 auto;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
	height: 7px;
	box-sizing: border-box;
	cursor: ns-resize;
	background: #f3f3f3 url(../../assets/red/grip-horizontal.png) no-repeat 50%
		50%;
}

.red-ui-panel {
	overflow: auto;
	height: calc(50% - 4px);
	position: relative;
}

.red-ui-panels.red-ui-panels-horizontal {
	height: 100%;
	flex-direction: row;
}
.red-ui-panels.red-ui-panels-horizontal > .red-ui-panel {
	vertical-align: top;
	display: inline-block;
	height: 100%;
	width: calc(50% - 4px);
}
.red-ui-panels.red-ui-panels-horizontal > .red-ui-panels-separator {
	vertical-align: top;
	border-top: none;
	border-bottom: none;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	height: 100%;
	width: 7px;
	display: inline-block;
	cursor: ew-resize;
	background: #f3f3f3 url(../../assets/red/grip.png) no-repeat 50% 50%;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-tabs {
	position: relative;
	background: #fff;
	overflow: hidden;
	height: 35px;
	box-sizing: border-box;
}
.red-ui-tabs .red-ui-tabs-scroll-container {
	height: 60px;
	overflow-x: scroll;
	overflow-y: hidden;
}
.red-ui-tabs .red-ui-tabs-scroll-container::-webkit-scrollbar {
	display: none;
}
.red-ui-tabs ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
	display: block;
	height: 35px;
	box-sizing: border-box;
	border-bottom: 1px solid #bbbbbb;
	white-space: nowrap;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.red-ui-tabs ul li {
	box-sizing: border-box;
	display: inline-block;
	border-left: 1px solid #bbbbbb;
	border-top: 1px solid #bbbbbb;
	border-right: 1px solid #bbbbbb;
	border-bottom: 1px solid #bbbbbb;
	background: #f0f0f0;
	margin: 3px 3px 0 3px;
	height: 32px;
	line-height: 29px;
	max-width: 200px;
	width: 14%;
	overflow: hidden;
	white-space: nowrap;
	position: relative;
}
.red-ui-tabs ul li.red-ui-tabs-closeable:hover .red-ui-tabs-badges {
	display: none;
}
.red-ui-tabs ul li.red-ui-tabs-closeable:hover .red-ui-tab-close {
	display: block;
}
.red-ui-tabs ul li a.red-ui-tab-label {
	display: block;
	font-size: 14px;
	padding-left: 12px;
	width: 100%;
	height: 100%;
	color: #666;
}
.red-ui-tabs ul li a:hover {
	text-decoration: none;
}
.red-ui-tabs ul li a:focus {
	text-decoration: none;
}
.red-ui-tabs ul li:not(.active) a:hover + a.red-ui-tab-close {
	background: #e6e6e6;
}
.red-ui-tabs ul li.active {
	background: #fff;
	font-weight: bold;
	border-bottom: 1px solid #fff;
	z-index: 2;
}
.red-ui-tabs ul li.active a {
	color: #444;
}
.red-ui-tabs ul li.active a.red-ui-tab-close {
	color: #888;
	background: #fff;
}
.red-ui-tabs ul li.active a.red-ui-tab-close:hover {
	background: #e6e6e6 !important;
	color: #666;
}
.red-ui-tabs ul li.active img.red-ui-tab-icon {
	opacity: 0.2;
}
.red-ui-tabs ul li.active .red-ui-tabs-fade {
	background-image: linear-gradient(
		to right,
		rgba(255, 255, 255, 0.001),
		#444
	);
}
.red-ui-tabs ul li.selected {
	font-weight: bold;
}
.red-ui-tabs ul li.selected:not(.active) {
	background: #efefef;
}
.red-ui-tabs ul li.selected:not(.active) .red-ui-tabs-fade {
	background-image: linear-gradient(
		to right,
		rgba(239, 239, 239, 0.001),
		#4c4c4c
	);
}
.red-ui-tabs ul li.selected:not(.active) .red-ui-tabs-badge-selected {
	background: #efefef;
}
.red-ui-tabs ul li.selected .red-ui-tabs-badge-selected {
	display: inline;
	background: #fff;
}
.red-ui-tabs ul li.selected .red-ui-tabs-badge-changed {
	display: none;
}
.red-ui-tabs ul li:not(.active) a:hover {
	color: #666;
	background: #e6e6e6;
}
.red-ui-tabs ul li:not(.active) a:hover + .red-ui-tabs-fade {
	background-image: linear-gradient(
		to right,
		rgba(230, 230, 230, 0.001),
		#444
	);
}
.red-ui-tabs.red-ui-tabs-scrollable {
	padding-left: 21px;
	padding-right: 21px;
}
.red-ui-tabs.red-ui-tabs-add {
	padding-right: 35px;
}
.red-ui-tabs.red-ui-tabs-add.red-ui-tabs-scrollable {
	padding-right: 59px;
}
.red-ui-tabs.red-ui-tabs-add.red-ui-tabs-search.red-ui-tabs-scrollable {
	padding-right: 95px;
}
.red-ui-tabs.red-ui-tabs-collapsible li:not(.active) {
	display: none;
}
.red-ui-tabs.red-ui-tabs-collapsible li:not(.active).red-ui-tab-pinned {
	width: 32px;
}
.red-ui-tabs.red-ui-tabs-collapsible li:not(.active).red-ui-tab-pinned a {
	padding-left: 0;
	text-align: center;
}
.red-ui-tabs.red-ui-tabs-collapsible li:not(.active).red-ui-tab-pinned span {
	display: none;
}
.red-ui-tabs.red-ui-tabs-vertical {
	box-sizing: border-box;
	height: 100%;
	border-right: 1px solid #bbbbbb;
	margin: 0;
	background: #f7f7f7;
	overflow: visible;
}
.red-ui-tabs.red-ui-tabs-vertical .red-ui-tabs-scroll-container {
	height: auto;
	overflow-x: visible;
	overflow-y: scroll;
}
.red-ui-tabs.red-ui-tabs-vertical ul {
	padding: 0;
	height: auto;
	border: none;
	width: calc(100% + 1px);
}
.red-ui-tabs.red-ui-tabs-vertical ul li {
	width: 100%;
	display: block;
	margin: 0;
	border: none;
	border-right: 1px solid #bbbbbb;
	height: auto;
}
.red-ui-tabs.red-ui-tabs-vertical ul li:not(:first-child) {
	border-top: 1px solid #dddddd;
}
.red-ui-tabs.red-ui-tabs-vertical ul li:last-child {
	border-bottom: 1px solid #dddddd;
}
.red-ui-tabs.red-ui-tabs-vertical ul li a.red-ui-tab-label {
	padding: 9px;
}
.red-ui-tabs.red-ui-tabs-vertical ul li.active {
	border-right: 1px solid #fff;
}
.red-ui-tabs .red-ui-tabs-select {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	opacity: 0.4;
	background: red;
}

.red-ui-tab-button {
	position: absolute;
	box-sizing: border-box;
	top: 0;
	right: 0;
	height: 35px;
	background: #fff;
	border-bottom: 1px solid #bbbbbb;
	z-index: 2;
}
.red-ui-tab-button a {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	line-height: 32px;
	height: 32px;
	width: 32px;
	margin-top: 3px;
	margin-right: 3px;
	margin-left: 3px;
	border: 1px solid #bbbbbb;
	z-index: 2;
}
.red-ui-tab-button a.disabled,
.red-ui-tab-button a:disabled {
	cursor: default;
	color: #bbb !important;
}
.red-ui-tab-button a:hover,
.red-ui-tab-button a:focus {
	text-decoration: none;
}
.red-ui-tab-button a:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
.red-ui-tab-button a:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
.red-ui-tab-button a:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
.red-ui-tab-button a.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group .red-ui-tab-button a:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group .red-ui-tab-button a:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical .red-ui-tab-button a {
	display: block;
	min-width: 22px;
}
.button-group-vertical .red-ui-tab-button a:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical .red-ui-tab-button a:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group .red-ui-tab-button a:focus {
	position: relative;
}
.button-row .red-ui-tab-button a:not(:first-child) {
	margin-left: 15px;
}
.red-ui-tab-button a:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
.red-ui-tab-button a.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.red-ui-tab-button a.primary.disabled,
.red-ui-tab-button a.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
.red-ui-tab-button a.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.red-ui-tab-button a.secondary {
	background: none;
}
.red-ui-tab-button a.secondary:not(:hover) {
	border-color: transparent;
}

.red-ui-tab-link-buttons {
	position: absolute;
	box-sizing: border-box;
	top: 0;
	right: 0;
	height: 35px;
	background: #fff;
	border-bottom: 1px solid #bbbbbb;
	z-index: 2;
}
.red-ui-tab-link-buttons a {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	color: #888 !important;
	background: #fff;
	text-decoration: none;
	box-sizing: border-box;
	display: inline-block;
	border: 1px solid #ccc;
	text-align: center;
	margin: 0;
	cursor: pointer;
	line-height: 26px;
	height: 28px;
	width: 28px;
	margin: 4px 3px 3px;
	z-index: 2;
}
.red-ui-tab-link-buttons a.disabled,
.red-ui-tab-link-buttons a:disabled {
	cursor: default;
	color: #bbb !important;
}
.red-ui-tab-link-buttons a:hover,
.red-ui-tab-link-buttons a:focus {
	text-decoration: none;
}
.red-ui-tab-link-buttons a:not(.disabled):not(:disabled):hover {
	color: #666 !important;
	background: #e6e6e6;
}
.red-ui-tab-link-buttons a:not(.disabled):not(:disabled):focus {
	color: #666 !important;
}
.red-ui-tab-link-buttons a:not(.disabled):not(:disabled):active {
	color: #666 !important;
	background: #efefef;
	text-decoration: none;
}
.red-ui-tab-link-buttons a.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
	background: #efefef;
}
.button-group .red-ui-tab-link-buttons a:not(:first-child) {
	border-left: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.button-group .red-ui-tab-link-buttons a:not(:last-child) {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group-vertical .red-ui-tab-link-buttons a {
	display: block;
	min-width: 22px;
}
.button-group-vertical .red-ui-tab-link-buttons a:not(:first-child) {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}
.button-group-vertical .red-ui-tab-link-buttons a:not(:last-child) {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}
.button-group .red-ui-tab-link-buttons a:focus {
	position: relative;
}
.button-row .red-ui-tab-link-buttons a:not(:first-child) {
	margin-left: 15px;
}
.red-ui-tab-link-buttons a:focus {
	outline: 1px solid rgba(85, 150, 230, 0.8);
	outline-offset: 1px;
}
.red-ui-tab-link-buttons a.primary {
	border-color: #ad1625;
	color: #eee !important;
	background: #ad1625;
}
.red-ui-tab-link-buttons a.primary.disabled,
.red-ui-tab-link-buttons a.primary.ui-state-disabled {
	background: none;
	color: #888 !important;
	border-color: #ccc;
}
.red-ui-tab-link-buttons
	a.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #6e0a1e;
	background: #6e0a1e;
	color: #eee !important;
}
.red-ui-tab-link-buttons a.secondary {
	background: none;
}
.red-ui-tab-link-buttons a.secondary:not(:hover) {
	border-color: transparent;
}
.red-ui-tab-link-buttons a:not(.single) {
	margin-bottom: 1px;
}
.red-ui-tab-link-buttons a:not(.single).selected:not(.disabled):not(:disabled) {
	border-bottom-width: 2px;
	border-bottom-color: #aaa;
	margin-bottom: 0;
	cursor: default;
}
.red-ui-tab-link-buttons a:not(.single):not(.selected) {
	margin-top: 1px;
}
.red-ui-tab-link-buttons a.red-ui-tab-link-button-menu {
	border-color: #fff;
}
.red-ui-tab-link-buttons a:not(.single):not(.selected) {
	margin-top: 4px;
}

.red-ui-tab-scroll {
	width: 21px;
	top: 0;
}
.red-ui-tab-scroll a {
	height: 35px;
	width: 21px;
	display: block;
	color: #888;
	font-size: 22px;
	text-align: center;
	margin: 0;
	border-left: none;
	border-right: none;
	border-top: none;
}

.red-ui-tab-scroll-left {
	left: 0;
}
.red-ui-tab-scroll-left a {
	border-right: 1px solid #bbbbbb;
}

.red-ui-tab-scroll-right {
	right: 0px;
}
.red-ui-tab-scroll-right a {
	border-left: 1px solid #bbbbbb;
}

.red-ui-tabs.red-ui-tabs-add .red-ui-tab-scroll-right {
	right: 38px;
}

.red-ui-tabs.red-ui-tabs-add.red-ui-tabs-search .red-ui-tab-scroll-right {
	right: 76px;
}

.red-ui-tabs.red-ui-tabs-add.red-ui-tabs-search .red-ui-tabs-add {
	right: 38px;
}

.red-ui-tabs-fade {
	position: absolute;
	bottom: 0;
	top: 0;
	right: 0;
	width: 15px;
	background-image: linear-gradient(
		to right,
		rgba(240, 240, 240, 0.001),
		#3e3e3e
	);
	pointer-events: none;
}

img.red-ui-tab-icon {
	margin-left: -8px;
	margin-right: 3px;
	margin-top: -2px;
	opacity: 0.1;
	width: 20px;
	height: 20px;
	vertical-align: middle;
}

i.red-ui-tab-icon {
	opacity: 0.7;
	width: 18px;
	height: 20px;
}

.red-ui-tabs-badges {
	position: absolute;
	top: 0px;
	right: 0px;
	width: 20px;
	pointer-events: none;
	display: block;
	height: 30px;
	line-height: 28px;
	text-align: center;
	padding: 0px;
	color: #aaa;
}

.red-ui-tabs-badges i {
	display: none;
}

.red-ui-tab.node_changed img.node_changed {
	display: inline-block;
}

.red-ui-tab.node_error img.node_error {
	display: inline-block;
}

.red-ui-tabs-badges img {
	width: 10px;
	height: 10px;
	margin-right: 2px;
	vertical-align: top;
}

.red-ui-tab-close {
	display: none;
	background: #f0f0f0;
	opacity: 0.8;
	position: absolute;
	right: 0px;
	top: 0px;
	width: 20px;
	height: 30px;
	line-height: 28px;
	text-align: center;
	padding: 0px;
	color: #888;
}
.red-ui-tab-close:hover {
	background: #e6e6e6 !important;
	color: #666;
	opacity: 1;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-sidebar-node-config {
	position: relative;
	background: #fff;
	height: 100%;
	overflow-y: auto;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.red-ui-sidebar-node-config:focus {
	outline: none;
}

ul.red-ui-sidebar-node-config-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
}
ul.red-ui-sidebar-node-config-list li {
	padding: 0;
	margin: 0;
	text-align: center;
}
ul.red-ui-sidebar-node-config-list .red-ui-palette-node {
	overflow: hidden;
}
ul.red-ui-sidebar-node-config-list .red-ui-palette-node.selected {
	border-color: transparent;
	box-shadow: 0 0 0 2px #ff7f0e;
}
ul.red-ui-sidebar-node-config-list .red-ui-palette-label {
	margin-left: 8px;
	line-height: 24px;
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
ul.red-ui-sidebar-node-config-list .red-ui-palette-icon-container {
	font-size: 12px;
	line-height: 30px;
	background-color: #efefef;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
ul.red-ui-sidebar-node-config-list .red-ui-palette-icon-container a {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	color: #888;
}
ul.red-ui-sidebar-node-config-list .red-ui-palette-icon-container a:hover {
	text-decoration: none;
	background: #e6e6e6;
}

.red-ui-palette-node-config {
	width: 160px;
	height: 30px;
	background: #f3f3f3;
	color: #555;
	cursor: pointer;
}

ul.red-ui-sidebar-node-config-list li.red-ui-palette-node-config-type {
	color: #888;
	text-align: right;
	padding-right: 3px;
}
ul.red-ui-sidebar-node-config-list
	li.red-ui-palette-node-config-type:not(:first-child) {
	margin-top: 20px;
}

.red-ui-palette-node-config-none {
	color: #aaa;
	text-align: right;
	padding-right: 3px;
}

.red-ui-palette-node-config-unused,
.red-ui-palette-node-config-disabled {
	border-color: #bbbbbb;
	background: #f0f0f0;
	border-style: dashed;
	color: #aaa;
}

.red-ui-palette-node-config-disabled {
	opacity: 0.6;
	font-style: italic;
}
.red-ui-palette-node-config-disabled i {
	color: #888;
	margin-right: 5px;
}

.red-ui-sidebar-node-config-filter-info {
	position: absolute;
	top: 0;
	right: 0;
	height: 38px;
	line-height: 38px;
	padding: 0 8px;
	background: #f3f3f3;
	font-size: 0.8em;
	color: #888;
	font-weight: normal;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-sidebar-context-stack {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	overflow-y: scroll;
}
.red-ui-sidebar-context-stack .red-ui-palette-category:not(.expanded) button {
	display: none;
}
.red-ui-sidebar-context-stack .red-ui-info-table {
	table-layout: fixed;
}
.red-ui-sidebar-context-stack
	table.red-ui-info-table
	tr:not(.blank)
	td:first-child {
	width: 30%;
}
.red-ui-sidebar-context-stack
	table.red-ui-info-table
	tr:not(.blank)
	td:last-child {
	vertical-align: top;
}
.red-ui-sidebar-context-stack button.red-ui-toggleButton.toggle {
	text-align: center;
}
.red-ui-sidebar-context-stack button.red-ui-toggleButton.toggle i {
	min-width: 10px;
}

.red-ui-sidebar-context-property {
	overflow-wrap: break-word;
	position: relative;
}
.red-ui-sidebar-context-property .red-ui-debug-msg-tools {
	right: 0px;
	margin-right: 5px;
	display: none;
}
.red-ui-sidebar-context-property:hover .red-ui-debug-msg-tools {
	display: inline-block;
}

.red-ui-sidebar-context-updated {
	text-align: right;
	font-size: 11px;
	color: #aaa;
	padding: 1px 3px;
}

.red-ui-sidebar-context-property-storename {
	display: block;
	font-size: 0.8em;
	font-style: italic;
	color: #aaa;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-sidebar-info {
	height: 100%;
}

.red-ui-sidebar-info hr {
	margin: 10px 0;
}

.red-ui-info-header {
	padding-left: 9px;
	line-height: 21px;
	cursor: default;
	border-bottom: 1px solid #dddddd;
}
.red-ui-info-header > * {
	vertical-align: middle;
}
.red-ui-info-header > span {
	display: inline-block;
	margin-left: 5px;
}

table.red-ui-info-table {
	font-size: 14px;
	margin: 0 0 10px;
	width: 100%;
}

table.red-ui-info-table tr:not(.blank) {
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
}

.red-ui-help-property-expand {
	font-size: 0.8em;
	text-align: right;
	line-height: 0.9em;
}
.red-ui-help-property-expand a {
	padding-bottom: 5px;
}

table.red-ui-info-table tr.blank {
	border: none;
}
table.red-ui-info-table tr.blank th {
	text-align: left;
	font-weight: 500;
	color: #555;
	padding: 6px 3px 3px;
}
table.red-ui-info-table tr.blank > * {
	padding-top: 8px;
	border: none;
	padding-left: 0px;
}
table.red-ui-info-table tr.blank a {
	display: block;
	color: #555;
}
table.red-ui-info-table tr.blank a:hover,
table.red-ui-info-table tr.blank a:focus {
	color: #555;
	text-decoration: none;
}
table.red-ui-info-table tr.blank a:not(.expanded) .red-ui-help-property-more {
	display: inline;
}
table.red-ui-info-table tr.blank a:not(.expanded) .red-ui-help-property-less {
	display: none;
}
table.red-ui-info-table tr.blank a i {
	width: 10px;
	text-align: center;
	transition: transform 0.2s ease-in-out;
}
table.red-ui-info-table tr.blank a.expanded .red-ui-help-property-more {
	display: none;
}
table.red-ui-info-table tr.blank a.expanded .red-ui-help-property-less {
	display: inline;
}
table.red-ui-info-table tr.blank a.expanded i {
	transform: rotate(180deg);
}

.red-ui-help-info-none {
	font-style: italic;
	color: #aaa;
}

table.red-ui-info-table tr:not(.blank) td:first-child {
	color: #444;
	vertical-align: top;
	width: 90px;
	padding: 3px 3px 3px 6px;
	background: #f7f7f7;
	border-right: 1px solid #dddddd;
}

table.red-ui-info-table tr:not(.blank) td:last-child {
	padding: 3px 3px 3px 6px;
	color: #555;
	overflow-y: hidden;
}

div.red-ui-info-table {
	margin: 5px;
}

.red-ui-help {
	font-size: 14px;
	line-height: 1.5em;
}
.red-ui-help a {
	color: #0088cc;
	text-decoration: none;
}
.red-ui-help a:hover,
.red-ui-help a:focus {
	color: #0088cc;
	text-decoration: underline;
}
.red-ui-help h1 {
	font-weight: 500;
	font-size: 1.296em;
	line-height: 1.3em;
	margin: 8px auto;
}
.red-ui-help h1.red-ui-help-title {
	border-bottom: 1px solid #ccc;
}
.red-ui-help h2 {
	font-weight: 500;
	font-size: 1.215em;
	margin: 8px auto;
	line-height: 1.3em;
}
.red-ui-help h3 {
	font-weight: 500;
	font-size: 1.138em;
	margin: 7px auto 5px;
	line-height: 1.3em;
}
.red-ui-help h4,
.red-ui-help h5 {
	font-weight: 500;
	font-size: 1.067em;
	line-height: 1.3em;
	margin: 8px auto 5px;
}
.red-ui-help dl.message-properties {
	border: 1px solid #dddddd;
	border-radius: 2px;
	margin: 5px auto 10px;
}
.red-ui-help dl.message-properties > dt {
	padding: 0px 3px 2px 3px;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	font-weight: normal;
	margin: 5px 3px 1px;
	color: #ad1625;
	white-space: nowrap;
}
.red-ui-help dl.message-properties > dt.optional {
	font-style: italic;
}
.red-ui-help dl.message-properties > dt .property-type {
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	color: #555;
	font-style: italic;
	font-size: 11px;
	float: right;
}
.red-ui-help dl.message-properties > dt:after {
	content: '';
	display: table;
	clear: both;
}
.red-ui-help dl.message-properties > dd {
	margin: 0px 8px 2px 13px;
	vertical-align: top;
}
.red-ui-help ol.node-ports {
	margin: 0;
}
.red-ui-help ol.node-ports li {
	border: 1px solid #dddddd;
	border-radius: 2px;
	list-style-position: inside;
	padding: 3px;
	margin-bottom: 5px;
}
.red-ui-help ol.node-ports li dl.message-properties {
	border: none;
}
.red-ui-help .red-ui-help-info-header {
	color: #444;
}
.red-ui-help .red-ui-help-info-header i {
	width: 10px;
	text-align: center;
	transition: transform 0.2s ease-in-out;
	margin-right: 4px;
}
.red-ui-help .red-ui-help-info-header:hover,
.red-ui-help .red-ui-help-info-header:focus {
	text-decoration: none;
}
.red-ui-help .red-ui-help-info-header.expanded i {
	transform: rotate(90deg);
}

.red-ui-sidebar-info-stack {
	height: 100%;
}

.red-ui-help-tips {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	height: 0;
	transition: height 0.2s, padding 0.2s;
	box-sizing: border-box;
	border-top: 1px solid #dddddd;
	background-color: #fff;
	padding: 0;
	box-shadow: 0 5px 20px 0px rgba(0, 0, 0, 0.2);
	overflow-y: auto;
}

.red-ui-sidebar-info.show-tips .red-ui-sidebar-info-stack {
	height: calc(100% - 150px);
}
.red-ui-sidebar-info.show-tips .red-ui-help-tips {
	display: block;
	height: 150px;
	padding: 20px;
}

.red-ui-help-tips:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	margin-right: -0.25em;
	/* Adjusts for spacing */
}

.red-ui-help-tip {
	display: inline-block;
	vertical-align: middle;
	width: 100%;
	font-size: 16px;
	text-align: center;
	line-height: 1.9em;
	color: #aaa;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: default;
}

.red-ui-help-tips-buttons {
	position: absolute;
	top: 4px;
	right: 6px;
}
.red-ui-help-tips-buttons a {
	color: #888;
	border-color: #dddddd !important;
	margin-left: 4px;
}

.node-info-property-config-node {
	border: 1px solid #dddddd;
	border-radius: 4px;
	padding: 2px 4px 2px;
}

.red-ui-info-outline,
.red-ui-sidebar-help-toc {
	display: flex;
	flex-direction: column;
}
.red-ui-info-outline .red-ui-treeList,
.red-ui-sidebar-help-toc .red-ui-treeList {
	flex-grow: 1;
	position: relative;
}
.red-ui-info-outline .red-ui-treeList-container,
.red-ui-sidebar-help-toc .red-ui-treeList-container {
	position: absolute;
	top: 0;
	bottom: 0;
}
.red-ui-info-outline .red-ui-treeList-container,
.red-ui-info-outline .red-ui-editableList-border,
.red-ui-sidebar-help-toc .red-ui-treeList-container,
.red-ui-sidebar-help-toc .red-ui-editableList-border {
	border: none;
	border-radius: 0;
}
.red-ui-info-outline .red-ui-info-outline-project,
.red-ui-sidebar-help-toc .red-ui-info-outline-project {
	border-bottom: 1px solid #dddddd;
}

.red-ui-info-outline .red-ui-info-outline-item,
.red-ui-sidebar-help-toc .red-ui-info-outline-item,
#red-ui-clipboard-dialog-import-conflicts-list .red-ui-info-outline-item,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item {
	display: inline-flex;
	padding: 0;
	font-size: 13px;
	border: none;
}
.red-ui-info-outline
	.red-ui-info-outline-item:not(.red-ui-node-list-item)
	.red-ui-palette-icon-fa,
.red-ui-sidebar-help-toc
	.red-ui-info-outline-item:not(.red-ui-node-list-item)
	.red-ui-palette-icon-fa,
#red-ui-clipboard-dialog-import-conflicts-list
	.red-ui-info-outline-item:not(.red-ui-node-list-item)
	.red-ui-palette-icon-fa,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item:not(.red-ui-node-list-item)
	.red-ui-palette-icon-fa {
	position: relative;
	top: 1px;
	left: 0px;
}
.red-ui-info-outline .red-ui-info-outline-item:hover,
.red-ui-sidebar-help-toc .red-ui-info-outline-item:hover,
#red-ui-clipboard-dialog-import-conflicts-list .red-ui-info-outline-item:hover,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item:hover {
	background: inherit;
}
.red-ui-info-outline
	.red-ui-info-outline-item.red-ui-info-outline-item-flow
	.red-ui-search-result-description,
.red-ui-sidebar-help-toc
	.red-ui-info-outline-item.red-ui-info-outline-item-flow
	.red-ui-search-result-description,
#red-ui-clipboard-dialog-import-conflicts-list
	.red-ui-info-outline-item.red-ui-info-outline-item-flow
	.red-ui-search-result-description,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item.red-ui-info-outline-item-flow
	.red-ui-search-result-description {
	margin-left: 4px;
}
.red-ui-info-outline
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node,
.red-ui-sidebar-help-toc
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node,
#red-ui-clipboard-dialog-import-conflicts-list
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node {
	background: none;
	border-color: transparent;
}
.red-ui-info-outline
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-container,
.red-ui-sidebar-help-toc
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-container,
#red-ui-clipboard-dialog-import-conflicts-list
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-container,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-container {
	background: none;
}
.red-ui-info-outline
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-fa,
.red-ui-sidebar-help-toc
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-fa,
#red-ui-clipboard-dialog-import-conflicts-list
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-fa,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item.red-ui-info-outline-item-group
	.red-ui-search-result-node
	.red-ui-palette-icon-fa {
	color: #888;
	font-size: 18px;
}
.red-ui-info-outline .red-ui-info-outline-item.red-ui-info-outline-item-empty,
.red-ui-sidebar-help-toc
	.red-ui-info-outline-item.red-ui-info-outline-item-empty,
#red-ui-clipboard-dialog-import-conflicts-list
	.red-ui-info-outline-item.red-ui-info-outline-item-empty,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-info-outline-item.red-ui-info-outline-item-empty {
	font-style: italic;
	color: #aaa;
}
.red-ui-info-outline .red-ui-treeList-label,
.red-ui-sidebar-help-toc .red-ui-treeList-label,
#red-ui-clipboard-dialog-import-conflicts-list .red-ui-treeList-label,
#red-ui-clipboard-dialog-export-tab-clipboard-preview .red-ui-treeList-label {
	font-size: 13px;
	padding: 2px 0;
	overflow: hidden;
}
.red-ui-info-outline .red-ui-search-result-node,
.red-ui-sidebar-help-toc .red-ui-search-result-node,
#red-ui-clipboard-dialog-import-conflicts-list .red-ui-search-result-node,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-search-result-node {
	width: 24px;
	height: 20px;
	margin-top: 1px;
}
.red-ui-info-outline .red-ui-palette-icon-container,
.red-ui-sidebar-help-toc .red-ui-palette-icon-container,
#red-ui-clipboard-dialog-import-conflicts-list .red-ui-palette-icon-container,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-palette-icon-container {
	width: 24px;
}
.red-ui-info-outline .red-ui-palette-icon,
.red-ui-sidebar-help-toc .red-ui-palette-icon,
#red-ui-clipboard-dialog-import-conflicts-list .red-ui-palette-icon,
#red-ui-clipboard-dialog-export-tab-clipboard-preview .red-ui-palette-icon {
	width: 20px;
}
.red-ui-info-outline .red-ui-search-result-description,
.red-ui-sidebar-help-toc .red-ui-search-result-description,
#red-ui-clipboard-dialog-import-conflicts-list
	.red-ui-search-result-description,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-search-result-description {
	margin-left: 32px;
	line-height: 22px;
	white-space: nowrap;
}
.red-ui-info-outline .red-ui-search-result-node-label,
.red-ui-sidebar-help-toc .red-ui-search-result-node-label,
#red-ui-clipboard-dialog-import-conflicts-list .red-ui-search-result-node-label,
#red-ui-clipboard-dialog-export-tab-clipboard-preview
	.red-ui-search-result-node-label {
	color: #888;
}

.red-ui-info-outline-item-control-spacer {
	display: inline-block;
	width: 23px;
}

.red-ui-info-outline-gutter {
	display: none;
}
.red-ui-info-outline-gutter button {
	position: relative;
	left: 2px;
}
.red-ui-treeList-label:hover .red-ui-info-outline-gutter {
	display: inline;
}

.red-ui-info-outline-item-controls {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0px;
	padding: 2px 3px 0 1px;
	text-align: right;
	background: #fff;
}
.red-ui-treeList-label:hover .red-ui-info-outline-item-controls {
	background: #e6e6e6;
}
.red-ui-treeList-label.selected .red-ui-info-outline-item-controls {
	background: #ffebc7;
}
.red-ui-info-outline-item-controls.red-ui-info-outline-item-hover-controls
	button {
	min-width: 23px;
}
.red-ui-treeList-label:not(:hover)
	.red-ui-info-outline-item-controls.red-ui-info-outline-item-hover-controls
	button {
	border: none;
	background: none;
}
.red-ui-info-outline-item-controls .red-ui-info-outline-item-control-reveal,
.red-ui-info-outline-item-controls .red-ui-info-outline-item-control-action {
	display: none;
}
.red-ui-treeList-label:hover
	.red-ui-info-outline-item-controls
	.red-ui-info-outline-item-control-reveal,
.red-ui-treeList-label:hover
	.red-ui-info-outline-item-controls
	.red-ui-info-outline-item-control-action {
	display: inline-block;
}
.red-ui-info-outline-item-controls .fa-ban {
	display: none;
}
.red-ui-info-outline-item.red-ui-info-outline-item-disabled
	.red-ui-info-outline-item-controls
	.fa-ban {
	display: inline-block;
}
.red-ui-info-outline-item.red-ui-info-outline-item-disabled
	.red-ui-info-outline-item-controls
	.fa-circle-thin {
	display: none;
}
.red-ui-info-outline-item-controls button {
	margin-right: 3px;
}

.red-ui-info-outline-item-disabled .red-ui-search-result-node {
	opacity: 0.4;
}
.red-ui-info-outline-item-disabled .red-ui-info-outline-item-label {
	font-style: italic;
	color: #bbb;
}
.red-ui-info-outline-item-disabled .red-ui-icons-flow {
	opacity: 0.4;
}

.red-ui-icons {
	display: inline-block;
	width: 18px;
}
.red-ui-icons:before {
	white-space: pre;
	content: ' ';
}

.red-ui-icons-flow {
	background-image: url('../../assets/red/subflow_tab.svg');
	background-repeat: no-repeat;
	background-size: contain;
	filter: brightness(2.5);
}

.red-ui-info-toolbar {
	min-height: 39px;
	height: 39px;
	box-sizing: border-box;
	text-align: left;
}
.red-ui-info-toolbar .red-ui-searchBox-container {
	position: absolute;
	top: 6px;
	right: 8px;
	width: calc(100% - 130px);
	max-width: 250px;
	background: #f3f3f3;
}

.red-ui-help-search {
	border-bottom: 1px solid #dddddd;
}

.red-ui-sidebar-help-toc .red-ui-treeList-label {
	font-size: 13px;
	padding: 2px 0;
	overflow: hidden;
	white-space: nowrap;
}

#red-ui-sidebar-help-show-toc i.fa-angle-right {
	transition: all 0.2s ease-in-out;
}
#red-ui-sidebar-help-show-toc.selected i.fa-angle-right {
	transform: rotate(90deg);
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-popover {
	display: none;
	position: absolute;
	width: auto;
	padding: 10px;
	height: auto;
	background: #333;
	color: #eee;
	border-radius: 4px;
	z-index: 1000;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size: 14px;
	line-height: 1.4em;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	border-color: #333;
}

.red-ui-popover:after,
.red-ui-popover:before {
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.red-ui-popover.red-ui-popover-right:after,
.red-ui-popover.red-ui-popover-right:before {
	top: 50%;
	right: 100%;
}

.red-ui-popover.red-ui-popover-left:after,
.red-ui-popover.red-ui-popover-left:before {
	top: 50%;
	left: 100%;
}

.red-ui-popover.red-ui-popover-bottom:after,
.red-ui-popover.red-ui-popover-bottom:before {
	bottom: 100%;
	left: 50%;
}

.red-ui-popover.red-ui-popover-top:after,
.red-ui-popover.red-ui-popover-top:before {
	top: 100%;
	left: 50%;
}

.red-ui-popover.red-ui-popover-right:after {
	border-color: transparent;
	border-right-color: #333;
	border-width: 10px;
	margin-top: -10px;
}

.red-ui-popover.red-ui-popover-right:before {
	border-color: transparent;
	border-right-color: #333;
	border-width: 11px;
	margin-top: -11px;
}

.red-ui-popover.red-ui-popover-left:after {
	border-color: transparent;
	border-left-color: #333;
	border-width: 10px;
	margin-top: -10px;
}

.red-ui-popover.red-ui-popover-left:before {
	border-color: transparent;
	border-left-color: #333;
	border-width: 11px;
	margin-top: -11px;
}

.red-ui-popover.red-ui-popover-bottom:after {
	border-color: transparent;
	border-bottom-color: #333;
	border-width: 10px;
	margin-left: -10px;
}

.red-ui-popover.red-ui-popover-bottom:before {
	border-color: transparent;
	border-bottom-color: #333;
	border-width: 11px;
	margin-left: -11px;
}

.red-ui-popover.red-ui-popover-top:after {
	border-color: transparent;
	border-top-color: #333;
	border-width: 10px;
	margin-left: -10px;
}

.red-ui-popover.red-ui-popover-top:before {
	border-color: transparent;
	border-top-color: #333;
	border-width: 11px;
	margin-left: -11px;
}

.red-ui-popover-size-small {
	font-size: 12px;
	padding: 5px 7px;
	line-height: 1.8em;
}
.red-ui-popover-size-small.red-ui-popover-right:after,
.red-ui-popover-size-small.red-ui-popover-left:after {
	border-width: 7px;
	margin-top: -7px;
}
.red-ui-popover-size-small.red-ui-popover-right:before,
.red-ui-popover-size-small.red-ui-popover-left:before {
	border-width: 8px;
	margin-top: -8px;
}
.red-ui-popover-size-small.red-ui-popover-bottom:after,
.red-ui-popover-size-small.red-ui-popover-top:after {
	border-width: 7px;
	margin-left: -7px;
}
.red-ui-popover-size-small.red-ui-popover-bottom:before,
.red-ui-popover-size-small.red-ui-popover-top:before {
	border-width: 8px;
	margin-left: -8px;
}

.red-ui-popover-key {
	font-size: 11px;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	margin-left: 3px;
	border: 1px solid #eee;
	border-radius: 3px;
	padding: 1px 2px;
}

.red-ui-popover a.red-ui-button:not(.primary),
.red-ui-popover button.red-ui-button:not(.primary) {
	border-color: #bbb;
	background: #333;
	color: #eee !important;
}
.red-ui-popover
	a.red-ui-button:not(.primary):not(.disabled):not(.ui-button-disabled):hover,
.red-ui-popover
	button.red-ui-button:not(.primary):not(.disabled):not(.ui-button-disabled):hover {
	border-color: #666;
}
.red-ui-popover a.red-ui-button.primary,
.red-ui-popover button.red-ui-button.primary {
	border-color: #bbb;
}
.red-ui-popover
	a.red-ui-button.primary:not(.disabled):not(.ui-button-disabled):hover,
.red-ui-popover
	button.red-ui-button.primary:not(.disabled):not(.ui-button-disabled):hover {
	border-color: #666;
}

.red-ui-popover code {
	border: none;
	background: none;
	color: #aaa;
}

.red-ui-popover-panel {
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size: 14px;
	position: absolute;
	box-sizing: border-box;
	border: 1px solid #bbbbbb;
	background: #fff;
	z-index: 2000;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.nr-ui-view-lasso {
	stroke-width: 1px;
	stroke: #ff7f0e;
	fill: rgba(20, 125, 255, 0.1);
	stroke-dasharray: 10 5;
}

.node_label_italic,
.red-ui-flow-node-label-italic {
	font-style: italic;
}

.red-ui-flow-node-label-white {
	fill: #fff !important;
}

.red-ui-flow-node-label {
	stroke-width: 0;
	fill: #333;
	font-size: 14px;
	pointer-events: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.red-ui-flow-node-label .red-ui-flow-node-label-text {
	dominant-baseline: middle;
}
.red-ui-flow-node-label.red-ui-flow-node-label-right
	.red-ui-flow-node-label-text {
	text-anchor: end;
}

.red-ui-flow-port-label {
	stroke-width: 0;
	fill: #888;
	font-size: 16px;
	dominant-baseline: middle;
	text-anchor: middle;
	pointer-events: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.red-ui-flow-node {
	stroke: #999;
	cursor: move;
	stroke-width: 1;
}

.red-ui-workspace-select-mode g.red-ui-flow-node.red-ui-flow-node-hovered * {
	cursor: pointer !important;
}
.red-ui-workspace-select-mode g.red-ui-flow-node,
.red-ui-workspace-select-mode g.red-ui-flow-link {
	opacity: 0.5;
}
.red-ui-workspace-select-mode
	g.red-ui-flow-node.red-ui-flow-node-hovered:not(.red-ui-flow-node-selected) {
	opacity: 0.9;
}
.red-ui-workspace-select-mode
	g.red-ui-flow-node.red-ui-flow-node-hovered:not(.red-ui-flow-node-selected)
	.red-ui-flow-node {
	stroke-width: 2;
	stroke: #ff7f0e !important;
	stroke-dasharray: 10, 4;
}

.red-ui-flow-group.red-ui-flow-group-hovered .red-ui-flow-group-outline-select {
	stroke-opacity: 0.8 !important;
	stroke-dasharray: 10 4 !important;
}
.red-ui-flow-group.red-ui-flow-group-active-hovered:not(.red-ui-flow-group-hovered)
	.red-ui-flow-group-outline-select {
	stroke: #aaa;
}

.red-ui-flow-group-outline {
	fill: none;
	stroke: #ff7f0e;
	stroke-opacity: 0;
	stroke-width: 12;
	pointer-events: stroke;
}

.red-ui-flow-group-outline-select {
	fill: none;
	stroke: #ff7f0e;
	pointer-events: stroke;
	stroke-opacity: 0;
	stroke-width: 3;
}
.red-ui-flow-group-outline-select.red-ui-flow-group-outline-select-background {
	stroke: #fff;
	stroke-width: 6;
}

.red-ui-flow-group-body {
	pointer-events: none;
	fill: none;
	fill-opacity: 1;
	stroke-width: 2;
	stroke: #999;
	stroke-opacity: 1;
}

.red-ui-flow-group-label {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	fill: #a4a4a4;
}

.red-ui-flow-node-unknown {
	stroke-dasharray: 10, 4;
	stroke: #f33;
}

.red-ui-flow-node-placeholder {
	stroke-dasharray: 10, 4;
	stroke: #aaa;
	fill: #eee;
	opacity: 0.5;
	stroke-width: 2;
}

.red-ui-flow-node-icon-group text {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
.red-ui-flow-node-icon-group .fa-lg {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	stroke: none;
	fill: #fff;
	text-anchor: middle;
	font-family: FontAwesome;
}

.red-ui-flow-node-icon-shade {
	stroke: none;
	fill: #000;
	fill-opacity: 0.05;
}

.red-ui-flow-node-icon-shade-border {
	stroke-opacity: 0.1;
	stroke: #000;
	stroke-width: 1;
}

.red-ui-flow-node-button {
	fill: inherit;
}
.red-ui-flow-node-button.red-ui-flow-node-button-disabled {
	opacity: 0.4;
}
.red-ui-flow-node-button.red-ui-flow-node-button-disabled
	.red-ui-flow-node-button-button {
	cursor: default;
}

.red-ui-flow-node-button-button {
	cursor: pointer;
}

.red-ui-flow-node-button-background {
	fill: #eee;
}

.red-ui-flow-port {
	stroke: #999;
	stroke-width: 1;
	fill: #d9d9d9;
	cursor: crosshair;
}

.red-ui-flow-node-error {
	fill: #ff6600;
	stroke: #911002;
	stroke-width: 1px;
	cursor: default;
	stroke-linejoin: round;
	stroke-linecap: round;
}

.red-ui-flow-node-changed {
	fill: #00bcff;
	stroke: #1c668c;
	cursor: default;
	stroke-width: 1px;
	stroke-linejoin: round;
	stroke-linecap: round;
}

.red-ui-workspace-select-mode g.red-ui-flow-node-selected {
	opacity: 1;
}
g.red-ui-flow-node-selected .red-ui-flow-node,
g.red-ui-flow-node-selected .red-ui-flow-subflow-port {
	stroke-width: 2;
	stroke: #ff7f0e !important;
}

.red-ui-flow-node-highlighted {
	border-color: #ff7f0e !important;
	border-style: dashed !important;
	stroke: #ff7f0e;
	stroke-width: 3;
	stroke-dasharray: 8, 4;
}

.red-ui-workspace-disabled .red-ui-flow-node {
	stroke-dasharray: 8, 3;
	fill-opacity: 0.5;
}
.red-ui-workspace-disabled .red-ui-flow-link-line {
	stroke-dasharray: 10, 8 !important;
	stroke-width: 2 !important;
	stroke: #ccc;
}
.red-ui-workspace-disabled .red-ui-flow-port {
	fill-opacity: 1;
	stroke-dasharray: none;
}
.red-ui-workspace-disabled .red-ui-flow-group,
.red-ui-workspace-disabled .red-ui-flow-group-body {
	stroke-dasharray: 8, 3;
}

.red-ui-flow-node-disabled.red-ui-flow-node,
.red-ui-flow-node-disabled .red-ui-flow-node {
	stroke-dasharray: 8, 3;
	fill-opacity: 0.5;
}
.red-ui-flow-node-disabled.red-ui-flow-link-line {
	stroke-dasharray: 10, 8 !important;
	stroke-width: 2 !important;
	stroke: #ccc;
}
.red-ui-flow-node-disabled .red-ui-flow-port {
	fill-opacity: 1;
	stroke-dasharray: none;
}

.red-ui-flow-node-status-dot-red {
	fill: #c00;
	stroke: #c00;
}

.red-ui-flow-node-status-ring-red {
	fill: #fff;
	stroke: #c00;
}

.red-ui-flow-node-status-dot-green {
	fill: #5a8;
	stroke: #5a8;
}

.red-ui-flow-node-status-ring-green {
	fill: #fff;
	stroke: #5a8;
}

.red-ui-flow-node-status-dot-yellow {
	fill: #f9df31;
	stroke: #f9df31;
}

.red-ui-flow-node-status-ring-yellow {
	fill: #fff;
	stroke: #f9df31;
}

.red-ui-flow-node-status-dot-blue {
	fill: #53a3f3;
	stroke: #53a3f3;
}

.red-ui-flow-node-status-ring-blue {
	fill: #fff;
	stroke: #53a3f3;
}

.red-ui-flow-node-status-dot-grey {
	fill: #d3d3d3;
	stroke: #d3d3d3;
}

.red-ui-flow-node-status-ring-grey {
	fill: #fff;
	stroke: #d3d3d3;
}

.red-ui-flow-node-status-dot-gray {
	fill: #d3d3d3;
	stroke: #d3d3d3;
}

.red-ui-flow-node-status-ring-gray {
	fill: #fff;
	stroke: #d3d3d3;
}

.red-ui-flow-node-status-label {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	stroke-width: 0;
	fill: #888;
	font-size: 9pt;
	text-anchor: start;
}

.red-ui-flow-port-hovered {
	stroke: #ff7f0e;
	fill: #ff7f0e;
}

.red-ui-flow-subflow-port {
	fill: #eee;
	stroke: #999;
}

.red-ui-flow-drag-line {
	stroke: #ff7f0e !important;
	stroke-width: 3;
	fill: none;
	pointer-events: none;
}

.red-ui-flow-link-line {
	stroke: #999;
	stroke-width: 3;
	fill: none;
	pointer-events: none;
}

.red-ui-flow-link-link {
	stroke-width: 2;
	stroke: #aaa;
	fill: none;
	stroke-dasharray: 25, 4;
}

.red-ui-flow-link-off-flow {
	stroke-width: 2;
}

.red-ui-flow-link-port {
	fill: #eee;
	stroke: #aaa;
	stroke-width: 1;
}

.red-ui-flow-link-group-active .red-ui-flow-link-port {
	stroke: #ff7f0e;
}

.red-ui-flow-link-group:hover {
	cursor: pointer;
}

.red-ui-flow-link-outline {
	stroke: #fff;
	stroke-opacity: 0.4;
	stroke-width: 5;
	cursor: crosshair;
	fill: none;
	pointer-events: none;
}

.red-ui-flow-link-background {
	stroke: #fff;
	opacity: 0;
	stroke-width: 20;
	cursor: crosshair;
	fill: none;
}

.red-ui-flow-link-splice > .red-ui-flow-link-line {
	stroke-dasharray: 15, 8;
}

g.red-ui-flow-link-selected path.red-ui-flow-link-line {
	stroke: #ff7f0e;
}

g.red-ui-flow-link-unknown path.red-ui-flow-link-line {
	stroke: #f00;
	stroke-width: 2;
	stroke-dasharray: 10, 4;
}

@keyframes red-ui-flow-port-tooltip-fadeIn {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
.red-ui-flow-port-tooltip {
	opacity: 0;
	animation: 0.1s ease-in 0s 1 normal forwards red-ui-flow-port-tooltip-fadeIn;
	pointer-events: none;
}
.red-ui-flow-port-tooltip path:first-child {
	fill: #333;
	stroke: #333;
	stroke-width: 1;
}

.red-ui-flow-port-tooltip-label {
	stroke-width: 0;
	fill: #eee;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size: 12px;
	pointer-events: none;
	-webkit-touch-callout: none;
	white-space: pre;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-settings-tab-palette {
	height: 100%;
}

#red-ui-palette-editor {
	text-align: left;
	position: absolute;
	top: 0px;
	right: 0;
	bottom: 0;
	left: 0;
	padding: 0;
	box-sizing: border-box;
	background: #fff;
}
#red-ui-palette-editor .red-ui-editableList-container {
	border: none;
	border-radius: 0;
	padding: 0px;
}
#red-ui-palette-editor .red-ui-editableList-container li {
	padding: 0px;
}
#red-ui-palette-editor .red-ui-editableList-container li .red-ui-button {
	min-width: 60px;
}
#red-ui-palette-editor
	.red-ui-editableList-container
	li
	.disabled
	.red-ui-palette-module-name {
	font-style: italic;
	color: #aaa;
}
#red-ui-palette-editor
	.red-ui-editableList-container
	li
	.disabled
	.red-ui-palette-module-version {
	color: #aaa;
}
#red-ui-palette-editor
	.red-ui-editableList-container
	li
	.disabled
	.red-ui-palette-module-errors
	.fa-warning {
	opacity: 0.5;
}
#red-ui-palette-editor
	.red-ui-editableList-container
	li
	.disabled
	ul.red-ui-palette-module-error-list
	li {
	color: #aaa;
}
#red-ui-palette-editor
	.red-ui-editableList-container
	li
	.red-ui-editableList-item-content {
	padding: 12px 16px;
}
#red-ui-palette-editor .red-ui-palette-editor-tab {
	position: absolute;
	top: 35px;
	left: 0;
	right: 0;
	bottom: 0;
}
#red-ui-palette-editor .red-ui-palette-editor-toolbar {
	background: #f3f3f3;
	box-sizing: border-box;
	padding: 8px 10px;
	border-bottom: 1px solid #bbbbbb;
	text-align: right;
}
#red-ui-palette-editor .red-ui-palette-module-shade-status {
	color: #888;
}
#red-ui-palette-editor .red-ui-palette-module-updated {
	margin-left: 10px;
}
#red-ui-palette-editor .red-ui-palette-module-link {
	margin-left: 5px;
}
#red-ui-palette-editor .red-ui-palette-module-description {
	margin-left: 20px;
	font-size: 0.9em;
	color: #888;
}
#red-ui-palette-editor .red-ui-palette-module-content {
	display: none;
	padding: 10px 3px;
}
#red-ui-palette-editor i.fa.red-ui-palette-module-node-chevron {
	width: 8px;
	margin-right: 0;
	transform: rotate(0deg);
	transition: transform 0.2s ease-in-out;
}
#red-ui-palette-editor .expanded i.fa.red-ui-palette-module-node-chevron {
	transform: rotate(90deg);
}
#red-ui-palette-editor .red-ui-palette-module-set {
	border: 1px solid #dddddd;
	border-radius: 0;
	padding: 5px;
	position: relative;
}
#red-ui-palette-editor .red-ui-palette-module-set:not(:last-child) {
	border-bottom: none;
}
#red-ui-palette-editor .red-ui-palette-module-set:first-child {
	border-top-right-radius: 2px;
	border-top-left-radius: 2px;
}
#red-ui-palette-editor .red-ui-palette-module-set:last-child {
	border-bottom-right-radius: 2px;
	border-bottom-left-radius: 2px;
}
#red-ui-palette-editor .red-ui-palette-module-type {
	color: #888;
	padding-left: 5px;
	font-size: 0.9em;
	-webkit-user-select: auto;
	-khtml-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}
#red-ui-palette-editor .red-ui-palette-module-type-swatch {
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 3px;
	vertical-align: middle;
	margin-right: 5px;
	background: #f3f3f3;
	border: 1px dashed #dddddd;
}
#red-ui-palette-editor .red-ui-palette-module-set-button-group {
	position: absolute;
	right: 4px;
	top: 4px;
}
#red-ui-palette-editor .red-ui-palette-module-set-disabled {
	background: #f0f0f0;
}
#red-ui-palette-editor
	.red-ui-palette-module-set-disabled
	.red-ui-palette-module-type {
	color: #999;
}
#red-ui-palette-editor .red-ui-palette-module-more {
	padding: 0 !important;
	margin-top: 10px;
	margin-bottom: 10px;
	background: #f0f0f0;
}
#red-ui-palette-editor .red-ui-palette-module-more a {
	display: block;
	text-align: center;
	padding: 12px 8px;
	color: #ad1625;
}
#red-ui-palette-editor .red-ui-palette-module-more a:hover {
	text-decoration: none;
	background: #e6e6e6;
}

.red-ui-palette-module-meta {
	color: #888;
	position: relative;
}
.red-ui-palette-module-meta.disabled {
	color: #bbb;
}
.red-ui-palette-module-meta .fa {
	width: 15px;
	text-align: center;
	margin-right: 5px;
}

.red-ui-palette-module-name {
	color: #555;
	white-space: nowrap;
	-webkit-user-select: auto;
	-khtml-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}

.red-ui-palette-module-version,
.red-ui-palette-module-updated,
.red-ui-palette-module-link {
	font-style: italic;
	font-size: 0.8em;
	-webkit-user-select: auto;
	-khtml-user-select: auto;
	-moz-user-select: auto;
	-ms-user-select: auto;
	user-select: auto;
}

.red-ui-palette-module-button-group {
	position: absolute;
	right: 0;
	bottom: 0;
}
.red-ui-palette-module-button-group a {
	margin-left: 5px;
}

.red-ui-palette-module-meta .fa-warning {
	color: #cab200;
}

ul.red-ui-palette-module-error-list {
	display: inline-block;
	list-style-type: none;
	margin: 0;
	font-size: 0.9em;
}
ul.red-ui-palette-module-error-list li {
	border: none;
	background: none;
}

.red-ui-palette-module-shade {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(160, 160, 160, 0.5);
	z-index: 5;
	text-align: center;
	padding-top: 20px;
}

#red-ui-palette-module-install-shade {
	padding-top: 80px;
}

button.red-ui-palette-editor-upload-button {
	padding: 0;
	height: 25px;
	margin-top: -1px;
}
button.red-ui-palette-editor-upload-button input[type='file'] {
	opacity: 0;
	margin: 0;
	height: 0;
	width: 0;
}
.red-ui-settings-tabs-content button.red-ui-palette-editor-upload-button label {
	margin: 0;
	min-width: 0;
	padding: 2px 8px;
}
button.red-ui-palette-editor-upload-button form {
	width: 0;
}

.red-ui-palette-editor-upload {
	display: none;
	position: absolute;
	left: 0;
	right: 0;
	top: 44px;
	padding: 20px;
	background: #fff;
	border-bottom: 1px #dddddd solid;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.red-ui-palette-editor-upload .placeholder-input {
	width: calc(100% - 180px);
	margin: 0;
}

.red-ui-palette-editor-upload-buttons {
	float: right;
}
.red-ui-palette-editor-upload-buttons button {
	margin-left: 10px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-diff-panel {
	padding: 5px;
	padding-top: 30px;
	position: relative;
}
.red-ui-diff-panel .red-ui-editableList-container {
	border-radius: 1px;
	padding: 0;
	background: #f7f7f7;
}
.red-ui-diff-panel .red-ui-diff-list li {
	background: #f7f7f7;
	padding: 0px;
	border: none;
	min-height: 0;
}
.red-ui-diff-panel .red-ui-editableList-item-content {
	padding: 5px;
}

.red-ui-diff-container {
	position: absolute;
	top: 40px;
	right: 0;
	bottom: 0;
	left: 0;
	overflow-y: scroll;
}

.red-ui-diff-panel-headers {
	position: absolute;
	left: 232px;
	right: 12px;
	top: 5px;
	height: 25px;
}
.red-ui-diff-panel-headers div {
	height: 25px;
	display: inline-block;
	box-sizing: border-box;
	padding-top: 2px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 50%;
	background: #f7f7f7;
	text-align: center;
	border-top: 1px solid #dddddd;
	border-color: #dddddd;
	border-left: 1px solid #dddddd;
}
.red-ui-diff-panel-headers div:last-child {
	border-right: 1px solid #dddddd;
}

.red-ui-diff-dialog-toolbar {
	box-sizing: border-box;
	color: #888;
	text-align: right;
	padding: 8px 10px;
	background: #f3f3f3;
	border-bottom: 1px solid #dddddd;
	white-space: nowrap;
}

.red-ui-diff-list-flow {
	background: #fff;
	border: 1px solid #dddddd;
	border-radius: 1px;
	overflow: hidden;
}
.red-ui-diff-list-flow.collapsed
	.red-ui-diff-list-flow-title
	.red-ui-diff-list-chevron {
	transform: rotate(-90deg);
}
.red-ui-diff-list-flow.collapsed .red-ui-diff-list-node {
	display: none;
}

.red-ui-diff-list-flow-stats {
	font-size: 0.9em;
}

.red-ui-diff-list-chevron {
	display: inline-block;
	width: 15px;
	text-align: center;
	margin-left: 3px;
	transition: transform 0.1s ease-in-out;
}

.red-ui-diff-list-node {
	margin-left: 20px;
	font-size: 0.9em;
}
.red-ui-diff-list-node:first-child {
	border-top: 1px solid #ccc;
}
.red-ui-diff-list-node:not(:last-child) {
	border-bottom: 1px solid #ccc;
}
.red-ui-diff-list-node.collapsed .red-ui-diff-list-chevron {
	transform: rotate(-90deg);
}
.red-ui-diff-list-node.collapsed .red-ui-diff-list-node-properties {
	display: none;
}
.red-ui-diff-list-node table {
	border-collapse: collapse;
	table-layout: fixed;
	width: calc(100% - 20px);
	margin-left: 20px;
}
.red-ui-diff-list-node col:first-child {
	width: 180px;
}
.red-ui-diff-list-node col:not(:first-child) {
	width: 100%;
}
.red-ui-diff-list-node td,
.red-ui-diff-list-node th {
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	padding: 0 0 0 3px;
	text-align: left;
	overflow-x: auto;
}
.red-ui-diff-list-node td:first-child,
.red-ui-diff-list-node th:first-child {
	border-left: none;
}
.red-ui-diff-list-node tr {
	vertical-align: top;
}
.red-ui-diff-list-node tr:first-child td {
	white-space: nowrap;
	overflow: hidden;
}
.red-ui-diff-list-node tr:hover {
	background: #efefef;
}
.red-ui-diff-list-node td .red-ui-diff-status {
	margin-left: 0;
}
.red-ui-diff-list-node tr:not(.red-ui-diff-list-header) .red-ui-diff-status {
	width: 12px;
	margin-left: 0;
	margin-top: 0;
	margin-bottom: 0;
	margin-right: 5px;
}

.red-ui-diff-three-way .red-ui-diff-list-node-cell {
	width: calc((100% - 220px) / 2);
}
.red-ui-diff-three-way .red-ui-diff-list-node-cell:first-child {
	width: 220px;
}
.red-ui-diff-three-way col:not(:first-child) {
	width: 50%;
}
.red-ui-diff-three-way .red-ui-diff-list-node .red-ui-diff-list-node-cell {
	width: calc((100% + 20px - 220px) / 2);
}
.red-ui-diff-three-way
	.red-ui-diff-list-node
	.red-ui-diff-list-node-cell:first-child {
	width: 200px;
}

.red-ui-diff-list-flow-title {
	cursor: pointer;
	padding: 0;
}
.red-ui-diff-list-flow-title:hover {
	background: #efefef;
}

.red-ui-diff-list-flow-title-meta {
	vertical-align: middle;
	display: inline-block;
	padding-top: 2px;
}

.red-ui-diff-list-node-header {
	cursor: pointer;
}
.red-ui-diff-list-node-header:hover {
	background: #efefef;
}

.red-ui-diff-list-node-icon {
	vertical-align: middle;
	display: inline-block;
	margin: 5px;
	width: 18px;
	height: 15px;
	background: #fff;
	border-radius: 2px;
	border: 1px solid #999;
	background-position: 5% 50%;
	background-repeat: no-repeat;
	background-size: contain;
	position: relative;
}
.red-ui-diff-list-node-icon .red-ui-palette-icon {
	background-position: 49% 50%;
	width: 15px;
}
.red-ui-diff-list-node-icon .red-ui-palette-icon-fa {
	position: relative;
	top: -2.5px;
	left: 0px;
}
.red-ui-diff-list-node-icon .red-ui-palette-icon-container {
	width: 18px;
}

.red-ui-diff-list-flow-empty .red-ui-diff-list-chevron i {
	display: none;
}
.red-ui-diff-list-flow-empty .red-ui-diff-list-flow-title {
	cursor: default;
}
.red-ui-diff-list-flow-empty .red-ui-diff-list-flow-title:hover {
	background: none;
}

.red-ui-diff-status-deleted {
	cursor: default !important;
}
.red-ui-diff-status-deleted .red-ui-diff-status {
	color: #f80000;
}
.red-ui-diff-status-deleted .red-ui-diff-list-node-node {
	opacity: 0.5;
}
.red-ui-diff-status-deleted .red-ui-diff-list-node-description {
	opacity: 0.5;
	text-decoration: line-through;
}

.red-ui-diff-status-added {
	cursor: default !important;
}
.red-ui-diff-status-added .red-ui-diff-status {
	color: #009900;
}

.red-ui-diff-status-moved .red-ui-diff-status {
	color: #3f81b3;
}

.red-ui-diff-status-changed .red-ui-diff-status {
	color: #f89406;
}

.red-ui-diff-status-unchanged .red-ui-diff-status {
	color: #bbb;
}

.red-ui-diff-status-conflict .red-ui-diff-status {
	color: #9b45ce;
}

.red-ui-diff-list-node-title {
	display: inline-block;
}
.red-ui-diff-list-node-title .red-ui-diff-status {
	margin-left: 15px;
}

.red-ui-diff-list-node-properties {
	margin: 0;
	color: #555;
}

.red-ui-diff-status {
	display: inline-block;
	height: 20px;
	margin-left: 5px;
	vertical-align: top;
	margin-top: 6px;
	margin-bottom: 6px;
	text-align: center;
}

.red-ui-diff-list-element {
	display: inline-block;
	width: calc(100% - 20px);
}

.red-ui-diff-list-node-description {
	color: #555;
	margin-right: 5px;
	padding-top: 5px;
	display: inline-block;
}
.red-ui-diff-list-node-description:after {
	content: '';
	display: table;
	clear: both;
}

.red-ui-diff-state-added {
	color: #009900;
}

.red-ui-diff-state-deleted {
	color: #f80000;
}

.red-ui-diff-state-changed {
	color: #f89406;
}

.red-ui-diff-state-unchanged {
	color: #bbb;
}

.red-ui-diff-state-conflicted {
	color: purple;
}

.red-ui-diff-list-node-cell {
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
	width: calc((100% - 20px) / 2);
	height: 32px;
	border-left: 1px solid #dddddd;
	padding-top: 2px;
	white-space: nowrap;
	overflow: hidden;
	position: relative;
}

.red-ui-diff-empty {
	background: #f9f9f9;
	background: repeating-linear-gradient(
		20deg,
		#fff,
		#fff 5px,
		#f9f9f9 5px,
		#f9f9f9 10px
	);
}

.red-ui-diff-list-node-cell:first-child {
	border-left: none;
}

.red-ui-diff-list-cell-label {
	margin-left: 20px;
	vertical-align: top;
	box-sizing: border-box;
	padding-left: 8px;
	width: 120px;
}

.red-ui-diff-list-wires {
	display: inline-block;
}
.red-ui-diff-list-wires .red-ui-diff-list-node-node {
	width: 18px;
	height: 15px;
}
.red-ui-diff-list-wires .red-ui-palette-icon-container {
	width: 18px;
}
.red-ui-diff-list-wires .red-ui-palette-icon {
	width: 15px;
}
.red-ui-diff-list-wires ul,
.red-ui-diff-list-wires li,
.red-ui-diff-list-wires ol {
	background: none !important;
}
.red-ui-diff-list-wires ul {
	vertical-align: middle;
	display: inline-block;
	margin-left: 5px;
}
.red-ui-diff-list-wires li {
	list-style-type: none !important;
}
.red-ui-diff-list-wires ol {
	font-size: 0.9em;
	margin: 0;
}
.red-ui-diff-list-wires ol > span {
	vertical-align: middle;
	display: inline-block;
	width: 30px;
	text-align: center;
}

.red-ui-diff-list-node-props .red-ui-diff-list-node-cell:first-child {
	padding: 6px 0px;
}
.red-ui-diff-list-node-props
	.red-ui-diff-list-node-cell:first-child
	span:not(.red-ui-diff-list-chevron) {
	margin-left: 5px;
}

.red-ui-diff-list-cell .red-ui-debug-msg-row:hover {
	background: none;
}
.red-ui-diff-list-cell.red-ui-diff-status-changed {
	background: #fff2e1;
}
.red-ui-diff-list-cell.red-ui-diff-status-conflict {
	background: #ffdad4;
}

label.red-ui-diff-selectbox {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	width: 35px;
	text-align: center;
	border-left: 1px solid #dddddd;
	margin: 0;
}
label.red-ui-diff-selectbox input[type='radio'] {
	margin-top: 8px;
}
label.red-ui-diff-selectbox:hover {
	background: #e6e6e6;
}

.red-ui-diff-list-node-conflict.red-ui-diff-select-remote
	.red-ui-diff-list-node-remote {
	background: #e7ffe3;
}
.red-ui-diff-list-node-conflict.red-ui-diff-select-remote
	.red-ui-diff-list-node-remote
	label {
	border-left-color: #b8daad;
}
.red-ui-diff-list-node-conflict.red-ui-diff-select-remote
	.red-ui-diff-list-node-local {
	background: #ffe1e1;
}
.red-ui-diff-list-node-conflict.red-ui-diff-select-remote
	.red-ui-diff-list-node-local
	label {
	border-left-color: #e4bcbc;
}

.red-ui-diff-list-node-conflict.red-ui-diff-select-local
	.red-ui-diff-list-node-local {
	background: #e7ffe3;
}
.red-ui-diff-list-node-conflict.red-ui-diff-select-local
	.red-ui-diff-list-node-local
	label {
	border-left-color: #b8daad;
}
.red-ui-diff-list-node-conflict.red-ui-diff-select-local
	.red-ui-diff-list-node-remote {
	background: #ffe1e1;
}
.red-ui-diff-list-node-conflict.red-ui-diff-select-local
	.red-ui-diff-list-node-remote
	label {
	border-left-color: #e4bcbc;
}

ul.red-ui-deploy-dialog-confirm-list {
	font-size: 0.9em;
	width: 400px;
	margin: 10px auto;
	text-align: left;
}

.red-ui-deploy-dialog-confirm-conflict-row img {
	vertical-align: middle;
	height: 30px;
	margin-right: 10px;
}
.red-ui-deploy-dialog-confirm-conflict-row i {
	vertical-align: middle;
	text-align: center;
	font-size: 30px;
	width: 30px;
	margin-right: 10px;
}
.red-ui-deploy-dialog-confirm-conflict-row i.fa-check {
	color: #3a3;
}
.red-ui-deploy-dialog-confirm-conflict-row i.fa-exclamation {
	color: #888;
}
.red-ui-deploy-dialog-confirm-conflict-row div {
	vertical-align: middle;
	width: calc(100% - 60px);
	display: inline-block;
}

#red-ui-diff-dialog-toolbar-resolved-conflicts .red-ui-diff-status {
	margin: 0;
}

.red-ui-diff-text-diff-button {
	float: right;
	margin: 2px 3px;
	line-height: 14px;
	height: 16px;
}

.red-ui-diff-text {
	height: 100%;
	overflow-y: auto;
}
.red-ui-diff-text table.red-ui-diff-text-content {
	margin: 10px;
	border: 1px solid #dddddd;
	border-radius: 3px;
	table-layout: fixed;
	width: calc(100% - 20px);
}
.red-ui-diff-text table.red-ui-diff-text-content td {
	vertical-align: top;
	word-wrap: break-word;
}
.red-ui-diff-text table.red-ui-diff-text-content td.lineno {
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	text-align: right;
	color: #aaa;
	background: #f7f7f7;
	padding: 1px 5px;
}
.red-ui-diff-text table.red-ui-diff-text-content td.lineno.added {
	background: #c0f6c0;
}
.red-ui-diff-text table.red-ui-diff-text-content td.lineno.removed {
	background: #ffcccc;
}
.red-ui-diff-text table.red-ui-diff-text-content td.lineno:nth-child(3) {
	border-left: 1px solid #dddddd;
}
.red-ui-diff-text table.red-ui-diff-text-content td.linetext {
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	white-space: pre-wrap;
	padding: 1px 5px;
	border-left: 1px solid #ccc;
}
.red-ui-diff-text table.red-ui-diff-text-content td.linetext span.prefix {
	width: 30px;
	display: inline-block;
	text-align: center;
	color: #888;
}
.red-ui-diff-text table.red-ui-diff-text-content td.linetext.added {
	border-left-color: #aaeeaa;
}
.red-ui-diff-text table.red-ui-diff-text-content td.linetext.removed {
	border-left-color: #eebbbb;
}
.red-ui-diff-text table.red-ui-diff-text-content td.blank {
	background: #f7f7f7;
}
.red-ui-diff-text table.red-ui-diff-text-content td.added {
	background: #e7ffe3;
}
.red-ui-diff-text table.red-ui-diff-text-content td.removed {
	background: #ffe1e1;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.mergeHeader td {
	color: #800080;
	background: #e5f9ff;
	height: 26px;
	vertical-align: middle;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.mergeHeader-separator td {
	color: #800080;
	background: #b2edff;
	height: 0px;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.mergeHeader-ours td {
	border-top: 2px solid #b2edff;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.mergeHeader-theirs td {
	border-bottom: 2px solid #b2edff;
}
.red-ui-diff-text table.red-ui-diff-text-content td.unchanged {
	background: #fff;
	color: #bbb;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.unchanged {
	background: #fff;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.start-block {
	border-top: 1px solid #dddddd;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.end-block {
	border-bottom: 1px solid #dddddd;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-file-header
	td {
	background: #f3f3f3;
	padding: 5px 10px 5px 0;
	color: #555;
	cursor: pointer;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-file-header
	td
	.filename {
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-file-header
	td
	i.red-ui-diff-list-chevron {
	width: 30px;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-file-header.collapsed
	td
	i.red-ui-diff-list-chevron {
	transform: rotate(-90deg);
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-commit-header
	td {
	background: #f3f3f3;
	padding: 5px 10px;
	color: #555;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-commit-header
	td
	h3 {
	font-size: 1.4em;
	margin: 0;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-commit-header
	td
	.commit-summary {
	border-top: 1px solid #dddddd;
	padding-top: 5px;
	color: #888;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-commit-header
	td
	.commit-body {
	margin-bottom: 15px;
	white-space: pre;
	line-height: 1.2em;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-header
	> td:not(.red-ui-diff-flow-diff) {
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	padding: 5px 10px;
	text-align: left;
	color: #888;
	background: #ffd;
	height: 30px;
	vertical-align: middle;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
}
.red-ui-diff-text table.red-ui-diff-text-content tr.red-ui-diff-text-expand td {
	cursor: pointer;
}
.red-ui-diff-text
	table.red-ui-diff-text-content
	tr.red-ui-diff-text-expand
	td:hover {
	background: #ffd;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-settings-tabs-container {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 120px;
	background: #f7f7f7;
}

.red-ui-settings-tabs-content {
	position: absolute;
	top: 0;
	left: 120px;
	right: 0;
	bottom: 0;
	padding: 0;
}
.red-ui-settings-tabs-content h3:not(:first-child) {
	border-top: 1px solid #dddddd;
	margin-top: 15px;
	margin-bottom: 10px;
	padding-top: 20px;
}
.red-ui-settings-tabs-content label {
	display: inline-block;
	min-width: 100px;
	vertical-align: top;
	margin-top: 5px;
}
.red-ui-settings-tabs-content label input {
	vertical-align: top;
	padding-bottom: 0;
}
.red-ui-settings-tabs-content div.uneditable-input {
	position: relative;
}
.red-ui-settings-tabs-content input[type='number'] {
	width: 60px;
}
.red-ui-settings-tabs-content h4 {
	margin-top: 20px;
	margin-bottom: 10px;
}

#red-ui-settings-tab-view {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	padding: 8px 20px 20px;
	overflow-y: scroll;
}

.red-ui-settings-row {
	padding: 5px 10px 2px;
}

.red-ui-settings-section {
	position: relative;
}
.red-ui-settings-section:after {
	content: '';
	display: table;
	clear: both;
}
.red-ui-settings-section .uneditable-input,
.red-ui-settings-section input[type='text'],
.red-ui-settings-section input[type='password'],
.red-ui-settings-section textarea {
	width: calc(100% - 150px);
}
.red-ui-settings-section textarea {
	resize: none;
	height: 10em;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-projects-dialog {
	padding: 0;
}
#red-ui-projects-dialog .red-ui-editableList-container {
	padding: 0px;
}
#red-ui-projects-dialog .red-ui-projects-dialog-box {
	box-sizing: border-box;
	overflow-y: auto;
	padding: 25px 25px 10px 25px;
}

#red-ui-project-settings-tab-settings {
	overflow-y: scroll;
}

.red-ui-sidebar-vc-shade {
	background: #f3f3f3;
}

.red-ui-projects-edit-form form {
	margin: 0;
}
.red-ui-projects-edit-form form .form-row {
	margin-bottom: 15px;
}
.red-ui-projects-edit-form form .form-row label {
	color: #555;
	width: 100%;
	display: block;
}
.red-ui-projects-edit-form
	form
	.form-row
	label.red-ui-projects-edit-form-inline-label {
	font-weight: normal;
	color: inherit;
	width: auto;
}
.red-ui-projects-edit-form form .form-row input[type='text'],
.red-ui-projects-edit-form form .form-row input[type='password'],
.red-ui-projects-edit-form form .form-row textarea {
	width: 100%;
}
.red-ui-projects-edit-form form .form-row input[type='checkbox'],
.red-ui-projects-edit-form form .form-row input[type='radio'] {
	width: auto;
	vertical-align: middle;
}

.red-ui-projects-edit-form-sublabel {
	color: #aaa;
	text-align: right;
	margin-bottom: -15px;
	font-weight: normal;
}

.red-ui-project-settings-tab-pane * .red-ui-projects-edit-form-sublabel {
	margin-right: 50px;
	margin-top: -10px !important;
	margin-bottom: 5px;
}

.red-ui-projects-dialog-screen-start .red-ui-projects-dialog-screen-start-hero {
	text-align: center;
	font-size: 2em;
	padding: 10px;
	min-height: 60px;
	color: #555;
}
.red-ui-projects-dialog-screen-start .red-ui-projects-dialog-screen-start-body {
	min-height: 400px;
	line-height: 1.6em;
}
.red-ui-projects-dialog-screen-start
	.red-ui-projects-dialog-screen-start-body
	p {
	font-size: 1.1em;
	margin-bottom: 20px;
}
.red-ui-projects-dialog-screen-start
	.red-ui-projects-dialog-screen-start-body
	p:first-child {
	font-weight: 500;
	font-size: 1.2em;
}
.red-ui-projects-dialog-screen-start
	button.red-ui-button.red-ui-projects-dialog-button {
	width: calc(50% - 80px);
	margin: 20px;
	height: auto;
	line-height: 2em;
	padding: 10px;
}
.red-ui-projects-dialog-screen-start .button-group {
	text-align: center;
}

.red-ui-projects-dialog-screen-create {
	min-height: 500px;
}
.red-ui-projects-dialog-screen-create
	button.red-ui-projects-dialog-screen-create-type {
	position: relative;
	height: auto;
	padding: 10px;
}
.red-ui-projects-dialog-screen-create .button-group {
	text-align: center;
}

.red-ui-projects-dialog-screen-secret {
	min-height: auto;
}

.red-ui-projects-dialog-credentials-box {
	width: 550px;
}
.red-ui-projects-dialog-credentials-box > div {
	vertical-align: top;
	display: inline-block;
}

.red-ui-projects-dialog-credentials-box-right {
	min-height: 150px;
	box-sizing: border-box;
	float: right;
	width: 331px;
	margin-left: -1px;
	padding: 15px;
	margin-top: -15px;
	border: 1px solid #dddddd;
	border-radius: 3px;
}

.red-ui-projects-dialog-credentials-box-left {
	width: 220px;
}
.red-ui-projects-dialog-credentials-box-left > div {
	padding: 7px 8px 3px 8px;
	border: 1px solid #dddddd;
	border-radius: 4px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right-color: #fff;
}
.red-ui-projects-dialog-credentials-box-left > div.disabled {
	border-color: #fff;
	border-right-color: #dddddd;
}
.red-ui-projects-dialog-credentials-box-left > div i {
	font-size: 1.4em;
	margin-right: 8px;
	vertical-align: middle;
}
.red-ui-projects-dialog-credentials-box-left
	> div
	label.red-ui-projects-edit-form-inline-label {
	margin-left: 5px;
}
.red-ui-projects-dialog-credentials-box-left
	> div
	label.red-ui-projects-edit-form-inline-label
	span {
	vertical-align: middle;
}
.red-ui-projects-dialog-credentials-box-left > div input[type='radio'] {
	vertical-align: middle;
	margin-top: 0;
	margin-right: 10px;
}

.red-ui-projects-dialog-project-list-container {
	border: 1px solid #dddddd;
	border-radius: 2px;
	display: flex;
	flex-direction: column;
}
.red-ui-projects-dialog-project-list-container .red-ui-search-container {
	flex-grow: 0;
}

.red-ui-projects-dialog-project-list-inner-container {
	flex-grow: 1;
	overflow-y: scroll;
	position: relative;
}
.red-ui-projects-dialog-project-list-inner-container
	.red-ui-editableList-border {
	border: none;
}

.red-ui-projects-dialog-project-list li {
	padding: 0 !important;
}

.red-ui-projects-dialog-project-list-entry {
	padding: 12px 0;
	color: #555;
	background: #fff;
	border-left: 3px solid #fff;
	border-right: 3px solid #fff;
}
.red-ui-projects-dialog-project-list-entry.projects-list-entry-current:not(.selectable) {
	color: #555;
	background: #ffebc7;
	border-left-color: #666;
	border-right-color: #666;
}
.red-ui-projects-dialog-project-list-entry.projects-list-entry-current i {
	color: #888;
}
.red-ui-projects-dialog-project-list-entry.selectable {
	cursor: pointer;
}
.red-ui-projects-dialog-project-list-entry.selectable:hover:not(.selected) {
	color: #555;
	background: #e6e6e6;
	border-left-color: #e6e6e6;
	border-right-color: #e6e6e6;
}
.red-ui-projects-dialog-project-list-entry
	.red-ui-projects-dialog-project-list-entry-icon
	i {
	color: #aaa;
	font-size: 2em;
}
.red-ui-projects-dialog-project-list-entry.selected {
	color: #555;
	background: #ffebc7;
	border-left-color: #666;
	border-right-color: #666;
}
.red-ui-projects-dialog-project-list-entry span {
	display: inline-block;
	vertical-align: middle;
}
.red-ui-projects-dialog-project-list-entry
	.red-ui-projects-dialog-project-list-entry-icon {
	margin: 0 10px 0 5px;
}
.red-ui-projects-dialog-project-list-entry
	.red-ui-projects-dialog-project-list-entry-name {
	font-size: 1.2em;
}
.red-ui-projects-dialog-project-list-entry
	.red-ui-projects-dialog-project-list-entry-current {
	float: right;
	margin-right: 20px;
	font-size: 0.9em;
	color: #888;
	padding-top: 4px;
}
.red-ui-projects-dialog-project-list-entry
	.red-ui-projects-dialog-project-list-entry-tools {
	position: absolute;
	top: 16px;
	right: 30px;
	display: none;
	color: #888;
}
.red-ui-projects-dialog-project-list-entry:hover
	.red-ui-projects-dialog-project-list-entry-tools {
	display: block;
}

.red-ui-projects-dialog-project-list-entry-delete-confirm {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 100%;
	width: 1000px;
	overflow: hidden;
	padding: 5px 20px;
	background: #fff;
	transition: left 0.4s;
	white-space: nowrap;
}
.red-ui-projects-dialog-project-list-entry-delete-confirm > span {
	line-height: 40px;
}
.red-ui-projects-dialog-project-list-entry-delete-confirm button {
	margin-left: 20px;
}

.red-ui-projects-dialog-screen-create-type {
	position: relative;
}

.red-ui-projects-dialog-screen-create-type.red-ui-button.toggle.selected:not(.disabled):not(:disabled) {
	color: #666 !important;
}

.red-ui-projects-dialog-screen-input-status {
	text-align: right;
	position: absolute;
	top: 2px;
	right: 8px;
	width: 70px;
	height: 30px;
	color: #888;
}

.red-ui-sidebar-vc {
	height: 100%;
}

.red-ui-sidebar-vc-stack {
	position: absolute;
	top: 0px;
	bottom: 0;
	left: 0;
	right: 0;
	overflow: hidden;
}
.red-ui-sidebar-vc-stack .red-ui-palette-category:not(.expanded) button {
	display: none;
}

#red-ui-project-settings-tab-deps .red-ui-editableList-container {
	padding: 0;
}
#red-ui-project-settings-tab-deps .red-ui-editableList-border {
	border-radius: 0;
}
#red-ui-project-settings-tab-deps .red-ui-editableList-item-content {
	padding: 0px 6px;
}
#red-ui-project-settings-tab-deps .red-ui-palette-module-header {
	padding: 6px 4px;
}
#red-ui-project-settings-tab-deps .red-ui-palette-module-button {
	float: right;
}
#red-ui-project-settings-tab-deps .red-ui-palette-module-unused > * {
	color: #888;
}
#red-ui-project-settings-tab-deps .red-ui-palette-module-unknown {
	border: 1px dashed #dddddd;
	background: #f0f0f0;
}
#red-ui-project-settings-tab-deps .red-ui-palette-module-not-installed {
	border: 1px dashed #cab200;
}
#red-ui-project-settings-tab-deps
	.red-ui-palette-module-not-installed
	i.fa-warning {
	color: #cab200;
}

.red-ui-project-settings-tab-pane {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow-y: auto;
	padding: 8px 20px 20px;
}

.red-ui-sidebar-vc .red-ui-editableList-container {
	background: #f7f7f7;
	padding: 0;
}
.red-ui-sidebar-vc .red-ui-editableList-container li {
	padding: 0;
	background: #fff;
}
.red-ui-sidebar-vc .red-ui-editableList-border {
	border: none;
	border-radius: 0;
}

.red-ui-sidebar-vc-change-container {
	position: relative;
	height: 50%;
	box-sizing: border-box;
	transition: height 0.2s ease-in-out;
}
.red-ui-sidebar-vc-merging .red-ui-sidebar-vc-change-container {
	height: 33%;
}

.red-ui-sidebar-vc-slide-box {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	height: 0;
	transition: height 0.2s ease-in-out;
	background: #f7f7f7;
	box-sizing: border-box;
	overflow: hidden;
}
.red-ui-sidebar-vc-slide-box.red-ui-sidebar-vc-slide-box-top {
	z-index: 4;
	top: 0px;
	left: auto;
	width: 100%;
	max-width: 280px;
	border-left: 1px solid #bbbbbb;
	border-right: 1px solid #bbbbbb;
	border-bottom: 1px solid #bbbbbb;
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	color: #555;
	background: #f7f7f7;
	padding: 10px;
	box-sizing: border-box;
}
.red-ui-sidebar-vc-slide-box.red-ui-sidebar-vc-slide-box-bottom {
	bottom: 0px;
	border-top: 1px solid #dddddd;
}
.red-ui-sidebar-vc-slide-box textarea {
	height: 110px;
	margin: 10px;
	width: calc(100% - 20px);
	box-sizing: border-box;
	border-radius: 1px;
	resize: none;
}

.red-ui-projects-branch-list {
	position: relative;
}
.red-ui-projects-branch-list .red-ui-searchBox-container {
	border-top: 1px solid #dddddd;
	border-left: 1px solid #dddddd;
	border-right: 1px solid #dddddd;
	border-top-left-radius: 2px;
	border-top-right-radius: 2px;
	overflow: hidden;
}
.red-ui-projects-branch-list .red-ui-editableList {
	border: 1px solid #dddddd;
	border-bottom-left-radius: 2px;
	border-bottom-right-radius: 2px;
}
.red-ui-projects-branch-list
	.red-ui-editableList
	> .red-ui-editableList-border {
	border-radius: 0;
	border: none;
}
.red-ui-projects-branch-list
	.red-ui-editableList
	.red-ui-editableList-container {
	padding: 0;
}
.red-ui-projects-branch-list
	.red-ui-editableList
	.red-ui-editableList-container
	li {
	padding: 0;
	background: #fff;
}

.uneditable-input .red-ui-projects-branch-list .red-ui-editableList {
	border-left: none;
	border-bottom: none;
	border-right: none;
}
.uneditable-input .red-ui-projects-branch-list .red-ui-searchBox-container {
	border-left: none;
	border-right: none;
}

.red-ui-sidebar-vc-slide-box-header {
	margin-bottom: 10px;
}

.red-ui-sidebar-vc-slide-box-toolbar {
	padding: 0 20px;
	text-align: right;
}

.red-ui-sidebar-vc-branch-list-entry {
	padding: 5px 8px;
	margin: 0 1px;
	color: #555;
	background: #fff;
	border-left: 2px solid #fff;
	border-right: 2px solid #fff;
	cursor: pointer;
}
.red-ui-sidebar-vc-branch-list-entry.selected {
	border-left-color: #666;
	border-right-color: #666;
}
.red-ui-sidebar-vc-branch-list-entry i {
	width: 16px;
	text-align: center;
}
.red-ui-sidebar-vc-branch-list-entry.input-error {
	cursor: default;
}
.red-ui-sidebar-vc-branch-list-entry:not(.input-error):hover {
	background: #e6e6e6;
	border-left-color: #666;
	border-right-color: #666;
}
.red-ui-sidebar-vc-branch-list-entry span {
	margin-left: 5px;
}
.red-ui-sidebar-vc-branch-list-entry span.current {
	float: right;
	font-size: 0.8em;
	color: #aaa;
}

.red-ui-sidebar-vc-change-entry {
	height: 20px;
	padding: 5px 10px;
	position: relative;
	white-space: nowrap;
}
.red-ui-sidebar-vc-change-entry span {
	margin: 0 6px;
}
.red-ui-sidebar-vc-change-entry a {
	color: currentColor;
}
.red-ui-sidebar-vc-change-entry a.disabled {
	pointer-events: none;
}
.red-ui-sidebar-vc-change-entry .red-ui-sidebar-vc-change-entry-tools {
	position: absolute;
	top: 4px;
	right: 4px;
	display: none;
}
.red-ui-sidebar-vc-change-entry .red-ui-sidebar-vc-change-entry-tools button {
	width: 24px;
}
.red-ui-sidebar-vc-change-entry:hover .red-ui-sidebar-vc-change-entry-tools {
	display: block;
}
.red-ui-sidebar-vc-change-entry.red-ui-help-info-node {
	text-align: center;
	background: #fff;
	white-space: normal;
	height: auto;
}

.red-ui-sidebar-vc-commit-entry {
	min-height: 20px;
	padding: 5px 10px;
	position: relative;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
}
.red-ui-sidebar-vc-commit-entry:hover {
	background: #e6e6e6;
}

.red-ui-sidebar-vc-commit-more {
	color: #888;
	text-align: center;
	padding: 10px;
	font-style: italic;
}

.red-ui-sidebar-vc-commit-sha {
	float: right;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	color: #c38888;
	display: inline-block;
	font-size: 0.85em;
	margin-left: 5px;
}

.red-ui-sidebar-vc-commit-subject {
	color: #555;
}

.red-ui-sidebar-vc-commit-refs {
	min-height: 22px;
}

.red-ui-sidebar-vc-commit-ref {
	color: #aaa;
	font-size: 0.7em;
	border: 1px solid #ccc;
	border-radius: 10px;
	padding: 2px 5px;
	margin-right: 5px;
}

.red-ui-sidebar-vc-commit-date {
	color: #888;
	font-size: 0.85em;
}

.red-ui-sidebar-vc-commit-user {
	float: right;
	color: #888;
	font-size: 0.85em;
}

.red-ui-sidebar-vc-change-header {
	color: #555;
	background: #f7f7f7;
	padding: 4px 10px;
	height: 30px;
	box-sizing: border-box;
	border-top: 1px solid #dddddd;
	border-bottom: 1px solid #dddddd;
}
.red-ui-sidebar-vc-change-header i {
	transition: all 0.2s ease-in-out;
}

.red-ui-sidebar-vc-repo-toolbar {
	color: #555;
	background: #f7f7f7;
	padding: 10px;
	box-sizing: border-box;
}

.red-ui-sidebar-vc-repo-count {
	margin-right: 8px;
	display: none;
}

.red-ui-sidebar-vc-repo-action {
	text-align: left;
	width: 100%;
}

.red-ui-sidebar-vc-repo-sub-action {
	width: calc(50% - 5px);
	margin-right: 5px;
}
.red-ui-sidebar-vc-repo-sub-action:not(:first-child) {
	margin-right: 0;
	margin-left: 5px;
}

.red-ui-projects-file-listing-container
	> .red-ui-editableList
	> .red-ui-editableList-border {
	border-radius: 0;
	border: none;
	border-top: 1px solid #dddddd;
}

.red-ui-editableList-container
	.red-ui-projects-dialog-file-list
	.red-ui-editableList-border {
	border: none;
}
.red-ui-editableList-container .red-ui-projects-dialog-file-list li {
	padding: 0 !important;
	border: none;
}
.red-ui-editableList-container
	.red-ui-projects-dialog-file-list
	.red-ui-editableList-container {
	padding: 0;
}

.red-ui-projects-dialog-file-list-entry {
	padding: 3px 0;
	border-left: 2px solid #fff;
	border-right: 2px solid #fff;
	background: #fff;
}
.red-ui-projects-dialog-file-list-entry.projects-list-entry-current:not(.selectable) {
	background: #ffebc7;
}
.red-ui-projects-dialog-file-list-entry.projects-list-entry-current i {
	color: #666;
}
.red-ui-projects-dialog-file-list-entry.selectable {
	cursor: pointer;
}
.red-ui-projects-dialog-file-list-entry.selectable:hover {
	background: #e6e6e6;
	border-left-color: #666;
	border-right-color: #666;
}
.red-ui-projects-dialog-file-list-entry.unselectable {
	color: #bbb;
}
.red-ui-projects-dialog-file-list-entry i {
	color: #888;
	width: 16px;
	text-align: center;
}
.red-ui-projects-dialog-file-list-entry.selected {
	background: #ffebc7;
	border-left-color: #666;
	border-right-color: #666;
}
.red-ui-projects-dialog-file-list-entry span {
	display: inline-block;
	vertical-align: middle;
}
.red-ui-projects-dialog-file-list-entry
	.red-ui-projects-dialog-file-list-entry-folder {
	margin: 0 10px 0 0px;
}
.red-ui-projects-dialog-file-list-entry
	.red-ui-projects-dialog-file-list-entry-folder
	.fa-angle-right {
	color: #555;
	transition: all 0.2s ease-in-out;
}
.red-ui-projects-dialog-file-list-entry
	.red-ui-projects-dialog-file-list-entry-file {
	margin: 0 10px 0 20px;
}
.red-ui-projects-dialog-file-list-entry
	.red-ui-projects-dialog-file-list-entry-name {
	font-size: 1em;
}
.red-ui-projects-dialog-file-list-entry.expanded .fa-angle-right {
	transform: rotate(90deg);
}

.red-ui-projects-dialog-file-list-entry-file-type-git {
	color: #aaa;
}

.red-ui-projects-dialog-remote-list .red-ui-editableList-container {
	padding: 0;
}
.red-ui-projects-dialog-remote-list .red-ui-editableList-container li {
	padding: 0;
	border: none;
	border-radius: 4px;
	overflow: hidden;
}

div.red-ui-projects-dialog-ssh-public-key {
	position: relative;
	padding: 15px 20px 0;
}
div.red-ui-projects-dialog-ssh-public-key pre {
	position: relative;
	word-break: break-all;
}
div.red-ui-projects-dialog-ssh-public-key:after {
	content: '';
	display: table;
	clear: both;
}

.red-ui-projects-dialog-ssh-key-list li {
	padding: 0 !important;
}
.red-ui-projects-dialog-ssh-key-list .red-ui-projects-dialog-ssh-key-header {
	padding: 10px 5px;
	cursor: pointer;
}
.red-ui-projects-dialog-ssh-key-list
	.red-ui-projects-dialog-ssh-key-header:hover {
	background: #e6e6e6;
}

.red-ui-projects-dialog-list {
	position: relative;
}
.red-ui-projects-dialog-list .red-ui-editableList-container {
	padding: 1px;
	background: #f7f7f7;
}
.red-ui-projects-dialog-list .red-ui-editableList-container li:last-child {
	border-bottom: none;
}

.red-ui-projects-dialog-list-entry.red-ui-search-empty {
	padding: 0;
}
.red-ui-projects-dialog-list-entry span {
	display: inline-block;
}
.red-ui-projects-dialog-list-entry .entry-icon {
	text-align: center;
	min-width: 30px;
	vertical-align: top;
	color: #888;
}
.red-ui-projects-dialog-list-entry .entry-name {
	min-width: 250px;
}
.red-ui-projects-dialog-list-entry.current .entry-name {
	font-weight: bold;
}
.red-ui-projects-dialog-list-entry .entry-detail {
	color: #aaa;
	font-size: 0.9em;
}
.red-ui-projects-dialog-list-entry .entry-remote-name {
	min-width: 250px;
}
.red-ui-projects-dialog-list-entry .entry-tools {
	float: right;
	margin-right: 10px;
}

.red-ui-projects-dialog-list-dialog {
	position: relative;
	margin-top: 10px;
	margin-bottom: 20px;
	background: #fff;
	border-radius: 4px;
	border: 1px solid #dddddd;
}
.red-ui-projects-dialog-list-dialog .red-ui-projects-edit-form-sublabel {
	margin-top: -8px !important;
	margin-right: 50px;
	display: block !important;
	width: auto !important;
}
.red-ui-projects-dialog-list-dialog:after {
	content: '';
	display: table;
	clear: both;
}
.red-ui-projects-dialog-list-dialog .red-ui-projects-dialog-list-dialog-header {
	font-weight: bold;
	background: #f3f3f3;
	margin-top: 0 !important;
	padding: 5px 10px;
	margin-bottom: 10px;
}

#red-ui-settings-tab-gitconfig {
	padding: 8px 20px 20px;
}

.red-ui-settings-section-description {
	color: #888;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-editableList-border {
	border: 1px solid #ccc;
	border-radius: 4px;
}
.red-ui-editableList-border .red-ui-editableList-header {
	border-bottom: 1px solid #ccc;
	padding: 2px 16px 2px 4px;
	font-size: 0.9em;
}

.red-ui-editableList-container {
	padding: 5px;
	margin: 0;
	vertical-align: middle;
	box-sizing: border-box;
}
.red-ui-editableList-container .red-ui-editableList-list {
	list-style-type: none;
	margin: 0;
}
.red-ui-editableList-container .red-ui-editabelList-item-placeholder {
	border: 2px dashed #dddddd !important;
}
.red-ui-editableList-container li {
	box-sizing: border-box;
	position: relative;
	background: #fff;
	margin: 0;
	padding: 8px 0px;
	border-bottom: 1px solid #dddddd;
	min-height: 20px;
}
.red-ui-editableList-container li .red-ui-editableList-item-handle {
	position: absolute;
	top: 50%;
	left: 2px;
	margin-top: -7px;
	color: #aaa;
	cursor: move;
}
.red-ui-editableList-container li .red-ui-editableList-item-remove {
	position: absolute;
	top: 50%;
	right: 0px;
	margin-top: -9px;
}
.red-ui-editableList-container li.ui-sortable-helper {
	border-top: 1px solid #dddddd;
}
.red-ui-editableList-container
	li.red-ui-editableList-item-sortable
	.red-ui-editableList-item-content {
	margin-left: 22px;
}
.red-ui-editableList-container
	li.red-ui-editableList-item-removable
	.red-ui-editableList-item-content {
	margin-right: 28px;
}
.red-ui-editableList-container li.red-ui-editableList-item-deleting {
	background: #f0f0f0;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-searchBox-container {
	position: relative;
}
.red-ui-searchBox-container i {
	position: absolute;
	top: 9px;
	font-size: 10px;
	color: #888;
}
.red-ui-searchBox-container i.fa-search {
	pointer-events: none;
	left: 8px;
}
.red-ui-searchBox-container i.fa-caret-down {
	position: absolute;
	right: 6px;
	font-size: 12px;
}
.red-ui-searchBox-container i.fa-times {
	position: absolute;
	right: 4px;
}
.red-ui-searchBox-container form.red-ui-searchBox-form {
	margin: 0;
}
.red-ui-searchBox-container a.red-ui-searchBox-opts:hover {
	color: #666;
	background: #e6e6e6;
}
.red-ui-searchBox-container input.red-ui-searchBox-input {
	border-radius: 0;
	border: none;
	width: 100%;
	box-shadow: none;
	-webkit-box-shadow: none;
	padding: 3px 17px 3px 22px;
	margin: 0px;
	height: 30px;
	box-sizing: border-box;
}
.red-ui-searchBox-container input.red-ui-searchBox-input:focus {
	border: none;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.red-ui-searchBox-container a {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	width: 16px;
}
.red-ui-searchBox-container a.red-ui-searchBox-clear {
	display: none;
}
.red-ui-searchBox-container .red-ui-searchBox-resultCount {
	position: absolute;
	right: 18px;
	top: 4px;
	background: #f3f3f3;
	color: #888;
	padding: 1px 8px;
	font-size: 9px;
	border-radius: 4px;
}
.red-ui-searchBox-container.red-ui-searchBox-has-options
	a.red-ui-searchBox-clear {
	right: 16px;
}
.red-ui-searchBox-container.red-ui-searchBox-has-options
	.red-ui-searchBox-resultCount {
	right: 33px;
}
.red-ui-searchBox-container.red-ui-searchBox-has-options
	input.red-ui-searchBox-input {
	padding-right: 33px;
}

.red-ui-searchBox-compact input:focus.red-ui-searchBox-input {
	outline: 1px solid rgba(85, 150, 230, 0.8);
}
.red-ui-searchBox-compact input.red-ui-searchBox-input,
.red-ui-searchBox-compact input:focus.red-ui-searchBox-input {
	border: 1px solid #dddddd;
	border-radius: 3px;
	font-size: 12px;
	height: 26px;
}
.red-ui-searchBox-compact i.fa-times,
.red-ui-searchBox-compact i.fa-search,
.red-ui-searchBox-compact i.fa-caret-down {
	top: 7px;
}
.red-ui-searchBox-compact .red-ui-searchBox-resultCount {
	top: 3px;
	padding: 0 6px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-typedInput-container {
	border: 1px solid #ccc;
	border-radius: 4px;
	height: 34px;
	line-height: 14px;
	display: inline-flex;
	padding: 0;
	margin: 0;
	vertical-align: middle;
	box-sizing: border-box;
	overflow: hidden;
	position: relative;
}
.red-ui-typedInput-container[disabled] input,
.red-ui-typedInput-container[disabled] button {
	background: #f0f0f0;
	pointer-events: none;
	cursor: not-allowed;
}
.red-ui-typedInput-container .red-ui-typedInput-input-wrap {
	flex-grow: 1;
}
.red-ui-typedInput-container input.red-ui-typedInput-input {
	width: 100%;
	padding: 0 0 0 3px;
	margin: 0;
	height: 32px;
	border: none;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	box-shadow: none;
	vertical-align: middle;
}
.red-ui-typedInput-container.red-ui-typedInput-focus:not(.input-error) {
	border-color: rgba(85, 150, 230, 0.8) !important;
}
.red-ui-typedInput-container .red-ui-typedInput-value-label {
	flex-grow: 1;
	display: inline-block;
	height: 32px;
	box-sizing: border-box;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.red-ui-typedInput-container
	.red-ui-typedInput-value-label
	.red-ui-typedInput-value-label-inactive {
	background: #f9f9f9;
	color: #bbb;
}

.red-ui-typedInput-options {
	box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	font-size: 14px;
	position: absolute;
	max-height: 350px;
	overflow-y: auto;
	border: 1px solid #bbbbbb;
	box-sizing: border-box;
	background: #fff;
	white-space: nowrap;
	z-index: 2000;
}
.red-ui-typedInput-options a {
	padding: 6px 18px 6px 6px;
	display: block;
	border-bottom: 1px solid #dddddd;
	color: #555;
}
.red-ui-typedInput-options a:hover {
	text-decoration: none;
	background: #e6e6e6;
}
.red-ui-typedInput-options a:focus {
	text-decoration: none;
	background: #efefef;
	outline: none;
}
.red-ui-typedInput-options a:active {
	text-decoration: none;
	background: #efefef;
}
.red-ui-typedInput-options a input[type='checkbox'] {
	margin-right: 6px;
}
.red-ui-typedInput-options .red-ui-typedInput-icon {
	margin-right: 6px;
}

button.red-ui-typedInput-type-select,
button.red-ui-typedInput-option-expand,
button.red-ui-typedInput-option-trigger {
	text-align: left;
	border: none;
	flex-basis: auto;
	box-sizing: border-box;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
	padding: 0 1px 0 5px;
	background: #efefef;
	height: 32px;
	line-height: 30px;
	vertical-align: middle;
	color: #555;
	white-space: nowrap;
}
button.red-ui-typedInput-type-select i.red-ui-typedInput-icon,
button.red-ui-typedInput-option-expand i.red-ui-typedInput-icon,
button.red-ui-typedInput-option-trigger i.red-ui-typedInput-icon {
	margin-left: 1px;
	margin-right: 2px;
	vertical-align: middle;
}
button.red-ui-typedInput-type-select.disabled,
button.red-ui-typedInput-option-expand.disabled,
button.red-ui-typedInput-option-trigger.disabled {
	cursor: default;
}
button.red-ui-typedInput-type-select.disabled > i.red-ui-typedInput-icon,
button.red-ui-typedInput-option-expand.disabled > i.red-ui-typedInput-icon,
button.red-ui-typedInput-option-trigger.disabled > i.red-ui-typedInput-icon {
	color: #bbb;
}
button.red-ui-typedInput-type-select .red-ui-typedInput-type-label,
button.red-ui-typedInput-type-select .red-ui-typedInput-option-label,
button.red-ui-typedInput-option-expand .red-ui-typedInput-type-label,
button.red-ui-typedInput-option-expand .red-ui-typedInput-option-label,
button.red-ui-typedInput-option-trigger .red-ui-typedInput-type-label,
button.red-ui-typedInput-option-trigger .red-ui-typedInput-option-label {
	display: inline-block;
	vertical-align: middle;
	height: 100%;
	padding: 0 1px 0 5px;
}
button.red-ui-typedInput-type-select .red-ui-typedInput-type-label img,
button.red-ui-typedInput-type-select .red-ui-typedInput-option-label img,
button.red-ui-typedInput-option-expand .red-ui-typedInput-type-label img,
button.red-ui-typedInput-option-expand .red-ui-typedInput-option-label img,
button.red-ui-typedInput-option-trigger .red-ui-typedInput-type-label img,
button.red-ui-typedInput-option-trigger .red-ui-typedInput-option-label img {
	max-width: none;
}
button.red-ui-typedInput-type-select:not(.disabled):hover,
button.red-ui-typedInput-option-expand:not(.disabled):hover,
button.red-ui-typedInput-option-trigger:not(.disabled):hover {
	text-decoration: none;
	background: #e6e6e6;
}
button.red-ui-typedInput-type-select:focus,
button.red-ui-typedInput-option-expand:focus,
button.red-ui-typedInput-option-trigger:focus {
	text-decoration: none;
	outline: none;
	box-shadow: inset 0 0 0 1px rgba(85, 150, 230, 0.8);
}
button.red-ui-typedInput-type-select:not(.disabled):active,
button.red-ui-typedInput-option-expand:not(.disabled):active,
button.red-ui-typedInput-option-trigger:not(.disabled):active {
	background: #efefef;
	text-decoration: none;
}
button.red-ui-typedInput-type-select.red-ui-typedInput-full-width,
button.red-ui-typedInput-option-expand.red-ui-typedInput-full-width,
button.red-ui-typedInput-option-trigger.red-ui-typedInput-full-width {
	flex-grow: 1;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
}
button.red-ui-typedInput-type-select:before,
button.red-ui-typedInput-option-expand:before,
button.red-ui-typedInput-option-trigger:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}

button.red-ui-typedInput-option-expand {
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

button.red-ui-typedInput-option-trigger {
	border-top-left-radius: 0px;
	border-bottom-left-radius: 0px;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	padding: 0 0 0 0;
	position: relative;
	flex-grow: 1;
	line-height: 32px;
	display: inline-flex;
}
button.red-ui-typedInput-option-trigger .red-ui-typedInput-option-label {
	background: #efefef;
	color: #555;
	flex-grow: 1;
	padding: 0 0 0 8px;
	display: inline-block;
}
button.red-ui-typedInput-option-trigger .red-ui-typedInput-option-caret {
	flex-grow: 0;
	display: inline-block;
	width: 23px;
	text-align: center;
	height: 100%;
}
button.red-ui-typedInput-option-trigger .red-ui-typedInput-option-caret:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
button.red-ui-typedInput-option-trigger:focus {
	box-shadow: none;
}
button.red-ui-typedInput-option-trigger:focus .red-ui-typedInput-option-caret {
	box-shadow: inset 0 0 0 1px rgba(85, 150, 230, 0.8);
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-nodeList .red-ui-editableList-container {
	padding: 0;
}
.red-ui-nodeList .red-ui-editableList-container li {
	padding: 2px 5px;
	margin: 0;
	white-space: nowrap;
	border: none;
	background: #fff;
}
.red-ui-nodeList .red-ui-editableList-container li:hover {
	background: #e6e6e6;
}
.red-ui-nodeList .red-ui-editableList-container li i.fa-angle-right {
	text-align: center;
	width: 15px;
	transition: transform 0.1s ease-in-out;
}
.red-ui-nodeList .red-ui-editableList-container li .expandable {
	cursor: pointer;
}
.red-ui-nodeList .red-ui-editableList-container li .expanded i.fa-angle-right {
	transform: rotate(90deg);
}
.red-ui-nodeList .red-ui-editableList-container li .meta {
	float: right;
}
.red-ui-nodeList
	.red-ui-editableList-container
	li
	.meta
	input[type='checkbox'].red-ui-treeList-checkbox {
	margin: 0;
}
.red-ui-nodeList
	.red-ui-editableList-container
	li
	.red-ui-editableList-item-content.disabled {
	color: #bbb;
}
.red-ui-nodeList
	.red-ui-editableList-container
	li.red-ui-editableList-section-header {
	background: #f3f3f3;
}
.red-ui-nodeList
	.red-ui-editableList-container
	li.red-ui-editableList-section-header
	.red-ui-editableList-item-content.disabled {
	color: #bbb;
}
.red-ui-nodeList .red-ui-editableList-header {
	text-align: left;
}
.red-ui-nodeList .red-ui-editableList-header > span:last-child {
	float: right;
	font-size: 14px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-checkboxSet {
	width: 15px;
	display: inline-block;
	color: #888;
	cursor: pointer;
}
.red-ui-checkboxSet input {
	display: none !important;
}
.red-ui-checkboxSet.disabled {
	pointer-events: none;
	color: #bbb;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-stack {
	background: #fff;
}
.red-ui-stack .red-ui-palette-category {
	background: #fff;
}
.red-ui-stack .red-ui-palette-category:last-child {
	border-bottom: none;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-treeList:focus {
	outline: none !important;
}

.red-ui-treeList-container {
	width: 100%;
	height: 100%;
	position: relative;
	background: #f7f7f7;
	border: 1px solid #ccc;
	border-radius: 4px;
	box-sizing: border-box;
}
.red-ui-treeList-container .red-ui-editableList-border {
	border: none;
}
.red-ui-treeList-container .red-ui-editableList-container {
	padding: 0px;
}
.red-ui-treeList-container .red-ui-editableList-container li {
	padding: 0;
	border-bottom: none;
}
.red-ui-treeList-container
	.red-ui-editableList-item-content
	> .red-ui-treeList-label
	.fa-angle-right {
	transition: transform 0.1s ease-in-out;
}
.red-ui-treeList-container
	.red-ui-editableList-item-content.expanded
	> .red-ui-treeList-label
	.fa-angle-right {
	transform: rotate(90deg);
}

.red-ui-treeList-label {
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	padding: 6px 0;
	display: flex;
	align-items: center;
	color: #555;
	text-decoration: none;
	cursor: pointer;
	vertical-align: middle;
	margin: 0;
	position: relative;
}
.red-ui-treeList-label:hover,
.red-ui-treeList-label:hover .red-ui-treeList-sublabel-text {
	background: #e6e6e6;
	color: #555;
	text-decoration: none;
}
.red-ui-treeList-label:focus,
.red-ui-treeList-label:focus .red-ui-treeList-sublabel-text {
	background: #e6e6e6;
	outline: none;
	color: #555;
	text-decoration: none;
}
.red-ui-treeList-label.selected,
.red-ui-treeList-label.selected .red-ui-treeList-sublabel-text {
	background: #ffebc7;
	outline: none;
	color: #555;
}
.red-ui-treeList-label input.red-ui-treeList-checkbox {
	margin: 0;
}

.red-ui-treeList-label-text {
	margin-left: 4px;
}
.red-ui-treeList-label-text:empty {
	min-height: 20px;
}

.red-ui-treeList-sublabel-text {
	top: 0;
	bottom: 0;
	right: 0;
	padding: 0 10px 0 5px;
	line-height: 32px;
	font-size: 0.9em;
	color: #888;
	position: absolute;
	background: #fff;
}

.red-ui-treeList-icon {
	display: inline-block;
	width: 20px;
	text-align: center;
}

.red-ui-treeList-spinner {
	height: 32px;
	background: url(../../assets/red/spin.svg) 50% 50% no-repeat;
	background-size: auto 20px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-drop-target {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(0, 0, 0, 0.3);
	display: table;
	width: 100%;
	height: 100%;
	display: none;
	z-index: 100;
}
#red-ui-drop-target div {
	pointer-events: none;
	display: table-cell;
	vertical-align: middle;
	text-align: center;
	font-size: 40px;
	color: #fff;
}
#red-ui-drop-target div i {
	pointer-events: none;
	font-size: 80px;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
#red-ui-settings-tab-keyboard .red-ui-editableList-container {
	border-radius: 0;
	border: none;
	padding: 0;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-list-header {
	padding: 0 5px 0 5px;
	border-bottom: 1px solid #bbbbbb;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-list-header div {
	color: #444 !important;
}
#red-ui-settings-tab-keyboard
	.keyboard-shortcut-list-header
	.red-ui-searchBox-container {
	width: calc(100% - 20px);
}
#red-ui-settings-tab-keyboard
	.keyboard-shortcut-list-header
	.keyboard-shortcut-entry-scope {
	text-align: center;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-list {
	position: absolute;
	top: 30px;
	left: 10px;
	right: 10px;
	bottom: 10px;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-list li {
	padding: 0;
}
#red-ui-settings-tab-keyboard
	.keyboard-shortcut-list
	li
	.red-ui-editableList-item-content {
	padding: 8px;
	cursor: pointer;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-list li:hover {
	background: #e6e6e6;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry div {
	display: inline-block;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry select {
	margin: 0;
	width: calc(100% - 30px);
	font-size: 0.9em;
	margin-right: 5px;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-key {
	width: 160px;
	vertical-align: middle;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-key input {
	margin: 0;
	width: calc(100% - 5px);
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-text {
	vertical-align: middle;
	width: calc(100% - 160px - 100px - 10px);
	overflow: hidden;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-text i {
	color: #aaa;
	margin-right: 5px;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-scope {
	width: 100px;
	color: #aaa;
	vertical-align: middle;
	text-align: right;
}
#red-ui-settings-tab-keyboard
	.keyboard-shortcut-entry:not(.keyboard-shortcut-list-header)
	.keyboard-shortcut-entry-scope {
	font-size: 0.8em;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-unassigned {
	color: #aaa;
}
#red-ui-settings-tab-keyboard
	.keyboard-shortcut-entry-unassigned
	.keyboard-shortcut-entry-key {
	font-style: italic;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-expanded {
	background: #ffebc7;
}
#red-ui-settings-tab-keyboard
	.keyboard-shortcut-entry-expanded
	.keyboard-shortcut-entry-key {
	width: 150px;
}
#red-ui-settings-tab-keyboard
	.keyboard-shortcut-entry-expanded
	.keyboard-shortcut-entry-scope {
	width: 110px;
}
#red-ui-settings-tab-keyboard .keyboard-shortcut-entry-expanded span {
	display: none;
}

.help-key {
	border: 1px solid #ccc;
	padding: 4px;
	border-radius: 3px;
	background: #f7f7f7;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	box-shadow: rgba(160, 160, 160, 0.5) 1px 1px 1px;
}

.help-key-block {
	white-space: nowrap;
}

/**
   * Copyright JS Foundation and other contributors, http://js.foundation
   *
   * Licensed under the Apache License, Version 2.0 (the "License");
   * you may not use this file except in compliance with the License.
   * You may obtain a copy of the License at
   *
   * http://www.apache.org/licenses/LICENSE-2.0
   *
   * Unless required by applicable law or agreed to in writing, software
   * distributed under the License is distributed on an "AS IS" BASIS,
   * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
   * See the License for the specific language governing permissions and
   * limitations under the License.
   **/
.red-ui-debug-window {
	padding: 0;
	margin: 0;
	background: #fff;
	line-height: 20px;
}
.red-ui-debug-window .red-ui-debug-msg-payload {
	font-size: 14px;
}

.red-ui-debug-content {
	position: absolute;
	top: 43px;
	bottom: 0px;
	left: 0px;
	right: 0px;
	overflow-y: scroll;
}

.red-ui-debug-filter-box {
	position: absolute;
	top: 42px;
	left: 0px;
	right: 0px;
	z-index: 20;
	background: #f7f7f7;
	padding: 10px;
	border-bottom: 1px solid #dddddd;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.red-ui-debug-filter-row .red-ui-nodeList {
	margin: 10px 0;
}

.red-ui-debug-msg {
	position: relative;
	border-bottom: 1px solid #eee;
	border-left: 8px solid #eee;
	border-right: 8px solid #eee;
	padding: 2px;
}
.red-ui-debug-msg > .red-ui-debug-msg-meta .red-ui-debug-msg-tools {
	display: none;
}
.red-ui-debug-msg.red-ui-debug-msg-hover {
	border-right-color: #999;
}
.red-ui-debug-msg.red-ui-debug-msg-hover
	> .red-ui-debug-msg-meta
	.red-ui-debug-msg-tools {
	display: inline-block;
}

.red-ui-debug-msg-row .red-ui-debug-msg-tools-pin {
	display: none;
}
.red-ui-debug-msg-row.red-ui-debug-msg-row-pinned .red-ui-debug-msg-tools-pin {
	display: inline-block;
}
.red-ui-debug-msg-row:hover {
	background: #efefef;
}
.red-ui-debug-msg-row:hover
	> .red-ui-debug-msg-tools
	.red-ui-debug-msg-tools-copy {
	display: inline-block;
}
.red-ui-debug-msg-row:hover
	> .red-ui-debug-msg-tools
	.red-ui-debug-msg-tools-pin {
	display: inline-block;
}
.red-ui-debug-msg-row:hover
	> .red-ui-debug-msg-tools
	.red-ui-debug-msg-tools-other {
	display: inline-block;
}

.red-ui-debug-msg-meta .red-ui-debug-msg-tools .red-ui-button-small {
	font-size: 11px;
}

.red-ui-debug-msg-tools .button-group:not(:last-child) {
	margin-right: 3px;
}
.red-ui-debug-msg-tools .red-ui-button-small {
	height: 16px;
	line-height: 14px;
	font-size: 8px;
	border-radius: 1px;
	padding: 0 3px;
	min-width: 18px;
}

.red-ui-debug-msg-meta {
	background: #fff;
	font-size: 11px;
	color: #666;
}

.red-ui-debug-msg-date {
	padding: 1px 5px 1px 1px;
}

.red-ui-debug-msg-topic {
	display: block;
	color: #a66;
}

.red-ui-debug-msg-name {
	padding: 1px 5px;
	color: #666;
}

.red-ui-debug-msg-tools {
	position: absolute;
	top: 3px;
	right: 1px;
}
.red-ui-debug-msg-tools .red-ui-debug-msg-tools-copy {
	display: none;
}
.red-ui-debug-msg-tools .red-ui-debug-msg-tools-other {
	display: none;
}

.red-ui-debug-msg-payload {
	display: block;
	padding: 2px;
	background: #fff;
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	font-size: 13px !important;
}

.red-ui-debug-msg-level-log {
	border-left-color: #eee;
	border-right-color: #eee;
}

.red-ui-debug-msg-level-30 {
	border-left-color: #ffcf70;
	border-right-color: #ffcf70;
}

.red-ui-debug-msg-level-20 {
	border-left-color: #df2935;
	border-right-color: #df2935;
}

.red-ui-debug-msg-object-entry {
	position: relative;
	padding-left: 15px;
}

.red-ui-debug-msg-element {
	color: #333;
	line-height: 1.3em;
	overflow-wrap: break-word;
}

.red-ui-debug-msg-object-key {
	color: #792e90;
}

.red-ui-debug-msg-object-handle {
	color: #888;
	font-size: 1em;
	width: 1em;
	text-align: center;
	transition: transform 0.1s ease-in-out;
	transform: rotate(90deg);
}

.red-ui-debug-msg-element:not(.red-ui-debug-msg-top-level)
	> .red-ui-debug-msg-expandable
	> .red-ui-debug-msg-object-handle {
	margin-left: -1em;
}

.red-ui-debug-msg-object-entry
	> .red-ui-debug-msg-expandable
	> .red-ui-debug-msg-object-handle {
	margin-left: -1em;
}

.red-ui-debug-msg-object-entry.collapsed
	> span
	> .red-ui-debug-msg-object-handle {
	transform: rotate(0deg);
}

.red-ui-debug-msg-element.collapsed > span > .red-ui-debug-msg-object-handle {
	transform: rotate(0deg);
}

.red-ui-debug-msg-object-entry.collapsed > .red-ui-debug-msg-object-entry {
	display: none;
}

.red-ui-debug-msg-element.collapsed .red-ui-debug-msg-object-entry {
	display: none;
}

.red-ui-debug-msg-element:not(.collapsed)
	> .red-ui-debug-msg-expandable
	> .red-ui-debug-msg-object-value
	> .red-ui-debug-msg-object-header {
	display: none;
}

.red-ui-debug-msg-element.collapsed .red-ui-debug-msg-buffer-opts {
	display: none;
}

.red-ui-debug-msg-element.collapsed .red-ui-debug-msg-object-type-header {
	display: none;
}

.red-ui-debug-msg-object-entry pre {
	font-family: Menlo, Consolas, 'DejaVu Sans Mono', Courier, monospace;
	font-size: 13px;
	line-height: 1.2em;
	margin: 0 0 0 -1em;
}

.red-ui-debug-msg-type-other {
	color: #2033d6;
}

.red-ui-debug-msg-type-string {
	color: #b72828;
}

.red-ui-debug-msg-type-null {
	color: #666;
	font-style: italic;
}

.red-ui-debug-msg-type-meta {
	color: #666;
	font-style: italic;
}

.red-ui-debug-msg-type-number {
	color: #2033d6;
}

.red-ui-debug-msg-type-number-toggle {
	cursor: pointer;
}

.red-ui-debug-msg-type-string {
	white-space: pre-wrap;
}

.red-ui-debug-msg-row {
	display: block;
	padding: 4px 2px 2px;
	position: relative;
}
.red-ui-debug-msg-row.red-ui-debug-msg-row-pinned {
	background: #efefef;
}

.red-ui-debug-msg-expandable {
	cursor: pointer;
}

.red-ui-debug-msg-expandable:hover .red-ui-debug-msg-object-handle {
	color: #666;
}

.red-ui-debug-msg-buffer-opts {
	margin-left: 5px;
}

.red-ui-debug-msg-buffer-raw > .red-ui-debug-msg-string-rows {
	display: none;
}

.red-ui-debug-msg-buffer-string > .red-ui-debug-msg-array-rows {
	display: none;
}

.red-ui-debug-msg-type-string-swatch {
	display: inline-block;
	width: 1.1em;
	height: 0.9em;
	vertical-align: middle;
	border-radius: 3px;
	margin: 0 4px;
}

/**
  * Copyright JS Foundation and other contributors, http://js.foundation
  *
  * Licensed under the Apache License, Version 2.0 (the License);
  * you may not use this file except in compliance with the License.
  * You may obtain a copy of the License at
  *
  * http://www.apache.org/licenses/LICENSE-2.0
  *
  * Unless required by applicable law or agreed to in writing, software
  * distributed under the License is distributed on an AS IS BASIS,
  * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  * See the License for the specific language governing permissions and
  * limitations under the License.
  **/
.red-ui-editor-radial-menu {
	font-size: 14px;
	font-family: 'Lato', Arial, Helvetica, sans-serif;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 1000;
}
.red-ui-editor-radial-menu > div {
	position: absolute;
	border-radius: 80px;
	width: 160px;
	height: 160px;
	background: rgba(0, 0, 0, 0.2);
	border: 1px solid #bbbbbb;
}

.red-ui-editor-radial-menu-opt {
	position: absolute;
	border-radius: 20px;
	width: 50px;
	height: 50px;
	background: #fff;
	border: 2px solid #bbbbbb;
	text-align: center;
	line-height: 50px;
}
.red-ui-editor-radial-menu-opt.selected {
	background: #e6e6e6;
}

.red-ui-editor-radial-menu-opt-disabled {
	border-color: #ccc;
	color: #ccc;
}

.red-ui-editor-radial-menu-opt-active {
	background: #e6e6e6;
}
