.re-cron-container .c-host {
	display: flex;
	flex-direction: column;
	flex: 1;
	min-height: 0;
}

.re-cron-container .c-host .c-tabs {
	display: flex;
	border: 0;
	width: 100%;
	border-bottom: 1px solid rgb(80, 80, 80);
}

.re-cron-container .c-host .c-tabs .c-tab {
	padding-left: 0.5rem;
	padding-right: 0.5rem;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
}

.re-cron-container .c-host .c-tabs .c-tab:focus {
	outline: none;
	/* box-shadow: var(--chakra-shadows-outline); */
}

.re-cron-container .c-host .c-tabs .c-tab:first-child {
	border-top-left-radius: 0.3rem;
}

.re-cron-container .c-host .c-tabs .c-tab.active {
	background-color: rgb(66, 66, 66);
}

.re-cron-container .c-host .c-tab-content {
	padding-top: 0.5rem;
	padding-left: 0.5rem;
	display: flex;
	background: rgb(66, 66, 66);
	flex: 1;
	min-height: 0;
	overflow: auto;
	border-bottom-left-radius: 0.2rem;
	border-bottom-right-radius: 0.2rem;
}

.re-cron-container .c-host .c-tab-content .form-group {
	display: flex;
	flex-wrap: wrap;
}
.re-cron-container .c-host .c-tab-content .form-group > select {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
	background: rgba(255, 255, 255, 0.144);
	border-radius: 0.1rem;
}

.re-cron-container .c-host .c-tab-content .form-group > select:focus {
	outline: none;
	box-shadow: var(--chakra-shadows-outline);
}

.re-cron-container .c-host .c-tab-content .form-group > select#xyz {
	border: 0px;
	outline: 0px;
}

.re-cron-container .c-host .c-tab-content .form-group > select > option {
	background: rgb(66, 66, 66);
}

.re-cron-container .c-host .c-tab-content .form-group .form-check > input {
	margin-right: 0.5rem;
	accent-color: rgb(153, 0, 255);
}

.re-cron-container .c-host .c-tab-content .form-group > select:disabled {
	margin-left: 0.2rem;
	margin-right: 0.2rem;
	background: rgba(255, 255, 255, 0.144);
	color: rgba(255, 255, 255, 0.24);
	border-radius: 0.1rem;
}

.re-cron-container .c-host .c-tab-content .form-group .c-and-list {
	display: flex;
	flex-wrap: wrap;
}

.re-cron-container .c-host .c-tab-content .form-group .c-and-list .c-and-item {
	white-space: nowrap;
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	margin-left: 1rem;
}

.re-cron-container .c-host .c-tab-content .form-group .c-and-weekday-list {
	display: flex;
	margin-left: 1rem;
}

.re-cron-container
	.c-host
	.c-tab-content
	.form-group
	.c-and-weekday-list
	.c-and-weekday-item {
	padding-left: 0.2rem;
	padding-right: 0.2rem;
}

.re-cron-container .c-host .c-tab-content .form-group .c-and-monthday-list {
	display: flex;
	margin-left: 1rem;
	flex-wrap: wrap;
}

.re-cron-container
	.c-host
	.c-tab-content
	.form-group
	.c-and-monthday-list
	.c-and-monthday-item {
	white-space: nowrap;
	padding-left: 0.2rem;
	padding-right: 0.2rem;
	margin-left: 1rem;
}
